import Alert from "Common/Components/Alert";
import Modal from "Common/Components/Modal";
import iphone from "assets/images/product/iphone.png";

interface DetailModalProps {
  detailState: { isOpen: boolean };
  setDetailState: any;
}

const DetailModal = ({ detailState, setDetailState }: DetailModalProps) => {
  return (
    <Modal
      show={detailState.isOpen}
      onHide={() =>
        setDetailState((state: any) => ({ ...state, isOpen: false }))
      }
      id="extraLargeModal"
      modal-center="true"
      className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
      dialogClassName="w-screen lg:w-[55rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full "
    >
      <Modal.Header
        className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500"
        closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500 dark:text-zink-200 dark:hover:text-red-500"
      >
        <Modal.Title className="text-16">Detaylar</Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
        <Alert className="px-4 py-3 text-sm border border-transparent rounded-md text-custom-500 bg-custom-50 dark:bg-custom-400/20 border-t-2 border-t-custom-500">
          <p>
            <Alert.Bold>'Lenovo LEGION'</Alert.Bold> adlı ürününüze gelen soruyu
            cevapladınız için teşekkür ederiz.
          </p>
          <p>
            Soruları düzenli ve özverili cevaplamanız müşteri deneyiminde etkili
            olacaktır.
          </p>
        </Alert>
        <div className="flex gap-4 p-4">
          <div className="w-[200px]">
            <img src={iphone} alt="pic" />
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-lg">Ne zaman stok güncellenir ?</p>
            <p className="text-gray-400">
              Onur Yücel Tarafından 22 Nisan 2020 22:22 tarihinde yazıldı.
            </p>
          </div>
        </div>

        <Alert className="relative p-3 pr-12 text-sm border border-transparent rounded-md text-slate-500 bg-slate-50 dark:bg-zink-400/20 dark:text-zink-200">
          {/* <Alert.Close className="absolute top-0 bottom-0 right-0 p-3 transition text-slate-200 hover:text-slate-500 dark:text-zink-300 dark:hover:text-zink-200" /> */}
          {/* <Alert.Bold className="font-bold">Light</Alert.Bold> alert! You should
          check in on some of those fields below. */}
          <div className="flex flex-col justify-between gap-2 p-2 ">
            <h4 className="text-emerald-600">Onaylanan Cevap</h4>
            <div>
              <p>
                Değerli İş ortağmız, aşağıdaki 24 Şubat 2024 tarihli cevabınız
                müşteriye iletilmiştir.
              </p>
              <p className="text-gray-400">
                Değerli Müşterimiz, 1 hafta içerisinde stoklara giriş
                yapılacaktır.
              </p>
            </div>
          </div>
        </Alert>
      </Modal.Body>
      {/* <Modal.Footer className="flex items-center justify-between p-4 mt-auto border-t border-slate-200 dark:border-zink-500">
      <h5 className="text-16">Modal Footer</h5>
    </Modal.Footer> */}
    </Modal>
  );
};

export default DetailModal;
