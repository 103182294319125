import React, { FormEvent, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Nav } from "../../Common/Components/Tab/Nav";
import Tab from "../../Common/Components/Tab/Tab";
import Select from "react-select";
import { CalendarDays, ArrowLeft } from "lucide-react";
import { Link, } from "react-router-dom";
import Flatpickr from 'react-flatpickr';
import moment from "moment";

const Filtering = ({ setFilter, listParam }: {
    setFilter: (data: any) => void;
    listParam: string | null;
}) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const formRef = useRef<HTMLFormElement>(null);
    const productCategoryRef = useRef<any>(null);
    const productBrandRef = useRef<any>(null);
    const handleFilter = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const data = {
            startDate: moment(formData.get("startDate") as string, 'DD MMM, YYYY').format('YYYY-MM-DD'),
            endDate: moment(formData.get("endDate") as string, 'DD MMM, YYYY').format('YYYY-MM-DD'),
        };
        setFilter(data);
    }


    const handleFilterReset = () => {
        formRef.current?.startDate._flatpickr.setDate(moment().subtract(12, 'months').format('DD MMM, YYYY'));
        formRef.current?.endDate._flatpickr.setDate("today");
        if (formRef.current && formRef.current.productCode) {
            formRef.current.productCode.value = "";
        }
        if (productCategoryRef.current) {
            productCategoryRef.current.clearValue();
        }
        if (productBrandRef.current) {
            productBrandRef.current.clearValue();
        }
    };

    useEffect(() => {
        handleFilterReset();
    }, [pathname]);

    useEffect(() => {
        if ((!listParam || (listParam !== "product-based" && listParam !== "brand-based" && listParam !== "category-based"))) {
            navigate({ search: "?list=product-based" });
        }
    }, [listParam, navigate, pathname]);

    return (
        <React.Fragment>
            <div className="card">
                <div className="card-body flex items-center gap-2">
                    <ArrowLeft size={24} />
                    <h5 className="text-16">Satış & Operasyon Raporları</h5>
                </div>
            </div>
            <div className="card">
                <div className="card-body flex flex-col gap-5">
                    <Tab.Container defaultActiveKey={pathname}>
                        <Nav className="flex flex-wrap w-full text-sm font-medium text-center border-b border-slate-200 dark:border-zink-500 nav-tabs">
                            <Nav.Item eventKey="/reports/sales-reports" className="group">
                                <Link
                                    to="/reports/sales-reports?list=product-based"
                                    data-tab-toggle
                                    className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                                >
                                    <span className="align-middle">Satış Rapoları</span>
                                </Link>
                            </Nav.Item>
                            <Nav.Item eventKey="/reports/canceled-reports" className="group">
                                <Link
                                    to="/reports/canceled-reports?list=product-based"
                                    data-tab-toggle
                                    className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                                >
                                    <span className="align-middle">İptal Rapoları</span>
                                </Link>
                            </Nav.Item>
                            <Nav.Item eventKey="/reports/return-reports" className="group">
                                <Link
                                    to="/reports/return-reports?list=product-based"
                                    data-tab-toggle
                                    className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                                >
                                    <span className="align-middle">İade Raporları</span>
                                </Link>
                            </Nav.Item>
                            {/* <Nav.Item eventKey="/reports/order-distribution-reports" className="group">
                                <Link
                                    // to="order-distribution-reports"
                                    to="#"
                                    data-tab-toggle
                                    className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px] pointer-events-none cursor-not-allowed"
                                >
                                    <span className="align-middle">Sipariş Dağılım Raporu</span>
                                </Link>
                            </Nav.Item> */}
                            {/* <Nav.Item eventKey="/reports/operation-metrics" className="group">
                                <Link
                                    // to="/reports/operation-metrics"
                                    to="#"
                                    data-tab-toggle
                                    className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px] pointer-events-none cursor-not-allowed"
                                >
                                    <span className="align-middle">Operasyon Metrikleri</span>
                                </Link>
                            </Nav.Item> */}
                        </Nav>
                    </Tab.Container>
                    <div className="flex gap-2">
                        <button
                            onClick={() => navigate({ search: "?list=product-based" })}
                            className={`btn leading-none rounded-xl ${listParam === "product-based" ? "bg-custom-500 text-white hover:bg-custom-600" : "bg-white text-black dark:text-white hover:bg-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 border border-black dark:border-white"}`}
                        >
                            Ürün Bazlı
                        </button>
                        <button
                            onClick={() => navigate({ search: "?list=brand-based" })}
                            className={`btn leading-none rounded-xl ${listParam === "brand-based" ? "bg-custom-500 text-white hover:bg-custom-600" : "bg-white text-black dark:text-white hover:bg-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 border border-black dark:border-white"}`}
                        >
                            Marka Bazlı
                        </button>
                        <button
                            onClick={() => navigate({ search: "?list=category-based" })}
                            className={`btn leading-none rounded-xl ${listParam === "category-based" ? "bg-custom-500 text-white hover:bg-custom-600" : "bg-white text-black dark:text-white hover:bg-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 border border-black dark:border-white"}`}
                        >
                            Kategori Bazlı
                        </button>
                    </div>
                    <form ref={formRef} onSubmit={handleFilter}>
                        <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 xl:grid-cols-4 flex-1">
                            <div className="relative mb-4">
                                <label htmlFor="start-date" className="inline-flex items-center gap-1 mb-2 text-base font-medium">
                                    Başlangıç Tarihi
                                    {/* <div className="relative group group/item">
                                        <Info size={14} className='text-blue-400' />
                                        <div className="absolute group/edit invisible group-hover/item:visible group-hover/item:opacity-100 left-0 bottom-full mb-2 w-48 p-2 text-xs text-white bg-black rounded-lg opacity-0 transition-opacity duration-200 text-justify">
                                            Seçeceğiniz başlangıç ve bitiş tarihinin aralığı 30 günü geçmemelidir.
                                        </div>
                                    </div> */}
                                </label>
                                <Flatpickr
                                    id="start-date"
                                    name="startDate"
                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    options={{
                                        dateFormat: "d M, Y",
                                        defaultDate: moment().subtract(12, 'months').format('DD MMM, YYYY'),
                                        maxDate: "today",
                                        enableTime: false,
                                        locale: {
                                            weekdays: {
                                                longhand: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
                                                shorthand: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt']
                                            },
                                            months: {
                                                longhand: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
                                                shorthand: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara']
                                            },
                                        }
                                    }}
                                    placeholder='Başlangıç tarihini seçiniz'
                                    lang='tr'
                                />
                                <CalendarDays className="absolute size-4 right-3 bottom-3 text-slate-500 dark:text-zink-200" />
                            </div>
                            <div className="relative mb-4">
                                <label htmlFor="end-date" className="inline-flex items-center gap-1 mb-2 text-base font-medium">
                                    Bitiş Tarihi
                                    {/* <div className="relative group group/item">
                                        <Info size={14} className='text-blue-400' />
                                        <div className="absolute group/edit invisible group-hover/item:visible group-hover/item:opacity-100 left-0 bottom-full mb-2 w-48 p-2 text-xs text-white bg-black rounded-lg opacity-0 transition-opacity duration-200 text-justify">
                                            Seçeceğiniz başlangıç ve bitiş tarihinin aralığı 30 günü geçmemelidir.
                                        </div>
                                    </div> */}
                                </label>
                                <Flatpickr
                                    id="end-date"
                                    name="endDate"
                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    options={{
                                        dateFormat: "d M, Y",
                                        defaultDate: "today",
                                        maxDate: "today",
                                        enableTime: false,
                                        locale: {
                                            weekdays: {
                                                longhand: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
                                                shorthand: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt']
                                            },
                                            months: {
                                                longhand: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
                                                shorthand: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara']
                                            },
                                        },
                                    }}
                                    placeholder='İptal bitiş tarihini seçiniz'
                                />
                                <CalendarDays className="absolute size-4 right-3 bottom-3 text-slate-500 dark:text-zink-200" />
                            </div>
                            {
                                listParam === "product-based" && (
                                    <div className="mb-4">
                                        <label
                                            htmlFor="product-code"
                                            className="inline-block mb-2 text-base font-medium"
                                        >
                                            Ürün Kodu
                                        </label>
                                        <input
                                            type="text"
                                            id="product-code"
                                            name="productCode"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Ürün kodunu giriniz"
                                        />
                                    </div>
                                )
                            }
                            {
                                listParam !== "brand-based" && (
                                    <div className="mb-4">
                                        <label
                                            htmlFor="product-category"
                                            className="inline-block mb-2 text-base font-medium"
                                        >
                                            Kategoriler
                                        </label>
                                        <Select
                                            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            isClearable
                                            ref={productCategoryRef}
                                            options={[
                                                { value: "Elektronik", label: "Elektronik" },
                                                { value: "Giyim", label: "Giyim" },
                                                { value: "Spor", label: "Spor" },
                                                { value: "Kozmetik", label: "Kozmetik" },
                                                { value: "Kitap", label: "Kitap" },
                                            ]}
                                            placeholder="Kategoriyi seçiniz"
                                            isSearchable
                                            name="productCategory"
                                            id="productCategory"
                                        />
                                    </div>
                                )
                            }
                            {
                                listParam !== "category-based" && (
                                    <div className="mb-4">
                                        <label
                                            htmlFor="product-brand"
                                            className="inline-block mb-2 text-base font-medium"
                                        >
                                            Markalar
                                        </label>
                                        <Select
                                            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            isClearable
                                            ref={productBrandRef}
                                            options={[
                                                { value: "Apple", label: "Apple" },
                                                { value: "Samsung", label: "Samsung" },
                                                { value: "Nike", label: "Nike" },
                                                { value: "Adidas", label: "Adidas" },
                                                { value: "Loreal", label: "Loreal" },
                                            ]}
                                            placeholder="Markayı seçiniz"
                                            isSearchable
                                            name="productBrand"
                                            id="productBrand"
                                        />
                                    </div>
                                )
                            }
                            <div className="grid-item flex items-end gap-2 mb-4 xl:col-start-4 xl:col-end-5">
                                <button
                                    type="button"
                                    onClick={handleFilterReset}
                                    className="flex-1 text-slate-500 btn bg-slate-200 border-slate-200 hover:text-slate-600 hover:bg-slate-300 hover:border-slate-300 focus:text-slate-600 focus:bg-slate-300 focus:border-slate-300 focus:ring focus:ring-slate-100 active:text-slate-600 active:bg-slate-300 active:border-slate-300 active:ring active:ring-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 dark:border-zink-600 dark:hover:border-zink-500 dark:text-zink-200 dark:ring-zink-400/50 md:w-[200px] w-full"
                                >
                                    <span className="align-middle">Temizle</span>
                                </button>
                                <button
                                    type="submit"
                                    className="flex-1 text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 md:w-[200px] w-full"
                                >
                                    Filtrele
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Filtering