import { X } from "lucide-react";
import { UnarchiveState } from "pages/product/Product";
import Modal from "../Modal";
import { EventFunc, SubmitFunc } from "models/models";
import { useState } from "react";

interface IUnarchive {
  unarchiveState: UnarchiveState;
  onHide: any;
  unarchiveProduct: any;
}
export interface StateType {
  stock: string;
  originalPrice: string;
  price: string;
}

const UnarchiveModal: React.FC<IUnarchive> = ({
  unarchiveState,
  onHide,
  unarchiveProduct,
}) => {
  const { product, isOpen } = unarchiveState;
  const [state, setState] = useState<StateType>({
    stock: "",
    originalPrice: "",
    price: "",
  });

  const handleSubmit: SubmitFunc = (e) => {
    e.preventDefault();
    unarchiveProduct(state, product, setState);
  };

  const handleChange: EventFunc = (e) => {
    if (e.target.name === "price") {
      if (Number(state.originalPrice) >= Number(e.target.value)) {
        return setState({
          ...state,
          [e.target.name]: e.target.value.toString(),
        });
      }
      return;
    }
    return setState({ ...state, [e.target.name]: e.target.value.toString() });
  };

  const modalOff = () => {
    setState({ stock: "", originalPrice: "", price: "" });
    onHide();
  };

  return (
    <Modal
      show={isOpen}
      onHide={modalOff}
      id="unarchiveModal"
      modal-center="true"
      className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
      dialogClassName="w-screen md:w-[35rem] bg-white shadow rounded-md dark:bg-zink-600"
    >
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] overflow-y-auto px-6 py-8 relative">
        <div className="absolute top-4 right-4">
          <button
            data-modal-close="unarchiveModal"
            className="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"
          >
            <X className="size-5" onClick={onHide} />
          </button>
        </div>
        <div className="flex flex-col gap-2">
          <div>
            <h4>Ürünü Arşivden Çıkart</h4>
          </div>
          <div className="flex items-center gap-5">
            <div className="h-[150px] w-[150px] rounded-md">
              <img
                src={product?.thumbnail || ""}
                alt="pic"
                className="h-full w-full rounded-md"
              />
            </div>
            <div className="flex flex-col gap-1 text-sm">
              <h6 className="underline decoration-1">{product.title}</h6>
              <p>Ürün Kodu: {product.product_code}</p>
              <p>KDV Oranı: %{product.tax}</p>
              <p>Komisyon: %{product.tax}</p>
            </div>
          </div>
          <form action="" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-4">
              <div>
                <label
                  htmlFor="stock"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Stok Adedi
                </label>
                <input
                  type="number"
                  name="stock"
                  id="stock"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Stok adedini giriniz"
                  required
                  onChange={handleChange}
                  value={state.stock}
                />
              </div>
              <div>
                <label
                  htmlFor="originalPrice"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Piyasa Satış Fiyatı
                </label>
                <input
                  type="number"
                  name="originalPrice"
                  id="originalPrice"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Stok adedini giriniz"
                  required
                  onChange={handleChange}
                  value={state.originalPrice}
                />
              </div>
              <div>
                <label
                  htmlFor="price"
                  className="inline-block mb-2 text-base font-medium"
                >
                  a-Commerce Satış Fiyatı
                </label>
                <input
                  type="number"
                  name="price"
                  id="price"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Stok adedini giriniz"
                  required
                  onChange={handleChange}
                  value={state.price}
                  disabled={state.originalPrice.length === 0}
                />
              </div>
              <div className="flex justify-between gap-5 mt-4">
                <button
                  type="reset"
                  className="text-slate-500 btn bg-slate-200 border-slate-200 hover:text-slate-600 hover:bg-slate-300 hover:border-slate-300 focus:text-slate-600 focus:bg-slate-300 focus:border-slate-300 focus:ring focus:ring-slate-100 active:text-slate-600 active:bg-slate-300 active:border-slate-300 active:ring active:ring-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 dark:border-zink-600 dark:hover:border-zink-500 dark:text-zink-200 dark:ring-zink-400/50  w-full"
                  onClick={modalOff}
                >
                  <span className="align-middle">Vazgeç</span>
                </button>
                <button
                  type="submit"
                  className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100  w-full"
                >
                  Arşivden Çıkart
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UnarchiveModal;
