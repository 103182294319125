import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getItem } from "helpers/localStorageControl";

interface UserState {
  bearer_token?: string;
  required_actions: any;
  two_fa_auth: boolean;
  control_token?: string;
  birthday: string;
  created_at: string;
  deleted_at: undefined;
  email: string;
  birthemail_verify_atday: string;
  full_name: string;
  gender: string;
  id: number;
  identity_number: undefined;
  phone_number: string;
  phone_verify_at: string;
  username: string;
  updated_at: string;
  user_details: {
    masked_email: string;
    user_id: number;
  };
}
const emptyUserState: UserState = {
  bearer_token: undefined,
  required_actions: null,
  two_fa_auth: false,
  control_token: undefined,
  birthday: "",
  created_at: "",
  deleted_at: undefined,
  email: "",
  birthemail_verify_atday: "",
  full_name: "",
  gender: "",
  id: 0,
  identity_number: undefined,
  phone_number: "",
  phone_verify_at: "",
  username: "",
  updated_at: "",
  user_details: {
    masked_email: "",
    user_id: 0,
  },
};

interface AuthState {
  currentUser: UserState;
}

const initialState: AuthState = {
  currentUser: getItem("auth")?.data || {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess(state, action: PayloadAction<UserState>) {
      state.currentUser = action.payload;
    },
    userSuccess(state, action: PayloadAction<UserState>) {
      state.currentUser = {
        ...state.currentUser,
        ...action.payload,
      };
    },
    logoutSuccess(state) {
      state.currentUser = emptyUserState;
    },
  },
});

export const { userSuccess, loginSuccess, logoutSuccess } = authSlice.actions;
export default authSlice.reducer;
