import React, { useCallback, useEffect, useState } from 'react';
import { sendRequestWithCache } from 'helpers/request';
import { useAppSelector } from "../../../redux/hooks";

// SimpleBar
import SimpleBar from 'simplebar-react';

// Icons
import { Link } from 'react-router-dom';

interface AnnouncementsData {
    image: string;
    title: string;
    content: string;
    date: string;
    category: string;
}

const Announcements = () => {
    const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
    const [announcements, setAnnouncements] = useState<AnnouncementsData[]>([]);
    const [loading, setLoading] = useState(true);

    const fetchAnnouncements = useCallback(async () => {
        try {
            const { data } = await sendRequestWithCache("GET", "/user/dashboard/announcements", null, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });
            setAnnouncements(data);
        } catch (error) {
            return error
        } finally {
            setLoading(false);
        }
    }, [bearer_token]);

    useEffect(() => {
        fetchAnnouncements();
    }, [fetchAnnouncements]);

    const formatDate = (date: string) => {
        const newDate = new Date(date);
        return newDate.toLocaleDateString('tr-TR', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' });
    }

    return (
        <React.Fragment>
            <div className="col-span-12 card lg:col-span-6 2xl:col-span-3 relative">
                {loading && (
                    <div className="flex items-center justify-center h-full absolute inset-0">
                        <div className="flex flex-col items-center">
                            <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-gray-400"></div>
                        </div>
                    </div>
                )}
                <div className={loading ? 'opacity-0' : ''}>
                    <div className="!pb-0 mb-3 card-body flex items-center justify-between">
                        <h6 className="text-15 text-slate-500">Duyurularım</h6>
                        <Link to="#" className="text-sm underline text-slate-500">Tümünü Gör</Link>
                    </div>
                    <div className="pb-5">
                        <SimpleBar className="flex flex-col h-[350px] gap-4 px-5">
                            <div className="flex flex-col mt-1 gap-2">
                                {announcements.map((item: any, key: number) => (<div className="border rounded-md border-slate-200 dark:border-zink-500" key={key}>
                                    <div className="flex flex-wrap items-center gap-3 p-2">
                                        <div className="size-10 rounded-full shrink-0">
                                            <img src={item.image} alt="" className="h-10 rounded-full" />
                                        </div>
                                        <div className="grow">
                                            <div className="flex items-center justify-between mb-1">
                                                <h6 className="text-xs text-slate-500">{item.category}</h6>
                                                <span className="text-xs text-slate-500">{formatDate(item.date)}</span>
                                            </div>
                                            <p className="text-slate-500 dark:text-zink-200 overflow-hidden text-nowrap w-60 text-ellipsis">{item.title}</p>
                                            <span className='underline cursor-pointer text-xs text-slate-500'>Devamını Oku</span>
                                        </div>
                                    </div>
                                </div>))}
                            </div>
                        </SimpleBar>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Announcements;
