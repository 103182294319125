import React, { useMemo, useCallback, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useAppSelector } from "../../redux/hooks";
import TableContainer from "Common/TableContainer";
import { Link } from "react-router-dom";
import NotFound from "../../Common/Components/NotFound/NotFound";
import { sendRequestWithCache } from 'helpers/request';
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

interface DataTableType {
    list: {
        productInfo: string;
        img: string;
        designation: string;
        evaluationDate: string;
        reviewStars: number;
        reviewDetail: string;
        status: string;
    }[] | Object[];
};

interface FilterType {
    startDate: string;
    endDate: string;
    productCode: string;
    productRating: string[];
    productCategory: string[];
    productBrand: string[];
};

const ProductReviewsDataByDate = ({ filter }: { filter: FilterType }) => {
    const [loading, setLoading] = useState(true);
    const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
    const [list, setList] = useState<DataTableType[]>([]);

    const formnatDate = (date: string) => {
        const d = new Date(date);
        return `${d.getDate()} ${d.toLocaleString('default', { month: 'long' })} ${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
    }

    const columns = useMemo(
        () => [
            {
                header: "Ürün Bilgileri",
                accessorKey: "productInfo",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => {
                    return (
                        <div className="flex items-center gap-2 w-full overflow-hidden">
                            <div className="h-16 flex items-center justify-center font-medium rounded shrink-0 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
                                <img
                                    src={cell.row.original.img}
                                    alt=""
                                    className="h-full rounded"
                                />
                            </div>
                            <div className="grow">
                                <h6 className="mb-1 ">
                                    <Link to="#!" className="name text-wrap line-clamp-2">
                                        {cell.getValue()}
                                    </Link>
                                </h6>
                                <p className="text-slate-500 dark:text-zink-200">
                                    Ürün Kodu: {cell.row.original.designation}
                                </p>
                            </div>
                        </div>
                    );
                },
            },
            {
                header: 'Değerlendirma Tarihi',
                accessorKey: 'evaluationDate',
                enableColumnFilter: false,
            },
            {
                header: 'Ürün Puanı',
                accessorKey: 'reviewStars',
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <p className={`${cell.getValue() < 3 ? 'text-red-400' : cell.getValue() < 4 ? 'text-yellow-400' : 'text-green-400'} text-center`}>
                        {cell.getValue()}
                    </p>
                ),
            },
            {
                header: 'Değerlendirme Detayı',
                accessorKey: 'reviewDetail',
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <React.Fragment>
                        {Array.from({ length: Math.floor(cell.row.original.reviewStars) }).map((_, index) => (
                            <i key={index} className="ri-star-fill text-yellow-500"></i>
                        ))}
                        {Math.floor(cell.row.original.reviewStars) < cell.row.original.reviewStars && (
                            <i className="ri-star-half-fill text-yellow-500"></i>
                        )}
                        {Array.from({ length: 5 - Math.ceil(cell.row.original.reviewStars) }).map((_, index) => (
                            <i key={index} className="ri-star-fill text-gray-200"></i>
                        ))}
                        <p className="text-wrap line-clamp-2">{cell.getValue()}</p>
                    </React.Fragment>
                ),
            },
            {
                header: 'Durum',
                accessorKey: 'status',
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <p className="text-wrap line-clamp-2">
                        {cell.getValue()}
                    </p>
                ),
            },
        ],
        []
    );

    const fetchProductReviews = useCallback(async () => {
        setLoading(true);

        const formData = new FormData();
        formData.append('start_date', moment(filter.startDate).format('YYYY-MM-DD'));
        formData.append('end_date', moment(filter.endDate).format('YYYY-MM-DD'));
        filter.productCode && formData.append('product_code', filter.productCode);
        filter.productRating && filter.productRating.map(el => formData.append('points[]', el));
        filter.productCategory && filter.productCategory.map(el => formData.append('categories[]', el));
        filter.productBrand && filter.productBrand.map(el => formData.append('brands[]', el));


        try {
            const response = await sendRequestWithCache("POST", "/product/review/search/byDate", formData, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });

            setList(
                response.map((item: any) => ({
                    productInfo: item.product.title,
                    img: item.product.images[0],
                    designation: item.product.product_code,
                    evaluationDate: formnatDate(item.created_at),
                    averageProductRating: item.average_points,
                    reviewStars: item.point,
                    reviewDetail: item.message,
                    status: "Yoruma destek süresi dolmuştur, mesaj yollanamaz",
                }))
            );

        } catch (error) {
            return error
        } finally {
            setLoading(false);
        }
    }, [bearer_token, filter]);

    useEffect(() => {
        fetchProductReviews();
    }, [fetchProductReviews, filter]);

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} limit={1} />
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
                <div className="xl:col-span-12">
                    <div className="card relative">
                        {loading && (
                            <div className="flex items-center justify-center h-full absolute inset-0">
                                <div className="flex flex-col items-center">
                                    <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                                </div>
                            </div>
                        )}
                        <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                            <React.Fragment>
                                {list.length > 0 && (<div className="md:flex justify-between items-center">
                                    <div className="mb-2">
                                        <h6 className="text-xl font-normal">Ürün Değerlendirmelerim</h6>
                                    </div>
                                    <div className="mb-2 flex sm:items-center items-start flex-col sm:flex-row">
                                        <div className="sm:text-end text-start mb-1 sm:mr-4 mr-0 sm:ml-auto">
                                            <p className="text-sm">Filtreleme Sonuçları: Toplam {list.length} yorum bilgisi</p>
                                            <span className="text-sm text-gray-400">Son Güncellenme: {moment().format('DD MMMM YYYY HH:mm')}</span>
                                        </div>
                                        <div>
                                            <div className="relative flex-1">
                                            </div>
                                            <div className='self-center place-self-end'>
                                                <button
                                                    type="button"
                                                    className="btn border-slate-200 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 mb-2"
                                                >
                                                    Excel ile indir{" "}
                                                    <i className="align-baseline ltr:pr-1 rtl:pl-1 ri-download-2-line"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                            </React.Fragment>
                            {list.length > 0 ? (
                                <TableContainer
                                    isPagination={true}
                                    columns={columns}
                                    data={list}
                                    customPageSize={10}
                                    divclassName="-mx-5 -mb-5 overflow-x-auto"
                                    tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                                    theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                                    thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                                    tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                                    PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                                />
                            ) : (
                                <NotFound />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};

export default ProductReviewsDataByDate;
