import React, { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { sendRequestWithCache } from 'helpers/request';
import { useAppSelector } from "../../../redux/hooks";


import { ShoppingCart } from 'lucide-react';

const TopSellingProducts = () => {

    const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
    const [loading, setLoading] = useState(true);
    const [bestSellers, setBestSellers] = useState([]);

    const featchBestSellers = useCallback(async () => {
        try {
            const { data } = await sendRequestWithCache("GET", "/user/dashboard/bestSellers", null, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });
            setBestSellers(data);
        } catch (error) {
            return error
        } finally {
            setLoading(false);
        }
    }, [bearer_token]);

    useEffect(() => {
        featchBestSellers();
    }, [featchBestSellers]);


    return (
        <React.Fragment>
            <div className="col-span-12 card lg:col-span-6 2xl:col-span-3 relative">
                {loading && (
                    <div className="flex items-center justify-center h-full absolute inset-0">
                        <div className="flex flex-col items-center">
                            <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-yellow-400"></div>
                        </div>
                    </div>
                )}
                <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                    <div className="flex items-center mb-3">
                        <h6 className="grow text-15">En Çok Satılan Ürünler</h6>
                    </div>
                    <ul className="flex flex-col gap-5">
                        {bestSellers.map((item: {
                            avg_point: string,
                            id: number,
                            thumbnail: string,
                            title: string,
                            total_orders: string
                        }) => (
                            <li key={item.id} className="flex items-center gap-3">
                                <div className="flex items-center justify-center size-10 rounded-md bg-slate-100 dark:bg-zink-600">
                                    <img src={item.thumbnail} alt="" className="h-6" />
                                </div>
                                <div className="overflow-hidden grow flex-1">
                                    <h6 className="truncate">{item.title}</h6>
                                    <div className="text-yellow-500">
                                        {Math.floor(+item.avg_point) > 0 && (
                                            Array.from({ length: Math.floor(+item.avg_point) }).map((_, index) => (
                                                <i key={index} className="ri-star-fill"></i>
                                            ))
                                        )}
                                        {Math.floor(+item.avg_point) < +item.avg_point && (
                                            <i className="ri-star-half-fill"></i>
                                        )}
                                    </div>
                                </div>
                                <h6 className="shrink-0"><ShoppingCart className="inline-block size-4 align-top text-slate-500 dark:text-zink-200 ltr:mr-1 rtl:ml-1"></ShoppingCart> {item.total_orders}</h6>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
};

export default TopSellingProducts;
