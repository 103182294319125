import React, { useCallback, useEffect, useState } from 'react'
import { sendRequestWithCache } from 'helpers/request';
import { useAppSelector } from "../../redux/hooks";
import LoadingOverlay from 'Common/Components/LoadingOverlay';
import useChartColors from "Common/useChartColors";
import ReactApexChart from "react-apexcharts";
import ProductBasedCancellationReportsTable from "./ProductBasedCancellationReportsTable";

interface ProductBasedCancellationReportsProps {
    filter: {
        startDate: string;
        endDate: string;
    }
}

const ProductBasedCancellationReports = ({ filter }: ProductBasedCancellationReportsProps) => {
    const chartColors = useChartColors('updatingDonutChart');
    const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
    const [loading, setLoading] = useState(true);
    const [reportCancellationSummary, setReportCancellationSummary] = useState<any>({});

    const formatPrice = (price: number) => {
        if (typeof price !== "number") return '0,00₺'
        return new Intl.NumberFormat("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price) + "₺";
    };

    const series = [33, 33, 34];
    const options: any = {
        chart: {
            height: 350,
            type: 'donut',
        },
        colors: chartColors,
        labels: ['Benim İptal Ettiğim', 'a-Commerce’in İptal Ettiği', 'Müşterinin İptal Ettiği'],
        legend: {
            show: false
        },
    };

    const fetchProductBasedCancellationReports = useCallback(async () => {
        setLoading(true);
        const params = new URLSearchParams({
            start_date: filter.startDate,
            end_date: filter.endDate,
            scope: 'product',
        });

        try {
            const response = await sendRequestWithCache("POST", `/reports/cancelled/summary?${params}`, null, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });
            setReportCancellationSummary(response);
        } catch (error) {
            return error
        } finally {
            setLoading(false);
        }
    }, [filter, bearer_token]);

    useEffect(() => {
        fetchProductBasedCancellationReports();
    }, [filter, fetchProductBasedCancellationReports]);

    return (
        <React.Fragment>
            <div className="card relative">
                <LoadingOverlay loading={loading} color="bg-custom-500" />
                <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                    <h6 className="mb-4 text-15">İptal Özeti</h6>
                    <div className="flex flex-col md:flex-row gap-20">
                        <div className="md:ml-20">
                            <ReactApexChart
                                dir="ltr"
                                options={options}
                                series={series}
                                data-chart-colors='["bg-custom-500", "bg-orange-500", "bg-green-500"]'
                                id='updatingDonutChart'
                                className="apex-charts"
                                height={300}
                                type="donut"
                                width="100%"
                            />
                        </div>
                        <div className="-mx-5 -mb-5 overflow-x-auto flex-1 md:pr-20 md:pb-12">
                            <table className="w-full h-full whitespace-nowrap">
                                <thead className="ltr:text-left rtl:text-right ">
                                    <tr>
                                        <th className="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-b border-slate-200 dark:border-zinc-500">İptal Türü</th>
                                        <th className="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-b border-slate-200 dark:border-zinc-500 text-center">İptal Oranı</th>
                                        <th className="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-b border-slate-200 dark:border-zinc-500 text-center">Adet</th>
                                        <th className="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-b border-slate-200 dark:border-zinc-500 text-center">Tahmini Satış Kaybı</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zinc-500"><span className="inline-block bg-blue-400 !h-[12px] !w-[12px] rounded-full -mb-[1px]" /> Benim İptal Ettiğim</td>
                                        <td className="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zinc-500 text-center">%{reportCancellationSummary[1]?.original.owner_demand.percentage}</td>
                                        <td className="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zinc-500 text-center">{reportCancellationSummary[1]?.original.owner_demand.count}</td>
                                        <td className="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zinc-500 text-center">{formatPrice(reportCancellationSummary[1]?.original.owner_demand.sales_loss)}</td>
                                    </tr>
                                    <tr>
                                        <td className="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zinc-500"><span className="inline-block bg-orange-400 !h-[12px] !w-[12px] rounded-full -mb-[1px]" /> a-Commerce’in İptal Ettiği</td>
                                        <td className="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zinc-500 text-center">%{reportCancellationSummary[1]?.original.vendor_demand.percentage}</td>
                                        <td className="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zinc-500 text-center">{reportCancellationSummary[1]?.original.vendor_demand.count}</td>
                                        <td className="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zinc-500 text-center">{formatPrice(reportCancellationSummary[1]?.original.vendor_demand.sales_loss)}</td>
                                    </tr>
                                    <tr>
                                        <td className="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zinc-500"><span className="inline-block bg-green-400 !h-[12px] !w-[12px] rounded-full -mb-[1px]" /> Müşterinin İptal Ettiği</td>
                                        <td className="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zinc-500 text-center">%{reportCancellationSummary[1]?.original.customer_demand.percentage}</td>
                                        <td className="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zinc-500 text-center">{reportCancellationSummary[1]?.original.customer_demand.count}</td>
                                        <td className="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zinc-500 text-center">{formatPrice(reportCancellationSummary[1]?.original.customer_demand.sales_loss)}</td>
                                    </tr>
                                    <tr className="font-semibold border-y border-slate-200 dark:border-zinc-500 dark:bg-gray-700">
                                        <td className="px-3.5 py-2.5 first:pl-5 last:pr-5">TOPLAM</td>
                                        <td className="px-3.5 py-2.5 first:pl-5 last:pr-5 text-center">%{reportCancellationSummary[1]?.original.total.percentage}</td>
                                        <td className="px-3.5 py-2.5 first:pl-5 last:pr-5 text-center">{reportCancellationSummary[1]?.original.total.count}</td>
                                        <td className="px-3.5 py-2.5 first:pl-5 last:pr-5 text-center">{formatPrice(reportCancellationSummary[1]?.original.total.sales_loss)}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
            <ProductBasedCancellationReportsTable reportCancellationSummary={reportCancellationSummary[0]?.original.summary_data} loading={loading} />
        </React.Fragment >
    )
}

export default ProductBasedCancellationReports;