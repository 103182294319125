import { useEffect, useState } from "react";
import { Pencil } from "lucide-react";
import CompanyModal from "Common/Components/account.tsx/CompanyModal";
import { sendRequestWithCache } from "helpers/request";
import { useAppSelector } from "../../redux/hooks";
import NotFound from "pages/finance/NotFound";
import { toastErrorNotify, toastSuccessNotify } from "helpers/ToasNotify";
import { getItem, setItem } from "helpers/localStorageControl";

interface CurrenDataType {
  loading: boolean;
  error: boolean;
  data: {
    company_name: string;
    company_type: number;
    created_at: string;
    deleted_at: any;
    iban: string;
    id: number;
    kep_mail: string;
    mersis_number: string;
    store_name: string;
    tax_id: string;
    tax_office: string;
    updated_at: string;
    user_id: number;
  };
}

const Company = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const [currentData, setCurrentData] = useState<CurrenDataType>({
    loading: false,
    error: false,
    data: {
      company_name: "",
      company_type: 0,
      created_at: "",
      deleted_at: null,
      iban: "",
      id: 0,
      kep_mail: "",
      mersis_number: "",
      store_name: "",
      tax_id: "",
      tax_office: "",
      updated_at: "",
      user_id: 0,
    },
  });
  const [modal, setModal] = useState({
    isOpen: false,
    modalTitle: "",
    description: "",
    title: "",
    info: "",
    scope: "",
  });

  const modalOpen = (
    modalTitle: string,
    info: string,
    title: string,
    description: string,
    scope: string
  ) => {
    setModal({
      ...modal,
      isOpen: true,
      modalTitle,
      title,
      info,
      description,
      scope,
    });
  };
  const onHide = () => {
    setModal({
      isOpen: false,
      modalTitle: "",
      title: "",
      info: "",
      description: "",
      scope: "",
    });
  };

  console.log(currentData.data);

  const companyGet = async (
    scope: string | null = null,
    formData: any = undefined,
    setLoading: any = undefined
  ) => {
    if (!scope) {
      setCurrentData({ ...currentData, loading: true, error: false });
    } else {
      setLoading(true);
    }
    try {
      const data = await sendRequestWithCache(
        scope ? "POST" : "GET",
        scope ? `/seller-data/6/${scope}` : `/seller-data/${currentUser.id}`,
        formData,
        scope ? {} : { cache: true, cacheName: "company", cacheTime: 500 },
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );
      if (!scope) {
        return setCurrentData({
          ...currentData,
          loading: false,
          error: false,
          data,
        });
      }
      const existingData = getItem("company");
      existingData.data[scope] = formData.get(scope);
      setItem("company", existingData);
      toastSuccessNotify(data.message);
      onHide();
      setLoading(false);

      return setCurrentData({
        ...currentData,
        loading: false,
        error: false,
        data: { ...currentData.data, [scope]: formData.get(scope) },
      });
    } catch (error) {
      if (!scope) {
        return setCurrentData({ ...currentData, loading: false, error: true });
      }
      toastErrorNotify("Bir hata oluştu !");
      setLoading(false);
    }
  };

  useEffect(() => {
    companyGet();
  }, []);

  return (
    <>
      {!currentData.loading && !currentData.error && (
        <div className="flex flex-col gap-5 mt-4">
          <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 xl:w-2/3">
            <div className="mb-4 relative">
              <label
                htmlFor="productCode"
                className="inline-block mb-2 text-base font-medium"
              >
                Mağaza Adı
              </label>
              <input
                type="text"
                id="productCode"
                name="productCode"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Mağaza Adı"
                value={currentData.data.store_name || ""}
                disabled
              />
              <button
                type="button"
                onClick={() =>
                  modalOpen(
                    "Mağaza adını",
                    "Yeni mağaza adını giriniz.",
                    "Mağaza adı",
                    "NOT: 10 gün içerisinde mağaza adını güncelleyemeyeceksiniz",
                    "store_name"
                  )
                }
              >
                <Pencil size="20" className="absolute top-10 right-2" />
              </button>
            </div>
            <div className="mb-4 relative">
              <label
                htmlFor="title"
                className="inline-block mb-2 text-base font-medium"
              >
                IBAN Nmarası
              </label>
              <input
                type="IBAN Numarası"
                id="title"
                name="title"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Ürün adını giriniz"
                disabled
                value={currentData.data.iban || ""}
              />
              <button
                type="button"
                className="absolute top-10 right-2"
                onClick={() =>
                  modalOpen(
                    "IBAN Numarasını",
                    "Yeni iban numaranızı giriniz.",
                    "IBAN Numarası",
                    "IBAN Numarasının Uyması Gereken Kriteler: (Araştırılması gerek) örneğin banka hesap adları falan uyuşmalı.",
                    "iban"
                  )
                }
              >
                <Pencil size="20" />
              </button>
            </div>
            <div className="mb-4 relative">
              <label
                htmlFor="title"
                className="inline-block mb-2 text-base font-medium"
              >
                KEP Adresi
              </label>
              <input
                type="text"
                id="title"
                name="title"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Ürün adını giriniz"
                value={currentData.data.kep_mail || ""}
                disabled
              />
              <button
                type="button"
                onClick={() =>
                  modalOpen(
                    "KEP Adresini",
                    "Yeni Mersis numaranızı giriniz.",
                    "Mersis Numarası",
                    `KEP (Kayıtlı Elektronik Posta), yasal olarak geçerli ve teknik olarak güvenli elektronik posta olarak tanımlanmaktadır. KEP adresini kayıtlı elektronik posta hizmetleri sağlayıcıları ile iletişime geçerek temin edebilirsiniz:
                  `,
                    "kep_mail"
                  )
                }
              >
                <Pencil size="20" className="absolute top-10 right-2" />
              </button>
            </div>
            <div className="mb-4 relative">
              <label
                htmlFor="title"
                className="inline-block mb-2 text-base font-medium"
              >
                Mersis No
              </label>
              <input
                type="text"
                id="title"
                name="title"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Ürün adını giriniz"
                value={currentData.data.mersis_number || ""}
                disabled
              />
              <button
                type="button"
                onClick={() =>
                  modalOpen(
                    "Mersis No",
                    "Yeni Mersis numaranızı giriniz.",
                    "Mersis Numarası",
                    `Mersis numarası bilgisine aşağıdaki adımları takip ederek ulaşabilirsiniz:https://mersis.ticaret.gov.tr/Mersis/AnaSayfa`,
                    "mersis_number"
                  )
                }
              >
                <Pencil size="20" className="absolute top-10 right-2" />
              </button>
            </div>
          </div>
          <div className="mb-4 xl:w-2/3 flex flex-col gap-4">
            <h6>Şirket Bilgileri</h6>
            <p className="text-gray-400 break-all">
              Aşağıdaki bilgileriniz Gelir İdaresi Başkanlığı aracılığı ile
              doğrulanmıştır. Hatalı olduğunu düşünüyorsanız Satıcı Destek Hattı
              üzerinden bizimle iletişime geçebilirsiniz. Firma bilgileriniz
              Gelir İdaresi Başkanlığı'nda değişti ise "Güncelle" butonuna
              tıklayarak bilgilerinizin a-Commerce’de de güncellenmesini
              sağlayabilirsiniz.
            </p>
          </div>
          <form action="">
            <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 xl:w-2/3">
              <div className="mb-4">
                <label
                  htmlFor="productCode"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Vergi Dairesi
                </label>
                <input
                  type="text"
                  id="productCode"
                  name="productCode"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Mağaza Adı"
                  disabled
                  value={currentData.data.tax_office || ""}
                />

                {/* <Pencil size="20" className="absolute top-10 right-2" /> */}
              </div>
              <div className="mb-4 ">
                <label
                  htmlFor="title"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Şirket Ünvanı
                </label>
                <input
                  type="IBAN Numarası"
                  id="title"
                  name="title"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Ürün adını giriniz"
                  value={currentData.data.company_name || ""}
                  disabled
                />
                {/* <Pencil size="20" className="absolute top-10 right-2" /> */}
              </div>
              <div className="mb-4 ">
                <label
                  htmlFor="title"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Vergi Numarası
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Ürün adını giriniz"
                  value={currentData.data.tax_id || ""}
                  disabled
                />
                {/* <Pencil size="20" className="absolute top-10 right-2" /> */}
              </div>
              <div className="mb-4 ">
                <label
                  htmlFor="title"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Şirket Türü
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Ürün adını giriniz"
                  value={currentData.data.company_type || ""}
                  disabled
                />
                {/* <Pencil size="20" className="absolute top-10 right-2" /> */}
              </div>
            </div>
            <div className="flex justify-end xl:w-2/3">
              <button
                type="submit"
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 md:w-[172px] w-full"
              >
                Güncelle
              </button>
            </div>
          </form>
        </div>
      )}

      {currentData.loading && (
        <div className="flex flex-col items-center justify-center">
          <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-yellow-400"></div>
          <p>Yükleniyor...</p>
        </div>
      )}
      {!currentData.loading && currentData.error && (
        <div className="noresult">
          <div className="py-6 text-center">
            <NotFound title="Üzgünüm! Sonuç bulunamadı." />
          </div>
        </div>
      )}
      <CompanyModal modal={modal} onHide={onHide} companyGet={companyGet} />
    </>
  );
};

export default Company;
