import React, { useCallback, useEffect, useState } from 'react';
import { sendRequestWithCache } from 'helpers/request';
import { useAppSelector } from "../../../redux/hooks";
import { BarChart, CalendarRange, TrendingUp } from 'lucide-react';
import CountUp from 'react-countup';
import { SalesRevenueOverviewChart } from './Charts';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/flatpickr.css";
import { Turkish } from "flatpickr/dist/l10n/tr";

interface SalesRevenueData {
    name: string;
    data: number[];
}


const SalesRevenue = () => {
    const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
    const [loading, setLoading] = useState(true);
    const [series, setSeries] = useState<SalesRevenueData[]>([]);

    const fetchMonthlyOrders = useCallback(async () => {
        try {
            const { data } = await sendRequestWithCache("GET", "/user/dashboard/monthlyProfit", null, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });
            setSeries([
                {
                    name: 'Toplam Satış',
                    data: Object.values(data).map((item: any) => item.sales)
                },
                {
                    name: 'Toplam Hakediş',
                    data: Object.values(data).map((item: any) => item.profit)
                }
            ]);
        } catch (error) {
            return error
        } finally {
            setLoading(false);
        }
    }, [bearer_token]);

    useEffect(() => {
        fetchMonthlyOrders();
    }, [fetchMonthlyOrders]);
    
    return (
        <React.Fragment>
            <div className="col-span-12 card 2xl:col-span-8 relative">
                {loading && (
                    <div className="flex items-center justify-center h-full absolute inset-0">
                        <div className="flex flex-col items-center">
                            <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                        </div>
                    </div>
                )}
                <div className={`card-body  ${loading ? 'opacity-0' : ''}`}>
                    <div className="flex flex-col gap-4 mb-4 md:mb-3 md:items-center md:flex-row">
                        <h6 className="grow text-15">Hakediş Önizlemesi</h6>
                        <div className="relative">
                            <CalendarRange className="absolute size-4 ltr:left-3 rtl:right-3 top-3 text-slate-500 dark:text-zink-200"></CalendarRange>
                            <Flatpickr
                                className="ltr:pl-10 rtl:pr-10 form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                options={{
                                    dateFormat: "d M, Y",
                                    mode: "range",
                                    locale: Turkish,
                                }}
                                placeholder='Tarih Aralığı Seçin'
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-4 mb-3 overflow-hidden">
                        <div className="col-span-6">
                            <div className="flex items-center gap-3">
                                <div className="flex items-center justify-center size-12 rounded-md text-sky-500 bg-sky-50 shrink-0 dark:bg-sky-500/10">
                                    <BarChart />
                                </div>
                                <div className="grow">
                                    <p className="mb-1 text-slate-500 dark:text-zink-200">Toplan Hakediş</p>
                                    <h5 className="text-15"><CountUp end={series[1]?.data.reduce((sum, item) => sum + Number(item), 0)} decimals={2} className="counter-value" /> ₺</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-6">
                            <div className="flex items-center gap-3">
                                <div className="flex items-center justify-center size-12 text-green-500 rounded-md bg-green-50 shrink-0 dark:bg-green-500/10">
                                    <TrendingUp />
                                </div>
                                <div className="grow">
                                    <p className="mb-1 text-slate-500 dark:text-zink-200">Toplam Satış</p>
                                    <h5 className="text-15"><CountUp end={series[0]?.data.reduce((sum, item) => sum + Number(item), 0)} decimals={2} className="counter-value" /> ₺</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SalesRevenueOverviewChart chartId="salesRevenueOverview" series={series} />
                </div>

            </div>
        </React.Fragment>
    );
};

export default SalesRevenue;
