import { useCallback, useEffect, useState } from "react";
import CountUp from 'react-countup';
import { sendRequestWithCache } from 'helpers/request';
import { useAppSelector } from "../../../redux/hooks";
import { Boxes, ShoppingBag, ScrollText, MessagesSquare } from 'lucide-react';
import InvoicePerformance from "./InvoicePerformance";
import { Link } from "react-router-dom";

interface ProductDataTypes {
    customerQuestions: number;
    expectedReviews: number;
    reviseExpected: number;
    soldOuts: number;
}

const Statistics = () => {
    const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
    const [loading, setLoading] = useState(true);
    const [productData, setProductData] = useState<ProductDataTypes>();

    const fetchProductStats = useCallback(async () => {
        try {
            const { data } = await sendRequestWithCache("GET", "/user/dashboard/productStats", null, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });
            setProductData(data);
        } catch (error) {
            return error
        } finally {
            setLoading(false);
        }
    }, [bearer_token]);

    useEffect(() => {
        fetchProductStats();
    }, [fetchProductStats]);
    return (
        <div className="grid grid-cols-12 gap-x-5 col-span-12 2xl:col-span-4">
            <div className="col-span-6 2xl:row-span-1">
                <Link to='/product/inactiveProducts' className="block card 2xl:m-0 pt-3 pb-3 relative">
                    {loading && (
                        <div className="flex items-center justify-center h-full absolute inset-0">
                            <div className="flex flex-col items-center">
                                <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-400"></div>
                            </div>
                        </div>
                    )}
                    <div className={`flex flex-col sm:flex-row items-center gap-3 card-body ${loading ? 'opacity-0' : ''}`}>
                        <div className="flex items-center justify-center size-16 rounded-md text-15 bg-custom-50 text-custom-400 dark:bg-custom-500/20 shrink-0"><ShoppingBag /></div>
                        <div className="grow">
                            <h5 className="mb-1 text-16 text-center sm:text-start">
                                <CountUp end={Number(productData?.soldOuts)} separator="," className="counter-value" />
                            </h5>
                            <p className="text-slate-500 dark:text-zink-200 text-nowrap">Tükenen Ürünler</p>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-span-6 2xl:row-span-1">
                <Link to='/product/waitingProducts' className="block card 2xl:m-0 pt-3 pb-3 relative" title="Bekleyen revize işlemleri">
                    {loading && (
                        <div className="flex items-center justify-center h-full absolute inset-0">
                            <div className="flex flex-col items-center">
                                <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-400"></div>
                            </div>
                        </div>
                    )}
                    <div className={`flex flex-col sm:flex-row items-center gap-3 card-body ${loading ? 'opacity-0' : ''}`}>
                        <div className="flex items-center justify-center size-16 rounded-md text-15 bg-sky-50 text-sky-500 dark:bg-sky-500/20 shrink-0"><Boxes /></div>
                        <div className="grow">
                            <h5 className="mb-1 text-16 text-center sm:text-start">
                                <CountUp end={Number(productData?.reviseExpected)} separator="," className="counter-value" />
                            </h5>
                            <p className="text-slate-500 dark:text-zink-200 text-nowrap">Revize Bekleyenler</p>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-span-6 2xl:row-span-1">
                <Link to='/questions' className="block card 2xl:m-0 pt-3 pb-3 relative" title="Bekleyen müşteri soruları">
                    {loading && (
                        <div className="flex items-center justify-center h-full absolute inset-0">
                            <div className="flex flex-col items-center">
                                <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-yellow-400"></div>
                            </div>
                        </div>
                    )}
                    <div className={`flex flex-col sm:flex-row items-center gap-3 card-body ${loading ? 'opacity-0' : ''}`}>
                        <div className="flex items-center justify-center size-16 text-yellow-500 rounded-md text-15 bg-yellow-50 dark:bg-yellow-500/20 shrink-0"><MessagesSquare /></div>
                        <div className="grow">
                            <h5 className="mb-1 text-16 text-center sm:text-start">
                                <CountUp end={Number(productData?.customerQuestions)} separator="," className="counter-value" />
                            </h5>
                            <p className="text-slate-500 dark:text-zink-200 text-nowrap">Müşteri Soruları</p>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-span-6 2xl:row-span-1">
                <Link to='/reviews/product-reviews?list=date-based' className="block card 2xl:m-0 pt-3 pb-3 relative" title="Bekleyen kullanıcı değerlendirmeleri">
                    {loading && (
                        <div className="flex items-center justify-center h-full absolute inset-0">
                            <div className="flex flex-col items-center">
                                <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-purple-400"></div>
                            </div>
                        </div>
                    )}
                    <div className={`flex flex-col sm:flex-row items-center gap-3 card-body ${loading ? 'opacity-0' : ''}`}>
                        <div className="flex items-center justify-center size-16 text-purple-500 rounded-md text-15 bg-purple-50 dark:bg-purple-500/20 shrink-0"><ScrollText /></div>
                        <div className="grow">
                            <h5 className="mb-1 text-16 text-center sm:text-start">
                                <CountUp end={Number(productData?.expectedReviews)} separator="," className="counter-value" />
                            </h5>
                            <p className="text-slate-500 dark:text-zink-200 text-nowrap">Değerlendirmeler</p>
                        </div>
                    </div>
                </Link>
            </div>
            <InvoicePerformance />
        </div>
    )
}

export default Statistics;