
import TableContainer from "Common/TableContainer";
import { PropsType } from "./AllRefund";

const Analysis: React.FC<PropsType> = ({ data ,columns}) => {

  return (
    <>
      <div className="card">
        <div className="card-body">
          <TableContainer
            isPagination={true}
            columns={columns || []}
            data={data || []}
            customPageSize={10}
            divclassName="-mx-5 -mb-5 overflow-x-auto"
            tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
            theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
            thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
            tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5  border-b-[1px] dark:border-zink-600"
            PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
            downloadExcel
            isSelect
            title="İade Tarihi"
          />
        </div>
      </div>
    </>
  );
};

export default Analysis;
