import React, { useState, useCallback, useEffect } from "react";
import { sendRequestWithCache } from 'helpers/request';
import { useAppSelector } from "../../redux/hooks";
import { Truck, CreditCard, CircleDollarSign, FileText, X } from 'lucide-react';
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "Common/BreadCrumb";
import Dropzone from "react-dropzone"
import { UploadCloud } from "lucide-react";
import Modal from "../../Common/Components/Modal";
import modalImg from "assets/images/faq.png";
import NotFound from "Common/Components/NotFound/NotFound";


const SalesDetail = () => {
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState<boolean | null>(true);
    const [selectedBorderFile, setSelectedBorderFile] = useState<any>(null);
    const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
    const [orderDetails, setOrderDetails] = useState<any>(null);
    const [isModalShow, setIsModalShow] = useState<boolean>(false);
    const [isModalShowIndividual, setIsModalShowIndividual] = useState<boolean>(false);
    const [isModalShowCorporate, setIsModalShowCorporate] = useState<boolean>(false);

    const formatBytes = (bytes: any, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };
    const handleAcceptedBorderFile = (files: File[]) => {
        if (files[0].type.split('/')[1] !== 'pdf') return setIsModalShow(true);
        files.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setSelectedBorderFile(files);
    };

    const fetchOrderDetails = useCallback(async () => {
        setLoading(true);
        try {
            const response = await sendRequestWithCache("GET", `/order/detail/${id}`, null, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });
            setLoading(false)
            setOrderDetails(response);
        } catch (error) {
            setLoading(null);
            return error
        }
    }, [bearer_token, id]);

    useEffect(() => {
        fetchOrderDetails();
    }, [id, fetchOrderDetails]);

    const formatDate = (date: string) => {
        const dateObj = new Date(date);

        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        };

        return dateObj.toLocaleDateString('tr-TR', options).replace(' ', ' ').replace(',', '');
    }

    const formatPrice = (price: number) => {
         if (typeof price !== "number") return '0,00₺'
        return new Intl.NumberFormat("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price) + "₺";
    };

    if (loading === null) {
        return (
            <div className="card">
                <div className="card-body">
                    <NotFound />
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <BreadCrumb title='Sipariş Detayı' pageTitle='Siparişler & Kargo' />
            <div className="grid grid-cols-1 2xl:grid-cols-12 gap-x-5">
                <div className="2xl:col-span-9">
                    <div>
                        <div className="grid grid-cols-1 2xl:grid-cols-12 gap-x-5">
                            <div className="2xl:col-span-4">
                                <div className="card relative">
                                    {loading && (
                                        <div className="flex items-center justify-center h-full absolute inset-0">
                                            <div className="flex flex-col items-center">
                                                <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                                            </div>
                                        </div>
                                    )}
                                    <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                                        <div className="flex items-center justify-center size-12 bg-purple-100 rounded-md dark:bg-purple-500/20 ltr:float-right rtl:float-left">
                                            <Truck className="text-purple-500 fill-purple-200 dark:fill-purple-500/30" />
                                        </div>
                                        <h6 className="mb-4 text-15">Kargo Ayrıntıları</h6>
                                        <h6 className="mb-1">{orderDetails?.addresses.name || <br />} {orderDetails?.addresses.surname}</h6>
                                        <p className="mb-1 text-slate-500 dark:text-zink-200">{orderDetails?.addresses.full_address || <br />}</p>
                                        <p className="text-slate-500 dark:text-zink-200">{orderDetails?.addresses.district_name} / {orderDetails?.addresses.city_name} <b className="text-topbar-border-dark dark:text-zink-200">Adres Başlığı</b> {orderDetails?.addresses.title}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="2xl:col-span-4">
                                <div className="card relative">
                                    {loading && (
                                        <div className="flex items-center justify-center h-full absolute inset-0">
                                            <div className="flex flex-col items-center">
                                                <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                                            </div>
                                        </div>
                                    )}
                                    <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                                        <div className="flex items-center justify-center size-12 bg-orange-100 rounded-md dark:bg-orange-500/20 ltr:float-right rtl:float-left">
                                            <CreditCard className="text-orange-500 fill-orange-200 dark:fill-orange-500/30" />
                                        </div>
                                        <div className="flex items-center justify-between mb-4">
                                            <h6 className="text-15">Fatura Adresi</h6>
                                            <button className="text-black dark:text-white underline text-xs mr-2" onClick={() => setIsModalShowIndividual(true)}>Fatura Bilgileri</button>
                                        </div>
                                        <h6 className="mb-1">{orderDetails?.addresses.name || <br />} {orderDetails?.addresses.surname}</h6>
                                        <p className="mb-1 text-slate-500 dark:text-zink-200">{orderDetails?.addresses.full_address || <br />}</p>
                                        <p className="text-slate-500 dark:text-zink-200">{orderDetails?.addresses.district_name} / {orderDetails?.addresses.city_name}</p>
                                        <Modal show={isModalShowIndividual} onHide={() => { setIsModalShowIndividual(false) }} modal-center="true" className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4" dialogClassName='max-w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600'>
                                            <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] overflow-y-auto p-8">
                                                <h6 className="text-xl font-[600] mb-2 inline-block">Fatura Adresi</h6>
                                                <div className="float-right"><button className="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"><X className="size-6" onClick={() => setIsModalShowIndividual(false)} /></button></div>
                                                <div className="space-y-2 mt-4">
                                                    <div className="font-light text-16 text-slate-400 mb-3"><h5 className="inline-block text-16">Müşteri Tipi:</h5> Bireysel Müşteri</div>
                                                    <div className="font-light text-16 text-slate-400 mb-3"><h5 className="inline-block text-16">Ad Soyad:</h5> Onur Yücel</div>
                                                    <div className="font-light text-16 text-slate-400 mb-3"><h5 className="inline-block text-16">Adres:</h5> Karapürçek mah. 354.sokak 5/19 Altındağ/Ankara 6165</div>
                                                    <div className="font-light text-16 text-slate-400 mb-3"><h5 className="inline-block text-16">E-Posta:</h5> onuryucel1242@gmail.com</div>
                                                    <div className="font-light text-16 text-slate-400 mb-3"><h5 className="inline-block text-16">Telefon Numarası:</h5> +90 555 555 55 55</div>
                                                </div>
                                                <p className="mt-3 text-slate-400 text-sm">NOT: Faturanızı sayfanın sağında bulunan <span className="text-black">Fatura</span> alanından yükleyebilirsiniz.Ya da yukarıda bulunan e-posta adresine iletebilirsiniz.</p>
                                                <div className="mt-6 flex gap-4">
                                                    <button className="flex-1 px-4 py-2 bg-slate-200 rounded hover:bg-slate-300 font-light text-slate-500" onClick={() => setIsModalShowIndividual(false)}>Vazgeç</button>
                                                    <button className="flex-1 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 font-light">Yazdır</button>
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                        <Modal show={isModalShowCorporate} onHide={() => { setIsModalShowCorporate(false) }} modal-center="true" className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4" dialogClassName='max-w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600'>
                                            <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] overflow-y-auto p-8">
                                                <h6 className="text-xl font-[600] mb-2 inline-block">Fatura Adresi</h6>
                                                <div className="float-right"><button className="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"><X className="size-6" onClick={() => setIsModalShowCorporate(false)} /></button></div>
                                                <div className="space-y-2 mt-4">
                                                    <div className="font-light text-16 text-slate-400 mb-3"><h5 className="inline-block text-16">Müşteri Tipi:</h5> Kurumsal Müşteri</div>
                                                    <div className="font-light text-16 text-slate-400 mb-3"><h5 className="inline-block text-16">Ad Soyad:</h5> Onur Yücel</div>
                                                    <div className="font-light text-16 text-slate-400 mb-3"><h5 className="inline-block text-16">Adres:</h5> Karapürçek mah. 354.sokak 5/19 Altındağ/Ankara 6165</div>
                                                    <div className="font-light text-16 text-slate-400 mb-3"><h5 className="inline-block text-16">E-Posta:</h5> onuryucel1242@gmail.com</div>
                                                    <div className="font-light text-16 text-slate-400 mb-3"><h5 className="inline-block text-16">Telefon Numarası:</h5> +90 555 555 55 55</div>
                                                    <div className="font-light text-16 text-slate-400 mb-3"><h5 className="inline-block text-16">VKN:</h5> 1234567890</div>
                                                    <div className="font-light text-16 text-slate-400 mb-3"><h5 className="inline-block text-16">Vergi Dairesi:</h5> M.Adıgüzel</div>
                                                    <div className="font-light text-16 text-slate-400 mb-3"><h5 className="inline-block text-16">Firma Adı:</h5> Osman Pazarlama</div>
                                                    <div className="font-light text-16 text-slate-400 mb-3"><h5 className="inline-block text-16">E-Fatura Mükellefi:</h5> Hayır</div>
                                                </div>
                                                <p className="mt-3 text-slate-400 text-sm">NOT: Faturanızı sayfanın sağında bulunan <span className="text-black">Fatura</span> alanından yükleyebilirsiniz.Ya da yukarıda bulunan e-posta adresine iletebilirsiniz.</p>
                                                <div className="mt-6 flex gap-4">
                                                    <button className="flex-1 px-4 py-2 bg-slate-200 rounded hover:bg-slate-300 font-light text-slate-500" onClick={() => setIsModalShowCorporate(false)}>Vazgeç</button>
                                                    <button className="flex-1 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 font-light">Yazdır</button>
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                            <div className="2xl:col-span-4">
                                <div className="card relative">
                                    {loading && (
                                        <div className="flex items-center justify-center h-full absolute inset-0">
                                            <div className="flex flex-col items-center">
                                                <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                                            </div>
                                        </div>
                                    )}
                                    <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                                        <div className="flex items-center justify-center size-12 rounded-md ltr:float-right rtl:float-left bg-sky-100 dark:bg-sky-500/20">
                                            <CircleDollarSign className="text-sky-500 fill-sky-200 dark:fill-sky-500/30" />
                                        </div>
                                        <h6 className="mb-3 text-15">Ödeme Detayları</h6>
                                        <h6 className="mb-1">ID: #{orderDetails?.cart_id}</h6>
                                        <p className="mb-1 text-slate-500 dark:text-zink-200">Ödeme Yöntemi: <b>{orderDetails?.order_details.payment_type}</b></p>
                                        <p className="mb-1 text-slate-500 dark:text-zink-200">Kart Numarası: <b>xxxx xxxx xxxx {orderDetails?.order_details.cc_number_last_four}</b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 2xl:grid-cols-5 gap-x-5">
                            <div className="card relative">
                                {loading && (
                                    <div className="flex items-center justify-center h-full absolute inset-0">
                                        <div className="flex flex-col items-center">
                                            <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                                        </div>
                                    </div>
                                )}
                                <div className={`text-center card-body ${loading ? 'opacity-0' : ''}`}>
                                    <p className="uppercase mb-3.5 text-slate-500 dark:text-zink-200">SİPARİŞ NUMARASI</p>
                                    <h6 className="truncate">#{orderDetails?.cart_id}</h6>
                                </div>
                            </div>
                            <div className="card relative">
                                {loading && (
                                    <div className="flex items-center justify-center h-full absolute inset-0">
                                        <div className="flex flex-col items-center">
                                            <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                                        </div>
                                    </div>
                                )}
                                <div className={`text-center card-body ${loading ? 'opacity-0' : ''}`}>
                                    <p className="uppercase mb-3.5 text-slate-500 dark:text-zink-200">SİPARİŞ TARİHİ</p>
                                    <h6 className="truncate">{formatDate(orderDetails?.created_at)}</h6>
                                </div>
                            </div>
                            <div className="card relative">
                                {loading && (
                                    <div className="flex items-center justify-center h-full absolute inset-0">
                                        <div className="flex flex-col items-center">
                                            <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                                        </div>
                                    </div>
                                )}
                                <div className={`text-center card-body ${loading ? 'opacity-0' : ''}`}>
                                    <p className="uppercase mb-3.5 text-slate-500 dark:text-zink-200">TESLİMAT TARİHİ</p>
                                    <h6 className="truncate">{formatDate(orderDetails?.order_status_history[0].created_at)}</h6>
                                </div>
                            </div>
                            <div className="card relative">
                                {loading && (
                                    <div className="flex items-center justify-center h-full absolute inset-0">
                                        <div className="flex flex-col items-center">
                                            <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                                        </div>
                                    </div>
                                )}
                                <div className={`text-center card-body ${loading ? 'opacity-0' : ''}`}>
                                    <p className="uppercase mb-3.5 text-slate-500 dark:text-zink-200">ÖDEME MİKTARI</p>
                                    <h6 className="truncate">{formatPrice(orderDetails?.total_price)}</h6>
                                </div>
                            </div>
                            <div className="card relative">
                                {loading && (
                                    <div className="flex items-center justify-center h-full absolute inset-0">
                                        <div className="flex flex-col items-center">
                                            <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                                        </div>
                                    </div>
                                )}
                                <div className={`text-center card-body ${loading ? 'opacity-0' : ''}`}>
                                    <p className="uppercase mb-3 text-slate-500 dark:text-zink-200">SİPARİŞ DURUMU</p>
                                    <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-green-100 border-green-200 text-green-500 dark:bg-purple-500/20 dark:border-purple-500/20">{orderDetails?.order_status_history[orderDetails.order_status_history.length - 1].state}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="card relative">
                            {loading && (
                                <div className="flex items-center justify-center h-full absolute inset-0">
                                    <div className="flex flex-col items-center">
                                        <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                                    </div>
                                </div>
                            )}
                            <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                                <div className="flex items-center gap-3 mb-4">
                                    <h6 className="text-15 grow">Sipariş Özeti</h6>
                                    <Link to="#!" className="text-red-500 underline shrink-0">Siparişi İptal Et</Link>
                                </div>
                                <div className="overflow-x-auto">
                                    <table className="w-full">
                                        <tbody>
                                            {(orderDetails?.products || Array(10).fill({}))
                                                .map((product: any, index: number) => (
                                                    <tr key={index}>
                                                        <td className="px-3.5 py-4 border-b border-dashed first:pl-0 last:pr-0 border-slate-200 dark:border-zink-500">
                                                            <div className="flex items-center gap-3">
                                                                <div className="flex items-center justify-center size-12 rounded-md bg-slate-100 shrink-0">
                                                                    <img src={product.image} alt="" className="h-8" />
                                                                </div>
                                                                <div className="grow">
                                                                    <h6 className="mb-1 text-15"><Link to="#" className="transition-all duration-300 ease-linear hover:text-custom-500 line-clamp-1">{product.title}</Link></h6>
                                                                    <p className="text-slate-500 dark:text-zink-200">{formatPrice(product.price)} x {product.count}</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="px-3.5 py-4 border-b border-dashed first:pl-0 last:pr-0 border-slate-200 dark:border-zink-500 ltr:text-right rtl:text-left">{formatPrice(product.total_price)}</td>
                                                    </tr>
                                                ))}
                                            <tr>
                                                <td className="px-3.5 pt-4 pb-3 first:pl-0 last:pr-0 text-slate-500 dark:text-zink-200">
                                                    Alt Toplam
                                                </td>
                                                <td className="px-3.5 pt-4 pb-3 first:pl-0 last:pr-0 ltr:text-right rtl:text-left">{formatPrice(orderDetails?.price_table.product_price)}</td>
                                            </tr>
                                            <tr>
                                                <td className="px-3.5 py-3 first:pl-0 last:pr-0 text-slate-500 dark:text-zink-200">
                                                    KDV
                                                </td>
                                                <td className="px-3.5 py-3 first:pl-0 last:pr-0 ltr:text-right rtl:text-left">{formatPrice(orderDetails?.price_table.total_tax)}</td>
                                            </tr>
                                            <tr>
                                                <td className="px-3.5 py-3 first:pl-0 last:pr-0 text-slate-500 dark:text-zink-200">
                                                    Kupon İndirimi
                                                </td>
                                                <td className="px-3.5 py-3 first:pl-0 last:pr-0 ltr:text-right rtl:text-left">{formatPrice(orderDetails?.price_table.total_discount)}</td>
                                            </tr>
                                            <tr>
                                                <td className="px-3.5 py-3 first:pl-0 last:pr-0 text-slate-500 dark:text-zink-200">
                                                    Kargo Ücreti
                                                </td>
                                                <td className="px-3.5 py-3 first:pl-0 last:pr-0 ltr:text-right rtl:text-left">{formatPrice(0)}</td>
                                            </tr>
                                            <tr className="font-semibold">
                                                <td className="px-3.5 pt-3 first:pl-0 last:pr-0 text-slate-500 dark:text-zink-200">
                                                    Toplam Miktar
                                                </td>
                                                <td className="px-3.5 pt-3 first:pl-0 last:pr-0 ltr:text-right rtl:text-left">{formatPrice(orderDetails?.price_table.total_price)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="card relative">
                            {loading && (
                                <div className="flex items-center justify-center h-full absolute inset-0">
                                    <div className="flex flex-col items-center">
                                        <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                                    </div>
                                </div>
                            )}
                            <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                                <div>
                                    {(orderDetails?.order_status_history || Array(5).fill({}))
                                        .map((status: { state: string; message: string; created_at: string }, index: number) => (
                                            <div key={index} className="relative ltr:pl-6 rtl:pr-6 before:absolute ltr:before:border-l rtl:before:border-r ltr:before:left-[0.1875rem] rtl:before:right-[0.1875rem] before:border-slate-200 [&.done]:before:border-custom-500 before:top-1.5 before:-bottom-1.5 after:absolute after:size-2 after:bg-white after:border after:border-slate-200 after:rounded-full ltr:after:left-0 rtl:after:right-0 after:top-1.5 pb-4 last:pb-0 last:before:hidden [&.done]:after:bg-custom-500 [&.done]:after:border-custom-500 done">
                                                <div className="flex gap-4">
                                                    <div className="grow">
                                                        <h6 className="mb-2 text-gray-800 text-15 dark:text-zink-50">{status.state || '-'}</h6>
                                                        <p className="text-gray-400 dark:text-zink-200">{status.message || '-'}</p>
                                                    </div>
                                                    <p className="text-sm text-gray-400 dark:text-zink-200 shrink-0">{formatDate(status.created_at || '-')}</p>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="2xl:col-span-3">
                    <div className="card">
                        <div className="card-body">
                            <h6 className="mb-4 text-15">Fatura</h6>
                            {
                                !selectedBorderFile ? (
                                    <div className="flex items-center justify-center bg-white border border-dashed rounded-md cursor-pointer dropzone min-h-[186.95px] border-slate-300 dropzone2 dark:bg-zink-700 dark:border-zink-500">
                                        <Dropzone
                                            onDrop={(acceptedFiles: any) => {
                                                handleAcceptedBorderFile(acceptedFiles)
                                            }}
                                        >
                                            {({ getRootProps, getInputProps }: any) => (
                                                <div
                                                    className="w-full py-5 text-lg text-center dz-message needsclick"
                                                    {...getRootProps()}
                                                >
                                                    <input {...getInputProps()} />
                                                    <div className="mb-3">
                                                        <UploadCloud className="block size-12 mx-auto text-slate-500 fill-slate-200 dark:text-zink-200 dark:fill-zink-500"></UploadCloud>
                                                    </div>

                                                    <h5 className="mb-0 font-normal text-slate-500 text-15">Faturanızı PDF formatında yükleyiniz.</h5>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </div>
                                ) : (
                                    <div className="border rounded border-slate-200 dark:border-zink-500">
                                        <div className="p-2 text-center relative">
                                            <div>
                                                <FileText className="text-slate-500 dark:text-zink-200 fill-slate-200 dark:fill-zink-500 size-14 mx-auto" />
                                            </div>
                                            <div className="pt-3">
                                                <h5 className="mb-1 text-15" data-dz-name>{selectedBorderFile[0].name}</h5>
                                                <p className="mb-0 text-slate-500 dark:text-zink-200" data-dz-size>{selectedBorderFile[0].formattedSize}</p>
                                            </div>
                                            <div className="absolute right-1 top-1">
                                                <button
                                                    className="p-1 text-xs text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-custom-400/20"
                                                    onClick={() => setSelectedBorderFile(null)}
                                                >
                                                    <X className="size-6" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <Modal show={isModalShow} onHide={() => { setIsModalShow(false) }} modal-center="true" className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4" dialogClassName='w-screen md:w-[25rem] bg-white shadow rounded-md dark:bg-zink-600'>
                                <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] overflow-y-auto px-6 py-8">
                                    <div className="float-right"><button onClick={() => setIsModalShow(false)} className="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"><X className="size-5" /></button></div>
                                    <img src={modalImg} alt="" className="block h-12 mx-auto" />
                                    <div className="mt-5 text-center">
                                        <h5 className="mb-1">Bir hata ile karşılaşıldı!</h5>
                                        <p className="text-slate-500 dark:text-zink-200">Lütfen faturayı PDF formatında yükleyiniz.</p>
                                        <div className="flex justify-center gap-2 mt-6">
                                            <button type="submit" className="text-white bg-blue-500 border-red-blue btn hover:bg-blue-600 hover:border-blue-600 focus:bg-blue-600 focus:border-blue-600 focus:ring focus:ring-blue-100  active:bg-blue-600 active:border-blue-600 active:ring active:ring-blue-100 dark:ring-custom-400/20" onClick={() => setIsModalShow(false)}>Tamam</button>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    <div className="card relative">
                        {loading && (
                            <div className="flex items-center justify-center h-full absolute inset-0">
                                <div className="flex flex-col items-center">
                                    <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                                </div>
                            </div>
                        )}
                        <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                            <div className="flex items-center gap-3 mb-4">
                                <h6 className="text-15 grow">Kargo Firması</h6>
                            </div>
                            <div className="flex gap-4">
                                <div className="shrink-0 h-10 w-10">
                                    <img src={orderDetails?.cargo.image} alt="" />
                                </div>
                                <div className="grow">
                                    <h6 className="mb-1 text-15">{orderDetails?.cargo.name || <br />}</h6>
                                    <p className="text-slate-500 dark:text-zink-200">Kargo Kodu: <b>#{orderDetails?.cargo_id}</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </React.Fragment >
    );
};

export default SalesDetail;