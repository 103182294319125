import React from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import SalesTransactions from './SalesTransactions';

const CanceledSales = () => {

    return (
        <React.Fragment>
            <BreadCrumb title='İptal Edilenler' pageTitle='Siparişler & Kargo' />
            <SalesTransactions />
        </React.Fragment>
    );
};

export default CanceledSales;
