import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Link, useParams } from "react-router-dom";
import { Search } from "lucide-react";
import { Nav } from "../../Common/Components/Tab/Nav";
import Tab from "../../Common/Components/Tab/Tab";
import BreadCrumb from "../../Common/BreadCrumb";
import AllProduct from "./route/AllProduct";
import ActiveProduct from "./route/ActiveProduct";
import WaitingProduct from "./route/WaitingProduct";
import InActiveproduct from "./route/InActiveproduct";
import { EventFunc, SubmitFunc } from "../../models/models";
import { sendRequestWithCache } from "../../helpers/request";
import { getItem, removeItem } from "helpers/localStorageControl";
import { useAppSelector } from "../../redux/hooks";
import { toastErrorNotify, toastSuccessNotify } from "helpers/ToasNotify";
import NotFound from "pages/finance/NotFound";
import DeleteModal from "../../Common/Components/product/DeleteModal";
import UnarchiveModal from "../../Common/Components/product/UnarchiveModal";

interface CurrentDataType {
  data: {} | undefined;
  loading: boolean;
  error: boolean;
  tableData: any[];
  state: string | undefined;
}

interface InputType {
  productCode: string;
  title: string;
  category: any;
  brand: any;
}
export interface modalStateType {
  isOpen: boolean;
  title: string;
  id: number | null;
}
export interface ProductProps {
  modalOpen: any;
  currentData: CurrentDataType;
  unarchiveModalOpen?: any;
}
export interface column {
  header: string;
  accessorKey: string;
  enableColumnFilter: boolean;
  enableSorting: boolean;
}
export interface UnarchiveState {
  isOpen: boolean;
  product: any;
}

const Product = () => {
  const params = useParams();
  const path = Object.values(params).toString();
  const [currentData, setCurrentData] = useState<CurrentDataType>({
    data: getItem(path)?.data || null,
    loading: false,
    error: false,
    tableData: getItem(path)?.data?.data?.data || [],
    state: getItem(path)?.data?.state.toString() || "",
  });
  const [inputState, setInputState] = useState<InputType>({
    productCode: "",
    title: "",
    category: "",
    brand: "",
  });
  const [modal, setModal] = useState<modalStateType>({
    isOpen: false,
    title: "",
    id: null,
  });
  const [userBrands, setUserBrands] = useState(
    getItem("userBrands")?.data?.data || []
  );
  const [userCategories, setUserCategories] = useState<any>([]);

  const [unarchiveState, setUnarchiveState] = useState<UnarchiveState>({
    isOpen: false,
    product: {},
  });

  const { currentUser } = useAppSelector((state) => state.auth);
  const { tableData, loading, error } = currentData;

  // modal kapatma
  const modalClose = () => {
    setModal({ isOpen: false, title: "", id: null });
  };
  // modal açma
  const modalOpen = (title: string, id: number) => {
    setModal({ isOpen: true, title, id });
  };

  const productGet = async (
    info: any = null,
    search: boolean = false,
    radioInput: string | null = null
  ) => {
    const formData = new FormData();

    let page = "0";
    if (radioInput) {
      page = radioInput;
    } else {
      if (path === "activeProducts") page = "1";
      if (path === "waitingProducts") page = "3";
      if (path === "inactiveProducts") page = "2";
    }

    if (info !== null) {
      if (info.productCode.length > 0) {
        formData.append("product_code", info.productCode);
      }
      if (info.title.length > 0) {
        formData.append("product_name", info.title);
      }
      if (inputState.category?.value) {
        formData.append("category_id", info.category.value.toString());
      }
      if (inputState.brand?.value) {
        formData.append("brand_id", inputState.brand.value.toString());
      }
    }
    setCurrentData({ ...currentData, loading: true, error: false });
    try {
      const res = await sendRequestWithCache(
        "POST",
        // "/product/search?page=0",
        `/product/search/${page}`,
        formData,
        search
          ? {}
          : {
              cache: true,
              cacheName: path,
              cacheTime: 300,
            },
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );
      if (res.data.data.length === 0) {
        throw new Error();
      }
      setCurrentData({
        ...currentData,
        data: res.data,
        tableData: res.data.data,
        loading: false,
        error: false,
        state: res.state.toString(),
      });
    } catch (error) {
      setCurrentData({
        ...currentData,
        loading: false,
        error: true,
        data: {},
        tableData: [],
        state: "",
      });
    }
  };

  const userBrandsGet = async () => {
    try {
      const { data } = await sendRequestWithCache(
        "GET",
        `/user/brands`,
        null,
        { cache: true, cacheName: "userBrands", cacheTime: 3000 },
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );

      setUserBrands(data);
    } catch (error) {
      setUserBrands([]);
    }
  };
  const userCategoriesGet = async () => {
    try {
      const { data } = await sendRequestWithCache(
        "GET",
        `/user/categories`,
        null,
        { cache: true, cacheName: "userCategories", cacheTime: 3000 },
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );
      const newData = Object.values(data);

      setUserCategories(newData);
    } catch (error) {
      setUserCategories([]);
    }
  };

  const productDelete = async (id: number) => {
    try {
      const res = await sendRequestWithCache(
        "POST",
        `/product/${id}/delete`,
        null,
        {},
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );
      if (!res.success) {
        throw new Error();
      }
      const data = tableData.filter(
        (item: any) => Number(item.id) !== Number(id)
      );
      setCurrentData({ ...currentData, tableData: data });

      toastSuccessNotify(res.message);
      removeItem("allProducts");
      removeItem("activeProducts");
      removeItem("waitingProducts");
      removeItem("inactiveProducts");
      modalClose();
    } catch (error) {
      toastErrorNotify("Ürün silinirken sorun oluştu.Tekrar deneyiniz. ");
    }
  };

  const productArchive = async (id: number) => {
    try {
      const res = await sendRequestWithCache(
        "POST",
        `/product/${id}/archive`,
        null,
        {},
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );
      if (!res.success) {
        throw new Error();
      }
      const data = tableData.filter(
        (item: any) => Number(item.id) !== Number(id)
      );
      // const newData = tableData.map((item: any) =>
      //   Number(item.id) === Number(id)
      //     ? {
      //         ...item,
      //         stock: 0,
      //         stateTitle: "Ürün Arşivde",
      //         stateMessage: "Ürün Arşive Alınmış.",
      //         price: "0",
      //         original_price: "0",
      //       }
      //     : item
      // );
      setCurrentData({ ...currentData, tableData: data });
      removeItem("allProducts");
      removeItem("activeProducts");
      removeItem("waitingProducts");
      removeItem("inactiveProducts");
      toastSuccessNotify("Ürün başarıyla arşivlendi.");
      modalClose();
    } catch (error) {
      toastErrorNotify("Ürün arşivlenirken sorun oluştu.Tekrar deneyiniz. ");
    }
  };

  //  inputdan gelen veriyi bastırma
  const handleChange: EventFunc = (event) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const handleRadio: EventFunc = (event) => {
    if (event.target.id === "all" && getItem(path)) {
      return setCurrentData((state) => ({
        ...state,
        tableData: getItem(path)?.data?.data?.data,
      }));
    }
    productGet(null, true, event.target.value);
  };

  // filter buttonunda basıldığında istenilen urunu getirme apisi
  const handleSubmit: SubmitFunc = (event) => {
    event.preventDefault();
    productGet(inputState, true, currentData.state);
  };
  // select boxlar için gelen veriyi bastırma
  const handleSelect = (select: any, section: string) => {
    if (section === "brand") {
      setInputState({ ...inputState, brand: select });
    } else {
      setInputState({ ...inputState, category: select });
    }
  };
  const unarchiveModalOpen = (product: {}) => {
    setUnarchiveState({ ...unarchiveState, isOpen: true, product });
  };
  const unarchiveModalOff = () => {
    setUnarchiveState({ ...unarchiveState, isOpen: false });
  };

  const unarchiveProduct = async (
    state: { stock: string; price: string; originalPrice: string },
    product: any,
    setState: any
  ) => {
    const formData = new FormData();
    formData.append("stock", state.stock);
    formData.append("price", state.price);
    formData.append("original_price", state.originalPrice);

    try {
      const res = await sendRequestWithCache(
        "POST",
        `/product/${product.id}/restore`,
        formData,
        {},
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );
      const data = tableData.filter(
        (item: any) => Number(item.id) !== Number(product.id)
      );
      setCurrentData({ ...currentData, tableData: data });
      toastSuccessNotify(res.message);
      removeItem("allProducts");
      removeItem("activeProducts");
      removeItem("waitingProducts");
      removeItem("inactiveProducts");
      unarchiveModalOff();
      setState((state: any) => ({
        ...state,
        stock: "",
        originalPrice: "",
        price: "",
      }));
    } catch (error) {
      toastErrorNotify(
        "Ürün arşivden çıkarılırken sorun oluştu.Tekrar deneyiniz."
      );
    }
  };

  useEffect(() => {
    productGet();
  }, [path]);

  useEffect(() => {
    userBrandsGet();
    userCategoriesGet();
  }, []);

  const handleClean = () => {
    setInputState({
      productCode: "",
      title: "",
      category: "",
      brand: "",
    });
  };

  return (
    <React.Fragment>
      <BreadCrumb title="Ürün Listesi" pageTitle="Ürün" />
      <div className="card">
        <div className="card-body flex flex-col gap-5">
          <Tab.Container defaultActiveKey={path}>
            <Nav className="flex flex-wrap w-full text-sm font-medium text-center border-b border-slate-200 dark:border-zink-500 nav-tabs">
              <Nav.Item eventKey="allProducts" className="group">
                <Link
                  to="allProducts"
                  data-tab-toggle
                  data-target="/product/allProducts"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  <span className="align-middle">Tüm Ürünler</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="activeProducts" className="group">
                <Link
                  to="activeProducts"
                  data-tab-toggle
                  data-target="/product/activeProducts"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  <span className="align-middle">Aktif Ürünler</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="waitingProducts" className="group">
                <Link
                  to="waitingProducts"
                  data-tab-toggle
                  data-target="/product/waitingProducts"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  <span className="align-middle">Onay Sürecindeki Ürünler</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="inactiveProducts" className="group">
                <Link
                  to="inactiveProducts"
                  data-tab-toggle
                  data-target="/product/inactiveProducts"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  <span className="align-middle">Pasif Ürünler</span>
                </Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
          <form
            action="#!"
            className="flex flex-col gap-5"
            onSubmit={handleSubmit}
          >
            {path === "inactiveProducts" && (
              <div className="flex gap-5">
                <div className="flex flex-wrap gap-5">
                  <div className="flex items-center gap-2">
                    <input
                      id="all"
                      name="InlineRadio"
                      className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500"
                      type="radio"
                      onChange={handleRadio}
                      value="2"
                      defaultChecked
                    />
                    <label htmlFor="radioInline1" className="align-middle">
                      Tümü
                    </label>
                  </div>

                  <div className="flex items-center gap-2">
                    <input
                      id="outOffStock"
                      name="InlineRadio"
                      className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-green-500 checked:border-green-500 dark:checked:bg-green-500 dark:checked:border-green-500"
                      type="radio"
                      value="10"
                      onChange={handleRadio}
                    />
                    <label htmlFor="radioInline2" className="align-middle">
                      Tükenenler
                    </label>
                  </div>

                  <div className="flex items-center gap-2">
                    <input
                      id="archived"
                      name="InlineRadio"
                      className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-orange-500 checked:border-orange-500 dark:checked:bg-orange-500 dark:checked:border-orange-500"
                      type="radio"
                      value="5"
                      onChange={handleRadio}
                    />
                    <label htmlFor="archived" className="align-middle">
                      Arşivdekiler
                    </label>
                  </div>

                  <div className="flex items-center gap-2">
                    <input
                      id="radioInline4"
                      name="InlineRadio"
                      className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-sky-500 checked:border-sky-500 dark:checked:bg-sky-500 dark:checked:border-sky-500"
                      type="radio"
                      value=""
                      disabled
                    />
                    <label htmlFor="radioInline4" className="align-middle">
                      Kilitliler
                    </label>
                  </div>
                  <div className="flex items-center gap-2">
                    <input
                      id="radioInline5"
                      name="InlineRadio"
                      className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-sky-500 checked:border-sky-500 dark:checked:bg-sky-500 dark:checked:border-sky-500"
                      type="radio"
                      value=""
                      disabled
                    />
                    <label htmlFor="radioInline5" className="align-middle">
                      Satışa Kapatılanlar
                    </label>
                  </div>
                </div>
              </div>
            )}
            <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 xl:grid-cols-4">
              <div className="mb-4">
                <label
                  htmlFor="productCode"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Ürün Kodu
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <input
                  type="search"
                  id="productCode"
                  name="productCode"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Ürün kodunuzu giriniz"
                  onChange={handleChange}
                  value={inputState.productCode}

                  // defaultValue="Sophia"
                  // required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="title"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Ürün Adı
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <input
                  type="search"
                  id="title"
                  name="title"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Ürün adını giriniz"
                  // defaultValue="Bethany"
                  onChange={handleChange}
                  // required
                  value={inputState.title}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="category"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Kategori
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <Select
                  className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  id="category"
                  name="category"
                  data-choices
                  // data-choices-multiple-groups="true"
                  // isMulti
                  placeholder="Kategori seçiniz"
                  options={userCategories}
                  onChange={(event: any) => handleSelect(event, "categories")}
                  value={inputState?.category}
                  isSearchable
                  isClearable
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="brand"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Marka
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <Select
                  className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  id="brand"
                  name="brand"
                  data-choices
                  placeholder="Marka seçiniz"
                  // data-choices-multiple-groups="true"
                  // isMulti
                  onChange={(event: any) => handleSelect(event, "brand")}
                  options={userBrands}
                  value={inputState.brand}
                  isSearchable
                  isClearable
                />
              </div>
            </div>
            <div className="flex justify-end gap-2">
              <button
                type="reset"
                className="text-slate-500 btn bg-slate-200 border-slate-200 hover:text-slate-600 hover:bg-slate-300 hover:border-slate-300 focus:text-slate-600 focus:bg-slate-300 focus:border-slate-300 focus:ring focus:ring-slate-100 active:text-slate-600 active:bg-slate-300 active:border-slate-300 active:ring active:ring-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 dark:border-zink-600 dark:hover:border-zink-500 dark:text-zink-200 dark:ring-zink-400/50 md:w-[172px] w-full"
                onClick={handleClean}
              >
                <span className="align-middle">Temizle</span>
              </button>
              <button
                type="submit"
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 md:w-[172px] w-full"
              >
                Filtrele
              </button>
            </div>
          </form>
        </div>
      </div>

      {loading && (
        <div className="flex flex-col items-center justify-center">
          <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-yellow-400"></div>
          <p>Yükleniyor...</p>
        </div>
      )}
      {!loading && error && (
        <div className="noresult">
          <div className="py-6 text-center">
            <NotFound title="Üzgünüm! Sonuç bulunamadı." />
          </div>
        </div>
      )}

      {tableData.length === 0 && !loading && !error && (
        <div className="noresult">
          <div className="py-6 text-center">
            <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
            <h5 className="mt-2 mb-1">Ekli ürün yok.</h5>
            <Link to="/addproduct">Ürün eklemek için buraya tıklayınız.</Link>
          </div>
        </div>
      )}
      {path === "allProducts" && !loading && !error && tableData.length > 0 && (
        <AllProduct modalOpen={modalOpen} currentData={currentData} />
      )}
      {path === "activeProducts" &&
        !loading &&
        !error &&
        tableData.length > 0 && (
          <ActiveProduct modalOpen={modalOpen} currentData={currentData} />
        )}
      {path === "waitingProducts" &&
        !loading &&
        !error &&
        tableData.length > 0 && (
          <WaitingProduct modalOpen={modalOpen} currentData={currentData} />
        )}
      {path === "inactiveProducts" &&
        !loading &&
        !error &&
        tableData.length > 0 && (
          <InActiveproduct
            modalOpen={modalOpen}
            currentData={currentData}
            unarchiveModalOpen={unarchiveModalOpen}
          />
        )}

      <DeleteModal
        modal={modal}
        onHide={modalClose}
        productDelete={productDelete}
        productArchive={productArchive}
      />
      <UnarchiveModal
        unarchiveState={unarchiveState}
        onHide={unarchiveModalOff}
        unarchiveProduct={unarchiveProduct}
      />
    </React.Fragment>
  );
};

export default Product;
