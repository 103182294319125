

import React from "react";
import ReactApexChart from "react-apexcharts";
import useChartColors from "Common/useChartColors";

interface NetTurnoverType {
    chartId: string;
    monthlyNetRevenueReport: any;
}

const NetTurnover = ({ chartId, monthlyNetRevenueReport }: NetTurnoverType) => {
    const chartColors = useChartColors(chartId);
    const series = [{
        name: '',
        data: monthlyNetRevenueReport.map((item: any) => item.revenue)
    }];

    const options: any = {
        yaxis: {
            min: 0,
            forceNiceScale: true,
            labels: {
                formatter: function (val: number) {
                    return val;
                }
            }
        },
        chart: {
            type: 'line',
            height: 310,
            toolbar: {
                show: false,
            },
        },
        tooltip: {
            x: {
                show: false
            },
            y: {
                formatter: function (val: number) {
                    return val + " Sipariş";
                }
            }
        },
        stroke: {
            curve: 'straight',
            width: 4,
        },
        colors: chartColors,
        dataLabels: {
            enabled: false
        },
        grid: {
            show: true,
            padding: {
                top: -20,
                right: 0,
            }
        },
        markers: {
            hover: {
                sizeOffset: 4
            }
        },
        xaxis: {
            categories: ["Oca", "Şub", "Mar", "Nis", "May", "Haz", "Tem", "Ağu", "Eyl", "Eki", "Kas", "Ara"],
            labels: {
                formatter: function (val: string) {
                    return val;
                }
            }
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series}
                id={chartId}
                className="apex-charts"
                type='line'
                height={310}
            />
        </React.Fragment>
    );
};

export default NetTurnover;