import React, { useCallback, useEffect, useState } from "react";
import { sendRequestWithCache } from 'helpers/request';
import { useAppSelector } from "../../redux/hooks";
import BreadCrumb from 'Common/BreadCrumb';
import { Nav } from "../../Common/Components/Tab/Nav";
import Tab from "../../Common/Components/Tab/Tab";
import { Link, useNavigate, useParams } from "react-router-dom";
import FuturePage from './FuturePage';
import PastPage from './PastPage';
import { Info } from "lucide-react";

interface FinanceTotals {
    [key: string]: number;
}

const Finance = () => {
    const { path } = useParams<{ path: string }>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [totalsData, setTotalsData] = useState<FinanceTotals>({
        total_other_price: 0,
        total_commission: 0,
        total_net_earnings: 0,
        total_price: 0,
        total_shipment_price: 0
    })
    const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
    const navigate = useNavigate();

    const fetchTotals = useCallback(async () => {
        setLoading(true);
        try {
            const { totals } = await sendRequestWithCache("GET", "/finance/totals", null, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });
            setTotalsData(totals);
        } catch (error) {
            return error
        } finally {
            setLoading(false);
        }
    }, [bearer_token]);

    useEffect(() => {
        fetchTotals();
    }, [fetchTotals]);

    useEffect(() => {
        if (path !== 'date' && path !== 'order' && path !== 'future') navigate('/finance/payments/past/date');
    }, [path, navigate]);

    const formatPrice = (price: number) => {
         if (typeof price !== "number") return '0,00₺'
        return new Intl.NumberFormat("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price) + "₺";
    };

    return (
        <React.Fragment>
            <BreadCrumb title='Ödemeler' pageTitle='Finans' />
            <React.Fragment>
                <div className="grid grid-cols-12 gap-5 mb-2">
                    <div className="card col-span-12 md:col-span-8 mb-0 relative">
                        {loading && (
                            <div className="flex items-center justify-center h-full absolute inset-0">
                                <div className="flex flex-col items-center">
                                    <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-lime-400"></div>
                                </div>
                            </div>
                        )}
                        <div className={`card-body flex flex-col justify-around h-full ${loading ? 'opacity-0' : ''}`}>
                            <div className='flex justify-between mb-2 gap-5'>
                                <div className="flex-1">
                                    <h2 className='font-normal text-2xl text-wrap'>Ödenecek Tutar</h2>
                                    <span className="text-gray-400 text-xl">{formatPrice(totalsData.total_net_earnings)}</span>
                                </div>
                                <div className='flex flex-1 md:flex-row flex-col gap-1'>
                                    <h6 className='font-normal text-gray-400 text-md'>Ödeme Günü:</h6>
                                    <span className="text-black dark:text-white font-medium text-md">02 Mayıs Perşembe</span>
                                </div>
                            </div>
                            <div className="grid lg:grid-cols-4 grid-cols-2 gap-5">
                                <div>
                                    <div className="text-sm text-slate-500 dark:text-slate-300 flex items-center gap-1">
                                        Satış Tutarı
                                        <div className="relative group group/item">
                                            <Info size={14} className='text-blue-400' />
                                            <div className="absolute group/edit invisible group-hover/item:visible group-hover/item:opacity-100 left-0 bottom-full mb-2 w-48 p-2 text-xs text-white bg-black rounded-lg opacity-0 transition-opacity duration-200 text-justify">
                                                Vadesi gelmiş ve ilgili ödeme gününe dahil olan siparişlerden elde edilen satış tutarı.
                                            </div>
                                        </div>
                                    </div>
                                    <span className="text-black dark:text-white">{formatPrice(totalsData.total_price)}</span>
                                </div>
                                <div>
                                    <div className="text-sm text-slate-500 dark:text-slate-300 flex items-center gap-1">
                                        Komisyon Kesintisi
                                        <div className="relative group group/item">
                                            <Info size={14} className='text-blue-400' />
                                            <div className="absolute group/edit invisible group-hover/item:visible group-hover/item:opacity-100 right-0 bottom-full mb-2 w-48 p-2 text-xs text-white bg-black rounded-lg opacity-0 transition-opacity duration-200 text-justify">
                                                Vadesi gelmiş ve ilgili ödeme gününe dahil olan siparişlerden kesilen komisyon tutarı.
                                            </div>
                                        </div>
                                    </div>
                                    <span className="text-black dark:text-white">{formatPrice(totalsData.total_commission)}</span>
                                </div>
                                <div className="">
                                    <div className="text-sm text-slate-500 dark:text-slate-300 flex items-center gap-1">
                                        Kargo Kesintisi
                                        <div className="relative group group/item">
                                            <Info size={14} className='text-blue-400' />
                                            <div className="absolute group/edit invisible group-hover/item:visible group-hover/item:opacity-100 left-0 bottom-full mb-2 w-48 p-2 text-xs text-white bg-black rounded-lg opacity-0 transition-opacity duration-200 text-justify">
                                                Vadesi gelmiş ve ilgili ödeme gününe dahil olan siparişlerden kesilen kargo tutarı.
                                            </div>
                                        </div>
                                    </div>
                                    <span className="text-black dark:text-white">{formatPrice(totalsData.total_shipment_price)}</span>
                                </div>
                                <div>
                                    <div className="text-sm text-slate-500 dark:text-slate-300 flex items-center gap-1">
                                        Diğer
                                        <div className="relative group group/item">
                                            <Info size={14} className='text-blue-400' />
                                            <div className="absolute group/edit invisible group-hover/item:visible group-hover/item:opacity-100 right-0 bottom-full mb-2 w-48 p-2 text-xs text-white bg-black rounded-lg opacity-0 transition-opacity duration-200 text-justify">
                                                Vadesi gelmiş ve ilgili ödeme gününe dahil olan siparişlerden kesilen diğer tutarlar.
                                            </div>
                                        </div>
                                    </div>
                                    <span className="text-black dark:text-white">{formatPrice(totalsData.total_other_price)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card col-span-12 md:col-span-4 mb-0">
                        <div className='card-body'>
                            <h5 className="font-normal text-16 mb-2">Erken Ödeme</h5>
                            <p className="text-sm text-slate-500 dark:text-slate-400 mb-6 w-[70%]">Erken ödeme sayesinde güncel bakiyenizin bir kısmını vadesini beklemeden alarak stoklarınızı doldurabilir ve karlılığınızı artırabilirsiniz.</p>
                            <button className="btn bg-blue-500 text-white rounded-lg">Erken Ödeme Talep Et</button>
                        </div>
                    </div>
                </div>
                <div className='flex items-start md:items-center gap-1 mb-2'>
                    <Info size={14} className='mb-0.5 mt-0.5 md:mt-0' />
                    <p className='text-slate-400'>Ödemeleriniz için TR00110011042140041210 IBAN numaralı hesabınız kullanılacaktır.</p>
                </div>
                <div className="card">
                    <div className="card-body flex flex-col gap-5">
                        <Tab.Container defaultActiveKey={path || ''}>
                            <Nav className="flex flex-wrap w-full text-sm font-medium text-center border-b border-slate-200 dark:border-zink-500 nav-tabs">
                                <Nav.Item eventKey="date" className="group">
                                    <Link
                                        to="/finance/payments/past/date"
                                        data-tab-toggle
                                        className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                                    >
                                        <span className="align-middle">Geçmiş Ödemeler</span>
                                    </Link>
                                </Nav.Item>
                                <Nav.Item eventKey="future" className="group">
                                    <Link
                                        to="/finance/payments/future"
                                        data-tab-toggle
                                        className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                                    >
                                        <span className="align-middle">Gelecek Ödemeler</span>
                                    </Link>
                                </Nav.Item>
                            </Nav>
                        </Tab.Container>
                        {
                            path === "future" ? (
                                <FuturePage />
                            ) : path === "date" || path === "order" ? (
                                <PastPage path={path} />
                            ) : null
                        }
                    </div>
                </div>
            </React.Fragment>
        </React.Fragment>
    );
};

export default Finance;
