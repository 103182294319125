import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Filtering from "./Filtering";
import BrandBasedCancellationReportsTable from "./BrandBasedCancellationReportsTable";
import ProductBasedCancellationReports from "./ProductBasedCancellationReports";
import CategoryBasedCancellationReportsTable from "./CategoryBasedCancellationReportsTable";
import moment from "moment";

const CanceledReports = () => {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const listParam = urlParams.get('list');
    const [filter, setFilter] = useState({
        startDate: moment().subtract(12, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
    });

    return (
        <React.Fragment>
            <Filtering setFilter={setFilter} listParam={listParam} />
            {listParam === 'product-based' ? (
                <ProductBasedCancellationReports filter={filter} />
            ) : listParam === 'brand-based' ? (
                <BrandBasedCancellationReportsTable filter={filter} />
            ) : listParam === 'category-based' ? (
                <CategoryBasedCancellationReportsTable filter={filter} />
            ) : (
                null
            )}
        </React.Fragment >
    )
}

export default CanceledReports;