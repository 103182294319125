import React, { useMemo } from "react";
import { ToastContainer } from "react-toastify";
import TableContainer from "Common/TableContainer";
import { Link } from "react-router-dom";
import NotFound from "../../Common/Components/NotFound/NotFound";
import moment from "moment";

const ProductReviewsDataByDate = () => {
    const loading = false;
    const list = [
        {
            productInfo: "Asus Rog Strix G17 G713PI-LL047 Amd Ryzen 9 7845HX 16GB 1tb SSD RTX4070 Freedos 17.3\" Wqhd",
            img: "https://productimages.hepsiburada.net/s/553/500/110000615474857.jpg",
            designation: "ALFZULA84000",
            evaluationDate: "6 Mayıs 2024 11:29",
            reviewStars: 4.5,
            reviewDetail: " Gerçenten harike bir ürün, teşekkürler.",
            status: "Yoruma destek süresi dolmuştur, mesaj yollanamaz",
            orderNumber: "2*********2",
            orderDate: "12.12.2021",
            deliveryDate: "15.12.2021",

        },
        {
            productInfo: "AMD RYZEN 5 5500 3.6 GHz 19MB AM4 İŞLEMCİ",
            img: "https://productimages.hepsiburada.net/s/205/500/110000179979391.jpg",
            designation: "ALFZULA84000",
            evaluationDate: "12.12.2021",
            reviewStars: 3.5,
            reviewDetail: "Ürünü beğendim, teşekkürler.",
            status: "Yoruma destek süresi dolmuştur, mesaj yollanamaz",
            orderNumber: "2*********2",
            orderDate: "18.12.2021",
            deliveryDate: "20.12.2021",
        },
        {
            productInfo: "Amd Ryzen 7 5700X 8 Core, 3,40-4.60GHZ, 36MB Cache, 65W, Am4 Soket, Box (Kutulu) (Grafik Kart Yok, Fan Yok)",
            img: "https://productimages.hepsiburada.net/s/555/550/110000617758908.jpg",
            designation: "ALFZULA84000",
            evaluationDate: "12.12.2021",
            reviewStars: 0.5,
            reviewDetail: "Ürün çok kötü, beğenmedim. Bir daha asla almayacağım.",
            status: "Yoruma destek süresi dolmuştur, mesaj yollanamaz",
            orderNumber: "2*********2",
            orderDate: "12.12.2021",
            deliveryDate: "15.12.2021",
        },
        {
            productInfo: "Intel Core İ3-12100 3.30GHZ 12MB 1700P 12.nesil Fanlı Box",
            img: "https://productimages.hepsiburada.net/s/261/550/110000245368308.jpg",
            designation: "ALFZULA84000",
            evaluationDate: "12.12.2021",
            reviewStars: 0,
            reviewDetail: "Resmen rezil, iade ediyorum. Bu kadar kötü bir ürün olamaz.",
            status: "Yoruma destek süresi dolmuştur, mesaj yollanamaz",
            orderNumber: "2*********2",
            orderDate: "24.12.2021",
            deliveryDate: "25.12.2021",
        },
    ];

    const columns = useMemo(
        () => [
            {
                header: "Teslimat Bilgileri",
                accessorKey: "deliveryInfo",
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <div>
                        <h6 className="mb-1 text-sm">{cell.row.original.orderNumber}</h6>
                        <p className="text-xs text-gray-400">Sipariş Tarihi: {cell.row.original.orderDate}</p>
                        <p className="text-xs text-gray-400">Teslimat Tarihi: {cell.row.original.deliveryDate}</p>
                    </div>
                ),
            },
            {
                header: "Ürün Bilgileri",
                accessorKey: "productInfo",
                enableColumnFilter: false,
                cell: (cell: any) => {
                    return (
                        <div className="flex items-center gap-2 w-full overflow-hidden">
                            <div className="h-16 flex items-center justify-center font-medium rounded shrink-0 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
                                <img
                                    src={cell.row.original.img}
                                    alt=""
                                    className="h-full rounded"
                                />
                            </div>
                            <div className="grow">
                                <h6 className="mb-1 ">
                                    <Link to="#!" className="name text-wrap line-clamp-2">
                                        {cell.getValue()}
                                    </Link>
                                </h6>
                                <p className="text-slate-500 dark:text-zink-200">
                                    Ürün Kodu: {cell.row.original.designation}
                                </p>
                            </div>
                        </div>
                    );
                },
            },
            {
                header: 'Değerlendirma Tarihi',
                accessorKey: 'evaluationDate',
                enableColumnFilter: false,
            },
            {
                header: 'Değerlendirme Detayı',
                accessorKey: 'reviewDetail',
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <React.Fragment>
                        {Array.from({ length: Math.floor(cell.row.original.reviewStars) }).map((_, index) => (
                            <i key={index} className="ri-star-fill text-yellow-500"></i>
                        ))}
                        {Math.floor(cell.row.original.reviewStars) < cell.row.original.reviewStars && (
                            <i className="ri-star-half-fill text-yellow-500"></i>
                        )}
                        {Array.from({ length: 5 - Math.ceil(cell.row.original.reviewStars) }).map((_, index) => (
                            <i key={index} className="ri-star-fill text-gray-200"></i>
                        ))}
                        <p className="text-wrap line-clamp-2">{cell.getValue()}</p>
                    </React.Fragment>
                ),
            },
            {
                header: 'Durum',
                accessorKey: 'status',
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <p className="text-wrap line-clamp-2">
                        {cell.getValue()}
                    </p>
                ),
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} limit={1} />
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
                <div className="xl:col-span-12">

                    <div className="card relative">
                        <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                            <h5 className="font-normal mb-4">Satıcı Değerlendirmeleri Özeti</h5>
                            <div className="col-span-12 md:col-span-6 lg:col-span-3">
                                <div className="flex flex-col md:flex-row gap-4">
                                    <div className="flex item-center justify-between border border-gray-900 dark:border-gray-500 rounded-md w-full p-4 min-h-[60px] gap-3">
                                        <div className="flex flex-col items-center justify-center size-[105px] rounded-md bg-green-400/60 shrink-0">
                                            <h6 className="font-light text-white text-xs mt-2">Ortalama Puan</h6>
                                            <span className="font-bold text-white text-base">5.0</span>
                                        </div>
                                        <div className="flex-1">
                                            {[
                                                { stars: 5, count: 20 },
                                                { stars: 4, count: 0 },
                                                { stars: 3, count: 0 },
                                                { stars: 2, count: 0 },
                                                { stars: 1, count: 0 }
                                            ].map((item, index) => (
                                                <div key={index} className="flex items-center gap-2">
                                                    <div className="w-4">{item.stars}</div>
                                                    <i className="ri-star-fill text-yellow-500"></i>
                                                    <span className="bg-slate-100 h-1 flex-1 rounded" />
                                                    <div className="overflow-hidden text-ellipsis w-6 text-start">{item.count}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="flex item-center justify-between border border-gray-900 dark:border-gray-500 rounded-md w-full p-4 min-h-[60px] gap-3">
                                        <div className="flex-1">
                                            <h6 className="font-normal mb-3">Olumsuz Etkiler</h6>
                                            {[
                                                { status: 'Özensiz Paketleme', count: 0 },
                                                { status: 'Eksik veya Hatalı Ürün', count: 0 },
                                                { status: 'Fatura Eksik', count: 0 },
                                            ].map((item, index) => (
                                                <div key={index} className="flex items-center gap-2">
                                                    <div className="w-36 text-xs font-light text-slate-400">{item.status}</div>
                                                    <span className="bg-slate-100 h-1 flex-1 rounded" />
                                                    <div className="overflow-hidden text-ellipsis w-6 text-start">{item.count}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="card relative">
                        {loading && (
                            <div className="flex items-center justify-center h-full absolute inset-0">
                                <div className="flex flex-col items-center">
                                    <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                                </div>
                            </div>
                        )}
                        <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                            <React.Fragment>
                                <div className="md:flex justify-between items-center">
                                    <div className="mb-2">
                                        <h6 className="text-xl font-normal">Ürün Değerlendirmelerim</h6>
                                    </div>
                                    <div className="mb-2 flex sm:items-center items-start flex-col sm:flex-row">
                                        <div className="sm:text-end text-start mb-1 sm:mr-4 mr-0 sm:ml-auto">
                                            <p className="text-sm">Filtreleme Sonuçları: Toplam {list.length} yorum bilgisi</p>
                                            <span className="text-sm text-gray-400">Son Güncellenme: {moment().format('DD MMMM YYYY HH:mm')}</span>
                                        </div>
                                        <div>
                                            <div className="relative flex-1">
                                            </div>
                                            <div className='self-center place-self-end'>
                                                <button
                                                    type="button"
                                                    className="btn border-slate-200 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 mb-2"
                                                >
                                                    Excel ile indir{" "}
                                                    <i className="align-baseline ltr:pr-1 rtl:pl-1 ri-download-2-line"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                            {list.length > 0 ? (
                                <TableContainer
                                    isPagination={true}
                                    columns={columns}
                                    data={list}
                                    customPageSize={10}
                                    divclassName="-mx-5 -mb-5 overflow-x-auto"
                                    tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                                    theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                                    thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                                    tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                                    PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                                />
                            ) : (
                                <NotFound />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};

export default ProductReviewsDataByDate;
