import React from "react";

// Image
import modern from "assets/images/auth-modern.png";


const AuthInfo = () => {
  return (
    <React.Fragment>
      <div className="relative z-10 hidden  lg:flex items-center justify-center min-h-screen px-10 text-center grow py-14">
        <div>
          {/* <Link to="/">
            <img src={logoLight} alt="" className="block mx-auto mb-14 h-7" />
          </Link> */}
          <img
            src={modern}
            alt=""
            className="w-full block object-cover mx-auto shadow-lg md:max-w-md rounded-xl shadow-custom-800"
          />
          <div className="mt-10 text-center">
            <h3 className="mt-4 mb-3 capitalize text-custom-50">
              İşletmenizin marka kimliğini oluşturmak için araçlar
            </h3>
            <p className="max-w-2xl text-custom-300 text-16">
              İşletmenizi şu alanlarda güçlendirmek için tasarlanmış çok yönlü
              markalaşma araçlarınızın potansiyelini ortaya çıkarın ayırt edici
              ve etkili bir markayı şekillendirin. İşletmenizin imajını
              yükseltin ve iz bırakın. Kapsamlı markalaşma çözümlerimizle kalıcı
              bir izlenim bırakın.
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AuthInfo;
