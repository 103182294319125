import React, { useState, FormEvent, useEffect, useRef } from "react";
import { Nav } from "../../Common/Components/Tab/Nav";
import Tab from "../../Common/Components/Tab/Tab";
import { CalendarDays, Info } from "lucide-react";
import Flatpickr from 'react-flatpickr';
import { Link, useLocation } from "react-router-dom";
import AllCancellations from "./AllCancellations";
import CustomerCanceled from "./CustomerCanceled";
import Sellercanceled from "./Sellercanceled";
import ACommerceCanceled from "./ACommerceCanceled";
import moment from "moment";

export interface ProductProps {
  handleDelete: () => void;
  deleteToggle: () => void;
  deleteModal: boolean;
}
export interface column {
  header: string;
  accessorKey: string;
  enableColumnFilter: boolean;
  enableSorting: boolean;
}
const SalesTransactions = () => {
  const { pathname } = useLocation();
  const formRef = useRef<HTMLFormElement>(null);
  const [filter, setFilter] = useState({
    orderNumber: "",
    cargoCode: "",
    cancelStartDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    cancelEndDate: moment().format('YYYY-MM-DD'),
  });

  const handleFilter = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const data = {
      orderNumber: formData.get('orderNumber') as string,
      cargoCode: formData.get('cargoCode') as string,
      cancelStartDate: moment(formData.get('cancelStartDate') as string, 'DD MMM, YYYY').format('YYYY-MM-DD'),
      cancelEndDate: moment(formData.get('cancelEndDate') as string, 'DD MMM, YYYY').format('YYYY-MM-DD'),
    };

    setFilter(data);
  }

  const handleFilterReset = () => {
    if (formRef.current && formRef.current.orderNumber) {
      formRef.current.orderNumber.value = "";
    }
    if (formRef.current && formRef.current.cargoCode) {
      formRef.current.cargoCode.value = "";
    }
    formRef.current?.cancelStartDate._flatpickr.setDate(moment().subtract(30, 'days').format('DD MMM, YYYY'));
    formRef.current?.cancelEndDate._flatpickr.setDate(moment().format('DD MMM, YYYY'));
  };

  useEffect(() => {
    handleFilterReset();
  }, [pathname]);

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body flex flex-col gap-5">
          <Tab.Container defaultActiveKey={pathname}>
            <Nav className="flex flex-wrap w-full text-sm font-medium text-center border-b border-slate-200 dark:border-zink-500 nav-tabs">
              <Nav.Item eventKey="/canceled-sales/all-canceled-sales" className="group">
                <Link
                  to="/canceled-sales/all-canceled-sales"
                  data-tab-toggle
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  <span className="align-middle">Tüm İptaller</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="/canceled-sales/customer-canceled" className="group">
                <Link
                  to="/canceled-sales/customer-canceled"
                  data-tab-toggle
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  <span className="align-middle">Müşterinin İptal Ettiği</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="/canceled-sales/i-canceled" className="group">
                <Link
                  to="/canceled-sales/i-canceled"
                  data-tab-toggle
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  <span className="align-middle">Benim İptal Ettiğim</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="/canceled-sales/a-commerce-canceled" className="group">
                <Link
                  to="/canceled-sales/a-commerce-canceled"
                  data-tab-toggle
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  <span className="align-middle">a-Commerce’in İptal Ettiği</span>
                </Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
          <form ref={formRef} onSubmit={handleFilter}>
            <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 xl:grid-cols-4 flex-1">
              <div className="mb-4">
                <label
                  htmlFor="order-number"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Sipariş Numarası
                </label>
                <input
                  type="text"
                  id="order-number"
                  name="orderNumber"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Sipariş numarasını giriniz"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="cargo-code"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Kargo Kodu
                </label>
                <input
                  type="text"
                  id="cargo-code"
                  name="cargoCode"
                  disabled
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Kargo kodunu giriniz"
                />
              </div>
              <div className="relative mb-4">
                <label htmlFor="cancel-start-date" className="inline-flex items-center gap-1 mb-2 text-base font-medium">
                  İptal Başlangıç Tarihi
                  <div className="relative group group/item">
                    <Info size={14} className='text-blue-400' />
                    <div className="absolute group/edit invisible group-hover/item:visible group-hover/item:opacity-100 left-0 bottom-full mb-2 w-48 p-2 text-xs text-white bg-black rounded-lg opacity-0 transition-opacity duration-200 text-justify">
                      Seçeceğiniz başlangıç ve bitiş tarihinin aralığı 30 günü geçmemelidir.
                    </div>
                  </div>
                </label>
                <Flatpickr
                  id="cancel-start-date"
                  name="cancelStartDate"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  options={{
                    dateFormat: "d M, Y",
                    defaultDate: moment().subtract(30, 'days').toDate(),
                    enableTime: false,
                    locale: {
                      weekdays: {
                        longhand: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
                        shorthand: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt']
                      },
                      months: {
                        longhand: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
                        shorthand: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara']
                      },
                    }
                  }}
                  placeholder='İptal başlangıç tarihini seçiniz'
                  lang='tr'
                />
                <CalendarDays className="absolute size-4 right-3 bottom-3 text-slate-500 dark:text-zink-200" />
              </div>
              <div className="relative mb-4">
                <label htmlFor="cancel-end-date" className="inline-flex items-center gap-1 mb-2 text-base font-medium">
                  İptal Bitiş Tarihi
                  <div className="relative group group/item">
                    <Info size={14} className='text-blue-400' />
                    <div className="absolute group/edit invisible group-hover/item:visible group-hover/item:opacity-100 left-0 bottom-full mb-2 w-48 p-2 text-xs text-white bg-black rounded-lg opacity-0 transition-opacity duration-200 text-justify">
                      Seçeceğiniz başlangıç ve bitiş tarihinin aralığı 30 günü geçmemelidir.
                    </div>
                  </div>
                </label>
                <Flatpickr
                  id="cancel-end-date"
                  name="cancelEndDate"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  options={{
                    dateFormat: "d M, Y",
                    maxDate: "today",
                    defaultDate: "today",
                    enableTime: false,
                    locale: {
                      weekdays: {
                        longhand: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
                        shorthand: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt']
                      },
                      months: {
                        longhand: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
                        shorthand: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara']
                      },
                    },
                  }}
                  placeholder='İptal bitiş tarihini seçiniz'
                />
                <CalendarDays className="absolute size-4 right-3 bottom-3 text-slate-500 dark:text-zink-200" />
              </div>
            </div>
            <div className="flex flex-1 items-end justify-end gap-2">
              <button
                type="button"
                onClick={handleFilterReset}
                className="text-slate-500 btn bg-slate-200 border-slate-200 hover:text-slate-600 hover:bg-slate-300 hover:border-slate-300 focus:text-slate-600 focus:bg-slate-300 focus:border-slate-300 focus:ring focus:ring-slate-100 active:text-slate-600 active:bg-slate-300 active:border-slate-300 active:ring active:ring-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 dark:border-zink-600 dark:hover:border-zink-500 dark:text-zink-200 dark:ring-zink-400/50 md:w-[200px] w-full"
              >
                <span className="align-middle">Temizle</span>
              </button>
              <button
                type="submit"
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 md:w-[200px] w-full"
              >
                Filtrele
              </button>
            </div>
          </form>
        </div>
      </div>

      {
        pathname === "/canceled-sales/all-canceled-sales" ? (
          <AllCancellations filter={filter} />
        ) : pathname === "/canceled-sales/customer-canceled" ? (
          <CustomerCanceled filter={filter} />
        ) : pathname === "/canceled-sales/i-canceled" ? (
          <Sellercanceled filter={filter} />
        ) : pathname === "/canceled-sales/a-commerce-canceled" ? (
          <ACommerceCanceled filter={filter} />
        ) : null
      }
    </React.Fragment >
  );
};

export default SalesTransactions;
