import TableContainer from "Common/TableContainer";

const reactTableData = [
  {
    adı: "A-Commerce Partner Sözleşmesi",
    durum: "Yeni",
    yayınlamaTarihi: "24mayıs 2024 22:30",
    sonislemTarihi: "24 mayıs 2024 23:31",
  },
  {
    adı: "A-Commerce Partner Sözleşmesi",
    durum: "Bilgilendirildi",
    yayınlamaTarihi: "24mayıs 2024 22:30",
    sonislemTarihi: "24 mayıs 2024 23:31",
  },
  {
    adı: "A-Commerce Partner Sözleşmesi",
    durum: "Reddedilen",
    yayınlamaTarihi: "24mayıs 2024 22:30",
    sonislemTarihi: "24 mayıs 2024 23:31",
  },
  {
    adı: "A-Commerce Partner Sözleşmesi",
    durum: "Yeni",
    yayınlamaTarihi: "24mayıs 2024 22:30",
    sonislemTarihi: "24 mayıs 2024 23:31",
  },
  {
    adı: "A-Commerce Partner Sözleşmesi",
    durum: "Yeni",
    yayınlamaTarihi: "24mayıs 2024 22:30",
    sonislemTarihi: "24 mayıs 2024 23:31",
  },
];

const Documents = () => {
  const columns = [
    {
      header: "Sözleşme Adı",
      accessorKey: "adı",
      enableColumnFilter: false,
      enableSorting: true,
    },
    {
      header: "Durum",
      accessorKey: "durum",
      enableColumnFilter: false,
      //   enableSorting: true,
      cell: (cell: any) => {
        if (cell.getValue() === "Yeni") {
          return (
            <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:border-transparent">
              {" "}
              {cell.getValue()}
            </span>
          );
        } else if (cell.getValue() === "Bilgilendirildi") {
          return (
            <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-custom-100 border-transparent text-custom-500 dark:bg-custom-500/20 dark:border-transparent">
              {cell.getValue()}
            </span>
          );
        }
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">
            {cell.getValue()}
          </span>
        );
      },
    },
    {
      header: "Yayınlama Tarihi",
      accessorKey: "yayınlamaTarihi",
      enableColumnFilter: false,
      enableSorting: true,
    },
    {
      header: "Son işlem Tarihi",
      accessorKey: "sonislemTarihi",
      enableColumnFilter: false,
      enableSorting: true,
    },

    {
      header: "Aksiyonlar",
      enableColumnFilter: false,
      enableSorting: true,
      cell: () => (
        <button
          type="button"
          className="flex gap-1 items-center bg-white text-custom-500 btn hover:text-custom-500 hover:bg-custom-100 focus:text-custom-500 focus:bg-custom-100 active:text-custom-500 active:bg-custom-100 dark:bg-zink-700 dark:hover:bg-custom-500/10 dark:focus:bg-custom-500/10 dark:active:bg-custom-500/10"
        >
          <span>Görüntüle</span>
        </button>
      ),
    },
  ];
  return (
    <>
      <div className="flex items-center gap-4">
        <button
          type="button"
          className="rounded-full text-custom-500 btn bg-custom-100 hover:text-white hover:bg-custom-600 focus:text-white focus:bg-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:ring active:ring-custom-100 dark:bg-custom-500/20 dark:text-custom-500 dark:hover:bg-custom-500 dark:hover:text-white dark:focus:bg-custom-500 dark:focus:text-white dark:active:bg-custom-500 dark:active:text-white dark:ring-custom-400/20"
        >
          Tümü
        </button>
        <button
          type="button"
          className="rounded-full text-custom-500 btn bg-custom-100 hover:text-white hover:bg-custom-600 focus:text-white focus:bg-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:ring active:ring-custom-100 dark:bg-custom-500/20 dark:text-custom-500 dark:hover:bg-custom-500 dark:hover:text-white dark:focus:bg-custom-500 dark:focus:text-white dark:active:bg-custom-500 dark:active:text-white dark:ring-custom-400/20"
        >
          Rededilenler
        </button>
        <button
          type="button"
          className="rounded-full text-custom-500 btn bg-custom-100 hover:text-white hover:bg-custom-600 focus:text-white focus:bg-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:ring active:ring-custom-100 dark:bg-custom-500/20 dark:text-custom-500 dark:hover:bg-custom-500 dark:hover:text-white dark:focus:bg-custom-500 dark:focus:text-white dark:active:bg-custom-500 dark:active:text-white dark:ring-custom-400/20"
        >
          Onaylananlar
        </button>
      </div>
      <div className="card-body">
        <TableContainer
          isPagination={true}
          columns={columns || []}
          data={reactTableData || []}
          customPageSize={10}
          divclassName="-mx-5 -mb-5 overflow-x-auto"
          tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
          theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
          thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
          tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
          PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
        />
      </div>
    </>
  );
};

export default Documents;
