import React, { useMemo, useCallback, useState, useEffect } from "react";
import { useAppSelector } from "../../redux/hooks";
import { sendRequestWithCache } from 'helpers/request';
import { ToastContainer } from "react-toastify";
import TableContainer from "Common/TableContainer";
import { Link } from "react-router-dom";
import NotFound from "../../Common/Components/NotFound/NotFound";
import Modal from "Common/Components/Modal";
import userDummy from "assets/images/users/user-dummy-img.jpg";
import { X } from 'lucide-react';
import moment from "moment";
import "flatpickr/dist/flatpickr.css";

interface DataTableType {
    list: {
        
    }[] | Object[];
};

interface FilterType {
    orderNumber: string;
    averageProductRating: string;
    productCode: string;
    productRating: string[];
};


const ProductReviewsDataByProduct = ({ filter }: { filter: FilterType }) => {
    const [loading, setLoading] = useState(true);
    const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
    const [list, setList] = useState<DataTableType[]>([]);
    const [data, setData] = useState<any>([]);
    const [show, setShow] = useState<boolean>(false);

    const fetchProductReviews = useCallback(async () => {
        setLoading(true);
        const formData = new FormData();
        filter.orderNumber && formData.append('order_number', filter.orderNumber);
        filter.averageProductRating && formData.append('average_product_rating', filter.averageProductRating);
        filter.productCode && formData.append('product_code', filter.productCode);
        filter.productRating && filter.productRating.map(el => formData.append('points[]', el));

        try {
            const response = await sendRequestWithCache("POST", "/product/review/search/byProductId", formData, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });

            setList(
                response.map((item: any) => ({
                    productInfo: "Asus Rog Strix G17 G713PI-LL047 Amd Ryzen 9 7845HX 16GB 1tb SSD RTX4070 Freedos 17.3\" Wqhd",
                    img: "https://productimages.hepsiburada.net/s/553/500/110000615474857.jpg",
                    designation: "ALFZULA84000",
                    averageProductRating: 4.5,
                    reviewStars: 4.5,
                    numberOfVotes: 5,
                    numberOfComments: 5,
                }))
            );

        } catch (error) {
            return error
        } finally {
            setLoading(false);
        }
    }, [bearer_token, filter]);

    useEffect(() => {
        fetchProductReviews();
    }, [fetchProductReviews, filter]);

    useEffect(() => {
        setData([
            {
                id: "1",
                img: userDummy,
                customerName: "Paula Keenan",
                date: "05 May 2024",
                rating: "5",
                review: "I bought this product and I am very happy with it. I recommend it to everyone.",
            },
            {
                id: "2",
                img: userDummy,
                customerName: "Paula Keenan",
                date: "05 May 2024 14:00",
                rating: "4",
                review: "I bought this product and I am very happy with it. I recommend it to everyone.",
            },
            {
                id: "3",
                img: userDummy,
                customerName: "Paula Keenan",
                date: "05 May 2024 14:36",
                rating: "3",
                review: "I bought this product and I am very happy with it. I recommend it to everyone.",
            },
            {
                id: "4",
                img: userDummy,
                customerName: "Paula Keenan",
                date: "05 May 2024",
                rating: "2",
                review: "I bought this product and I am very happy with it. I recommend it to everyone.",
            },
            {
                id: "1",
                img: userDummy,
                customerName: "Paula Keenan",
                date: "05 May 2024 15:00",
                rating: "5",
                review: "I bought this product and I am very happy with it. I recommend it to everyone.",
            },
            {
                id: "2",
                img: userDummy,
                customerName: "Paula Keenan",
                date: "05 May 2024 16:00",
                rating: "4",
                review: "I bought this product and I am very happy with it. I recommend it to everyone.",
            },
            {
                id: "3",
                img: userDummy,
                customerName: "Paula Keenan",
                date: "05 May 2024 17:50",
                rating: "3",
                review: "I bought this product and I am very happy with it. I recommend it to everyone.",
            },
            {
                id: "4",
                img: userDummy,
                customerName: "Paula Keenan",
                date: "05 May 2024 18:44",
                rating: "2",
                review: "I bought this product and I am very happy with it. I recommend it to everyone.",
            },
            {
                id: "1",
                img: userDummy,
                customerName: "Paula Keenan",
                date: "05 May 2024 19:00",
                rating: "5",
                review: "I bought this product and I am very happy with it. I recommend it to everyone.",
            },
            {
                id: "2",
                img: userDummy,
                customerName: "Paula Keenan",
                date: "05 May 2024 13:26",
                rating: "4",
                review: "I bought this product and I am very happy with it. I recommend it to everyone.",
            },
            {
                id: "3",
                img: userDummy,
                customerName: "Paula Keenan",
                date: "05 May 2024 12:00",
                rating: "3",
                review: "I bought this product and I am very happy with it. I recommend it to everyone.",
            },
            {
                id: "4",
                img: userDummy,
                customerName: "Paula Keenan",
                date: "05 May 2024 11:00",
                rating: "2",
                review: "I bought this product and I am very happy with it. I recommend it to everyone.",
            },
        ]);
    }, []);

    const onHide = () => {
        setShow(false);
    }

    const StareRating = ({ item }: { item: number }) => {
        const stars = Array(5).fill(<i className="ri-star-line"></i>).map((star, index) =>
            index < item ? <i className="ri-star-fill"></i> : star
        );

        return (
            <div className="flex items-center gap-2 mt-2 mb-2 text-yellow-500">
                {stars}
            </div>
        );
    };

    const StarRatingButtons = ({ item, rating }: { item: number, rating: number }) => {
        const stars = Array(5).fill(<i className="ri-star-line"></i>).map((star, index) =>
            index < item ? <i className="ri-star-fill"></i> : star
        );

        return (
            <button className="flex px-2.5 items-center gap-1 text-yellow-500 btn leading-none rounded-xl bg-white dark:text-white hover:bg-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 border border-black dark:border-white">
                {stars} <span className="text-black dark:text-white">{rating}</span>
            </button>
        );
    };

    const columns = useMemo(
        () => [
            {
                header: "Ürün Bilgileri",
                accessorKey: "productInfo",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => {
                    return (
                        <div className="flex items-center gap-2 w-full overflow-hidden">
                            <div className="h-16 flex items-center justify-center font-medium rounded shrink-0 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
                                <img
                                    src={cell.row.original.img}
                                    alt=""
                                    className="h-full rounded"
                                />
                            </div>
                            <div className="grow">
                                <h6 className="mb-1 ">
                                    <Link to="#!" className="name text-wrap line-clamp-2">
                                        {cell.getValue()}
                                    </Link>
                                </h6>
                                <p className="text-slate-500 dark:text-zink-200">
                                    Ürün Kodu: {cell.row.original.designation}
                                </p>
                            </div>
                        </div>
                    );
                },
            },
            {
                header: 'Ortalama Ürün Puanı',
                accessorKey: 'averageProductRating',
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <p className={`${cell.getValue() < 3 ? 'text-red-400' : cell.getValue() < 4 ? 'text-yellow-400' : 'text-green-400'} text-center`}>
                        {cell.getValue()}
                    </p>
                ),
            },
            {
                header: 'Oy Adedi',
                accessorKey: 'numberOfVotes',
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <p className="text-center">{cell.getValue()}</p>
                ),
            },
            {
                header: 'Yorum Sayısı',
                accessorKey: 'numberOfComments',
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <p className="text-center">{cell.getValue()}</p>
                ),
            },
            {
                header: <div className="text-center w-full">Durum</div>,
                accessorKey: 'status',
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <div className="flex justify-center">
                        <button onClick={() => setShow(true)} className="btn leading-none rounded-xl bg-white text-black dark:text-white hover:bg-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 border border-black dark:border-white">
                            Değerlendirme Detayları
                        </button>
                    </div >
                ),
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} limit={1} />
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
                <div className="xl:col-span-12">
                    <div className="card relative">
                        {loading && (
                            <div className="flex items-center justify-center h-full absolute inset-0">
                                <div className="flex flex-col items-center">
                                    <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                                </div>
                            </div>
                        )}
                        <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                            <React.Fragment>
                                <div className="md:flex justify-between items-center">
                                    <div className="mb-2">
                                        <h6 className="text-xl font-normal">Ürün Değerlendirmelerim</h6>
                                    </div>
                                    <div className="mb-2 flex sm:items-center items-start flex-col sm:flex-row">
                                        <div className="sm:text-end text-start mb-1 sm:mr-4 mr-0 sm:ml-auto">
                                            <p className="text-sm">Filtreleme Sonuçları: Toplam {list.length} yorum bilgisi</p>
                                            <span className="text-sm text-gray-400">Son Güncellenme: {moment().format('DD MMMM YYYY HH:mm')}</span>
                                        </div>
                                        <div>
                                            <div className="relative flex-1">
                                            </div>
                                            <div className='self-center place-self-end'>
                                                <button
                                                    type="button"
                                                    className="btn border-slate-200 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 mb-2"
                                                >
                                                    Excel ile indir{" "}
                                                    <i className="align-baseline ltr:pr-1 rtl:pl-1 ri-download-2-line"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                            {list.length > 0 ? (
                                <TableContainer
                                    isPagination={true}
                                    columns={columns}
                                    data={list}
                                    customPageSize={10}
                                    divclassName="-mx-5 -mb-5 overflow-x-auto"
                                    tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                                    theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                                    thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                                    tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                                    PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                                />
                            ) : (
                                <NotFound />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={onHide} modal-center="true" className="fixed transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4" dialogClassName='bg-white shadow rounded-md dark:bg-zink-600'>
                <div className="border-b-2 px-6 py-4 flex justify-between items-center">
                    <h6 className="text-[14px] font-[600]">Değerlendirme Detayları</h6>
                    <button data-modal-close="deleteModal" className="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"><X onClick={() => setShow(false)} className="size-5" /></button>
                </div>
                <Modal.Body className="max-h-[calc(theme('height.screen')_-_50px)] min-h-[300px] h-[700px] w-[90vw] max-w-[1200px] overflow-y-auto px-6 py-2">
                    <div className="grid grid-cols-1 gap-10 xl:grid-cols-12">
                        <div className="xl:col-span-4 m-auto w-full h-full">
                            <div className="sticky top-20 border border-dashed rounded-md border-slate-200 dark:border-zink-500">
                                <div className="flex justify-center items-center gap-4 p-5">
                                    <div className="md:max-h-32 md:max-w-32 md:min-h-32 md:min-w-32 p-2">
                                        <img
                                            src='https://www.imgacademy.com/sites/default/files/img-academy-organization-schema.jpg'
                                            alt=""
                                            className="h-full w-full object-contain rounded"
                                        />
                                    </div>
                                    <div className="">
                                        <h6 className="mb-1 text-wrap line-clamp-2">İphone 15 Pro Max, Mavi Titanyum</h6>
                                        <p className="text-slate-500 dark:text-zink-200 line-clamp-1">Akıllı Telefon - Ürün Kodu:ALFZULA84000</p>
                                    </div>
                                </div>
                                <div className="p-5">
                                    <div className="text-center">
                                        <h5 className="mb-2 text-16">Ortalama Ürün Puanı</h5>
                                        <span className="px-3.5 py-1.5 inline-flex gap-3 text-xs font-medium rounded-full border bg-slate-100 border-slate-100 text-slate-500 dark:bg-zink-600 dark:text-zink-200 dark:border-zink-600">
                                            <span className="flex items-center gap-2 text-yellow-500">
                                                <i className="ri-star-fill"></i>
                                                <i className="ri-star-fill"></i>
                                                <i className="ri-star-fill"></i>
                                                <i className="ri-star-fill"></i>
                                                <i className="ri-star-half-line"></i>
                                            </span>
                                            4.7
                                        </span>
                                        <p className="mt-2 text-sm text-slate-500 dark:text-zink-200">
                                            1,210 Değerlendirme
                                        </p>
                                    </div>
                                    <div className="flex flex-col gap-3 mt-4">
                                        <div className="flex items-center gap-3">
                                            <div className="text-sm shrink-0">
                                                5 <i className="ml-1 text-yellow-500 align-middle ri-star-fill"></i>
                                            </div>
                                            <div className="w-full h-1.5 rounded-full bg-slate-200 dark:bg-zink-600">
                                                <div className="h-1.5 rounded-full bg-green-500" style={{ width: "79%" }}></div>
                                            </div>
                                            <div className="text-sm text-right w-9 shrink-0">
                                                1,210
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-3">
                                            <div className="text-sm shrink-0">
                                                4 <i className="ml-1 text-yellow-500 align-middle ri-star-fill"></i>
                                            </div>
                                            <div className="w-full h-1.5 rounded-full bg-slate-200 dark:bg-zink-600">
                                                <div className="h-1.5 rounded-full bg-sky-500" style={{ width: "66%" }}></div>
                                            </div>
                                            <div className="text-sm text-right w-9 shrink-0">
                                                1,174
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-3">
                                            <div className="text-sm shrink-0">
                                                3 <i className="ml-1 text-yellow-500 align-middle ri-star-fill"></i>
                                            </div>
                                            <div className="w-full h-1.5 rounded-full bg-slate-200 dark:bg-zink-600">
                                                <div className="h-1.5 rounded-full bg-purple-500" style={{ width: "45%" }}></div>
                                            </div>
                                            <div className="text-sm text-right w-9 shrink-0">
                                                762
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-3">
                                            <div className="text-sm shrink-0">
                                                2 <i className="ml-1 text-yellow-500 align-middle ri-star-fill"></i>
                                            </div>
                                            <div className="w-full h-1.5 rounded-full bg-slate-200 dark:bg-zink-600">
                                                <div className="h-1.5 rounded-full bg-yellow-500" style={{ width: "22%" }}></div>
                                            </div>
                                            <div className="text-sm text-right w-9 shrink-0">
                                                274
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-3">
                                            <div className="text-sm shrink-0">
                                                1 <i className="ml-1 text-yellow-500 align-middle ri-star-fill"></i>
                                            </div>
                                            <div className="w-full h-1.5 rounded-full bg-slate-200 dark:bg-zink-600">
                                                <div className="h-1.5 rounded-full bg-red-500" style={{ width: "5%" }}></div>
                                            </div>
                                            <div className="text-sm text-right w-9 shrink-0">
                                                32
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-8 h-auto pb-8">
                            <div className="py-3 mt-3 border-b border-slate-200 dark:border-zink-500">
                                <div className="flex items-center justify-between gap-2 flex-nowrap overflow-x-auto w-full pb-3">
                                    <button className="btn text-nowrap leading-none rounded-xl bg-white text-black dark:text-white hover:bg-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 border border-black dark:border-white">
                                        Tümü <i className="ri-arrow-right-s-line"></i> 60
                                    </button>
                                    {StarRatingButtons({ item: 5, rating: 30 })}
                                    {StarRatingButtons({ item: 4, rating: 17 })}
                                    {StarRatingButtons({ item: 3, rating: 3 })}
                                    {StarRatingButtons({ item: 2, rating: 8 })}
                                    {StarRatingButtons({ item: 1, rating: 11 })}
                                </div>
                            </div>
                            {(data || []).map((item: any, key: number) => (<div className={key === 0 ? "mt-3" : "pt-3 mt-3 border-t border-slate-200 dark:border-zink-500"} key={key}>
                                <div className="">
                                    <p className="text-sm text-slate-500 dark:text-zink-200"><span className="text-black dark:text-white font-[500]">{item.date}</span> tarihinde yazıldı</p>
                                </div>
                                <StareRating item={item.rating} />
                                <p className="text-slate-500 dark:text-zink-200">{item.review}</p>
                            </div>))}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment >
    );
};

export default ProductReviewsDataByProduct;
