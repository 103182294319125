import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "./assets/scss/themes.scss";
import RouteIndex from "Routes/Index";

import fakeBackend from "./helpers/AuthType/fakeBackend";
import { store } from "./redux/store";

fakeBackend();

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <RouteIndex />
        <ToastContainer />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
