import { useCallback, useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useAppSelector } from "../../../redux/hooks";
import { sendRequestWithCache } from 'helpers/request';

const InvoicePerformance = () => {
    const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
    const [loading, setLoading] = useState(true);
    const [invoicePerformance, setInvoicePerformance] = useState<any>();

    const fetchInvoicePerformance = useCallback(async () => {
        try {
            const response = await sendRequestWithCache("GET", "/user/dashboard/invoice-performance", null, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });
            setInvoicePerformance(response);
        } catch (error) {
            return error
        } finally {
            setLoading(false);
        }
    }, [bearer_token]);

    useEffect(() => {
        fetchInvoicePerformance();
    }, [fetchInvoicePerformance]);

    const currentTime = new Date().toLocaleString('tr-TR', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' });

    return (
        <div className="col-span-12 card relative">
            {loading && (
                <div className="flex items-center justify-center h-full absolute inset-0">
                    <div className="flex flex-col items-center">
                        <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                    </div>
                </div>
            )}
            <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                <h5 className="font-normal">Fatura Performansı</h5>
                <div className="w-full rounded-full h-2.54 mt-2">
                    <div className="bg-custom-500 h-2.54 rounded-full"></div>
                    <div className="col-span-12 md:col-span-6 lg:col-span-3">
                        <div className="flex items-center justify-between w-full gap-4">
                            <div className="flex items-center justify-center size-16 text-green-500 rounded-md bg-green-50 shrink-0 dark:bg-green-500/10">
                                %<CountUp end={invoicePerformance?.performance} className="counter-value" />
                            </div>
                            <div className="flex item-center justify-between border border-gray-900 dark:border-gray-500 rounded w-full p-2 min-h-[60px] gap-3">
                                <div className="flex-1">
                                    <h6 className="text-14 font-normal text-slate-500 dark:text-zink-200">Geçiken</h6>
                                    <span className="text-14 text-blue-500 underline"><CountUp end={invoicePerformance?.delayed_invoices} className="counter-value" /> Fatura</span>
                                </div>
                                <div className="bg-gray-900 w-0.5" />
                                <div className="flex-1">
                                    <h6 className="text-14 font-normal text-slate-500 dark:text-zink-200">Kesilmesi Gereken</h6>
                                    <span className="text-14 underline"><CountUp end={invoicePerformance?.invoices_to_be_issued} className="counter-value" /> Fatura</span>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-between mt-3">
                            <p className="text-xs text-slate-500 dark:text-zink-200">Son Güncellenme: {currentTime}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvoicePerformance;