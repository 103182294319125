import React from "react";
import Modal from "../../Common/Components/Modal";
import Select from "react-select";

// Icons
import { X } from 'lucide-react';

// Image
import Alert from "Common/Components/Alert";

interface props {
    show: boolean;
    onHide: () => void;
}

const DetailModal: React.FC<props> = ({ show, onHide }) => {
    return (
        <React.Fragment>
            <Modal show={show} onHide={onHide} id="deleteModal" modal-center="true" className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4" dialogClassName='w-screen md:w-[860px] bg-white shadow rounded-md dark:bg-zink-600'>
                <div className="border-b-2 px-8 py-4 flex justify-between items-center">
                    <h5 className="text-[14px] font-normal">Sipariş Kargo Bilgilerini Kaydet</h5>
                    <button data-modal-close="deleteModal" className="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"><X className="size-5" onClick={onHide} /></button>
                </div>
                <Modal.Body className="p-8 max-h-[calc(theme('height.screen')_-_180px)] min-h-[200px] overflow-y-auto">
                    <div className="flex flex-col gap-3 mb-6">
                        <Alert className="relative p-3 pr-12 text-sm border border-t-2 border-transparent rounded-md border-t-custom-500 text-custom-500 bg-custom-200 dark:bg-custom-400/20 dark:border-t-custom-500/50">
                            <Alert.Close className="absolute top-0 bottom-0 right-0 p-3 transition text-custom-200 hover:text-custom-500 dark:text-custom-400/50 dark:hover:text-custom-500" />
                            <Alert.Bold>#TWT5015100365 numaralı sipariş için kargo bilgilerini ekliyorsunuz.
                                <br />Bu işlem sonrasında müşterinize siparişiniz kargoya verilmiştir bildirimi gönderilecektir.</Alert.Bold>
                        </Alert>
                    </div>
                    <div className="flex justify-between mb-6">
                        <div className="">
                            <span className="text-sm text-slate-600 font-bold">Müşteri Adı : </span>
                            <span className="text-sm text-slate-400">Emin Yormaz</span>
                        </div>
                        <div className="">
                            <span className="text-sm text-slate-600 font-bold">Sipariş Tarihi : </span>
                            <span className="text-sm text-slate-400">26 Nisan 2022 20:12</span>
                        </div>
                    </div>
                    <form action="#!">
                        <div className="mb-6">
                            <label htmlFor="courierCompany" className="inline-block mb-2 text-base font-medium">Kargo Firması seç</label>
                            <Select
                                name="courierCompany"
                                id="courierCompany"
                                className="border-slate-200 focus:outline-none focus:border-custom-500"
                                placeholder="Firması seçiniz"
                                maxMenuHeight={120}
                                options={[
                                    { value: 'Yurtiçi Kargo', label: 'Yurtiçi Kargo' },
                                    { value: 'MNG Kargo', label: 'MNG Kargo' },
                                    { value: 'Sürat Kargo', label: 'Sürat Kargo' },
                                    { value: 'UPS Kargo', label: 'UPS Kargo' },
                                    { value: 'PTT Kargo', label: 'PTT Kargo' },
                                    { value: 'Aras Kargo', label: 'Aras Kargo' },
                                ]}
                                required
                            />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="cargoCode" className="inline-block mb-2 text-base font-medium">Kargo Kodunu Gir</label>
                            <input type="text" id="cargoCode" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Kargo kodunu giriniz" required />
                        </div>
                        <button type="submit" className="btn bg-custom-500 text-custom-200 hover:bg-custom-600 hover:text-custom-100 w-full">Onayla</button>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default DetailModal;