import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";

// interface selectLogoutState {
//   isUserLogout: boolean;
// }

const Logout = () => {
  //   const dispatch = useDispatch<any>();

  //   const selectLogout = createSelector(
  //     (state: RootState) => state.Login as selectLogoutState,
  //     (login) => ({
  //       isUserLogout: login.isUserLogout,
  //     })
  //   );
  // const selectLogin = createSelector(
  //   (state: any) => state.currentUser,
  // (state: any) => state.Login,
  // (register, login) => ({
  //     user: register.user,
  //     success: login.success,
  //     error: login.error
  // })
  // )
  const { currentUser } = useAppSelector((state) => state.auth);

  //   React.useEffect(() => {
  //     dispatch(logoutUser());
  //   }, [dispatch]);

  return !currentUser ? <Navigate to="/login" /> : null;
};

export default Logout;
