import React, { useMemo, useCallback, useEffect, useState } from "react";
import { sendRequestWithCache } from 'helpers/request';
import { useAppSelector } from "../../redux/hooks";
import LoadingOverlay from 'Common/Components/LoadingOverlay';
import { ToastContainer } from "react-toastify";
import TableContainer from "Common/TableContainer";
import NotFound from "../../Common/Components/NotFound/NotFound";
import moment from "moment";

interface BrandBasedReturnReportsTableProps {
    filter: {
        startDate: string;
        endDate: string;
    }
}

const BrandBasedReturnReportsTable = ({ filter }: BrandBasedReturnReportsTableProps) => {
    const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
    const [loading, setLoading] = useState(true);
    const [reportCancellationSummary, setReportCancellationSummary] = useState<any>([]);

    const formatPrice = (price: number) => {
        if (typeof price !== "number") return '0,00₺'
        return new Intl.NumberFormat("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price) + "₺";
    };

    const fetchBrandBasedReturnReports = useCallback(async () => {
        setLoading(true);
        const params = new URLSearchParams({
            start_date: filter.startDate,
            end_date: filter.endDate,
            scope: 'brand',
        });
        try {
            const response = await sendRequestWithCache("POST", `/reports/return/summary?${params}`, null, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });
            setReportCancellationSummary(response);
        } catch (error) {
            return error
        } finally {
            setLoading(false);
        }
    }, [filter, bearer_token]);

    useEffect(() => {
        fetchBrandBasedReturnReports();
    }, [filter, fetchBrandBasedReturnReports]);

    const list = reportCancellationSummary?.map((item: {
        brand_name: string;
        customer_demand: { count: number };
        owner_demand: { count: number };
        vendor_demand: { count: number };
        total_returns_count: number;
        total_sales_count: number;
        total_sales_loss: number;
        total_sales_revenue: number;
    }) => {
        return {
            brand: item.brand_name,
            grossTurnover: formatPrice(item.total_sales_revenue),
            estimatedSalesLoss: formatPrice(item.total_sales_loss),
            grossSalesQuantity: item.total_sales_count,
            numberOfReturns: item.total_returns_count,
            // designDislike: item.,
            // wrongOrder: item.,
            // cancelled: item.,
        }
    });


    const columns = useMemo(
        () => [
            {
                header: "Marka",
                accessorKey: "brand",
                enableColumnFilter: false,
            },
            {
                header: "Brüt Ciro",
                accessorKey: "grossTurnover",
                enableColumnFilter: false,
            },
            {
                header: "Tahmini Satış Kaybı",
                accessorKey: "estimatedSalesLoss",
                enableColumnFilter: false,
            },
            {
                header: "Brüt Satış Adedi",
                accessorKey: "grossSalesQuantity",
                enableColumnFilter: false,
            },
            {
                header: "İade Adedi",
                accessorKey: "numberOfReturns",
                enableColumnFilter: false,
            },
            {
                header: "Modeli Beğenmedim",
                accessorKey: "designDislike",
                enableColumnFilter: false,
            },
            {
                header: "Yanlış Sipariş Verdim",
                accessorKey: "wrongOrder",
                enableColumnFilter: false,
            },
            {
                header: "Vazgeçtim",
                accessorKey: "cancelled",
                enableColumnFilter: false,
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} limit={1} />
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
                <div className="xl:col-span-12">
                    <div className="card relative">
                        <LoadingOverlay loading={loading} color="bg-custom-500" />
                        <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                            <React.Fragment>
                                <div className="md:flex justify-between items-center">
                                    <div className="mb-2">
                                        <h6 className="text-xl font-normal">Marka Bazlı İade Raporları</h6>
                                    </div>
                                    <div className="mb-2 flex sm:items-center items-start flex-col sm:flex-row">
                                        <div className="sm:text-end text-start mb-1 sm:mr-4 mr-0 sm:ml-auto">
                                            <p className="text-sm">Filtreleme Sonuçları: Toplam {list.length} yorum bilgisi</p>
                                            <span className="text-sm text-gray-400">Son Güncellenme: {moment().format('DD MMMM YYYY HH:mm')}</span>
                                        </div>
                                        <div>
                                            <div className="relative flex-1">
                                            </div>
                                            <div className='self-center place-self-end'>
                                                <button
                                                    type="button"
                                                    className="btn border-slate-200 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 mb-2"
                                                >
                                                    Excel ile indir{" "}
                                                    <i className="align-baseline ltr:pr-1 rtl:pl-1 ri-download-2-line"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                            {list.length > 0 ? (
                                <TableContainer
                                    isPagination={true}
                                    columns={columns}
                                    data={list}
                                    customPageSize={10}
                                    divclassName="-mx-5 -mb-5 overflow-x-auto"
                                    tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                                    theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                                    thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold text-center"
                                    tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 text-center"
                                    PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                                />
                            ) : (
                                <NotFound />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};

export default BrandBasedReturnReportsTable;
