import React, { useMemo } from "react";
import { ToastContainer } from "react-toastify";
import TableContainer from "Common/TableContainer";
import { Link } from "react-router-dom";
import NotFound from "../../Common/Components/NotFound/NotFound";
import moment from "moment";

interface Props {
    reportCancellationSummary: {
        product_title: string;
        images: string;
        product_code: string;
        total_sales_revenue: string;
        total_cancellations_price: string;
        total_sales_count: number;
        total_cancellations_count: number;
        customer_demand: {
            count: number;
        };
        owner_demand: {
            count: number;
        };
        vendor_demand: {
            count: number;
        };
    }[];
    loading: boolean;
}


const ProductBasedCancellationReportsTable = ({ reportCancellationSummary, loading }: Props) => {
    const list = reportCancellationSummary ? reportCancellationSummary.map((item: any) => {
        return {
            productInfo: item.product_title,
            img: item.images,
            designation: item.product_code,
            grossTurnover: item.total_sales_revenue,
            estimatedSalesLoss: item.total_cancellations_price,
            grossSalesQuantity: item.total_sales_count,
            numberOfCancellations: item.total_cancellations_count,
            customerCancelled: item.customer_demand.count,
            aCommerceCancelled: item.owner_demand.count,
            iCancelled: item.vendor_demand.count
        }
    }) : Array(10).fill({});

    const textWrap = (text: string) => {
        return (
            <div className="text-center mx-[15px]">
                {text.split(" ").map((item, index) => (
                    <span key={index}>
                        {item}
                        <br />
                    </span>
                ))}
            </div>
        );
    };


    const columns = useMemo(
        () => [
            {
                header: "Ürün Bilgileri",
                accessorKey: "productInfo",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell: any) => {
                    return (
                        <div className="flex items-center gap-2 w-full overflow-hidden min-w-72">
                            <div className="h-16 flex items-center justify-center font-medium rounded shrink-0 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
                                <img
                                    src={cell.row.original.img}
                                    alt=""
                                    className="h-full rounded"
                                />
                            </div>
                            <div className="grow">
                                <h6 className="mb-1 ">
                                    <Link to="#!" className="name text-wrap line-clamp-2">
                                        {cell.getValue()}
                                    </Link>
                                </h6>
                                <p className="text-slate-500 dark:text-zink-200 text-wrap line-clamp-1">
                                    Ürün Kodu: {cell.row.original.designation}
                                </p>
                            </div>
                        </div>
                    );
                },
            },
            {
                header: textWrap("Brüt Ciro"),
                accessorKey: "grossTurnover",
                enableColumnFilter: false,
            },
            {
                header: textWrap("Tahmini Satış Kaybı"),
                accessorKey: "estimatedSalesLoss",
                enableColumnFilter: false,
            },
            {
                header: textWrap("Brüt Satış Adedi"),
                accessorKey: "grossSalesQuantity",
                enableColumnFilter: false,
            },
            {
                header: textWrap("İptal Adedi"),
                accessorKey: "numberOfCancellations",
                enableColumnFilter: false,
            },
            {
                header: textWrap("Müşterinin İptal Ettiği"),
                accessorKey: "customerCancelled",
                enableColumnFilter: false,
            },
            {
                header: textWrap("a-Commerce’in İptal Ettiği"),
                accessorKey: "aCommerceCancelled",
                enableColumnFilter: false,
            },
            {
                header: textWrap("Benim İptal Ettiğim"),
                accessorKey: "iCancelled",
                enableColumnFilter: false,
            }
        ],
        []
    );

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} limit={1} />
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
                <div className="xl:col-span-12">
                    <div className="card relative">
                        {loading && (
                            <div className="flex items-center justify-center h-full absolute inset-0">
                                <div className="flex flex-col items-center">
                                    <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                                </div>
                            </div>
                        )}
                        <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                            <React.Fragment>
                                <div className="md:flex justify-between items-center">
                                    <div className="mb-2">
                                        <h6 className="text-xl font-normal">Ürün Bazlı İptal Raporları</h6>
                                    </div>
                                    <div className="mb-2 flex sm:items-center items-start flex-col sm:flex-row">
                                        <div className="sm:text-end text-start mb-1 sm:mr-4 mr-0 sm:ml-auto">
                                            <p className="text-sm">Filtreleme Sonuçları: Toplam {list.length} yorum bilgisi</p>
                                            <span className="text-sm text-gray-400">Son Güncellenme: {moment().format('DD MMMM YYYY HH:mm')}</span>
                                        </div>
                                        <div>
                                            <div className="relative flex-1">
                                            </div>
                                            <div className='self-center place-self-end'>
                                                <button
                                                    type="button"
                                                    className="btn border-slate-200 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 mb-2"
                                                >
                                                    Excel ile indir{" "}
                                                    <i className="align-baseline ltr:pr-1 rtl:pl-1 ri-download-2-line"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                            {list.length > 0 ? (
                                <TableContainer
                                    isPagination={true}
                                    columns={columns}
                                    data={list}
                                    customPageSize={10}
                                    divclassName="-mx-5 -mb-5 overflow-x-auto"
                                    tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                                    theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                                    thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                                    tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 first:text-start text-center"
                                    PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                                />
                            ) : (
                                <NotFound />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};

export default ProductBasedCancellationReportsTable;
