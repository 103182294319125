import { X } from "lucide-react";
import PhoneInput from "react-phone-input-2";
import { Formik, Form } from "formik";
import Modal from "../Modal";
import { SubmitFunc } from "models/models";
import { useState } from "react";
import { toastWarnNotify } from "helpers/ToasNotify";
// import "react-phone-input-2/lib/style.css";

interface ModalType {
  modal: {
    isOpen: boolean;
    title: string;
    info: string;
    modalTitle: string;
  };
  offModal?: () => void;
  emailChange?: any;
  loading?: boolean;
  phoneChange?: any;
}
interface PhonePropsType {
  setInput: any;
  input: {
    phone: string;
    phone_country: string;
    phone2: string;
    phone_country2: string;
  };
}

const Eposta: React.FC = () => {
  return (
    <>
      <div>
        <p className="text-gray-400">Yeni e-posta adresinizi girebilirsiniz.</p>
      </div>
      <div>
        <label
          htmlFor="email"
          className="inline-block mb-2 text-base font-medium"
        >
          E-Posta Adresim
        </label>
        <input
          type="email"
          name="email"
          id="email"
          className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
          placeholder="Yeni E-Posta Adresi"
          required
        />
      </div>
      <div>
        <label
          htmlFor="stock"
          className="inline-block mb-2 text-base font-medium"
        >
          E-Posta Adresim Tekrar
        </label>
        <input
          type="email"
          name="email_confirmation"
          id="email_confirmation"
          className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
          placeholder="Yeni E-Posta Adresi"
          required
        />
      </div>
    </>
  );
};

const Phone: React.FC<PhonePropsType> = ({ setInput, input }) => {
  return (
    <>
      <div>
        <p className="text-gray-400">Yeni telefon numaranızı girebilirsiniz.</p>
      </div>
      <div className="flex flex-col gap-2 ">
        <div>
          <label
            htmlFor="phone"
            className="inline-block mb-2 text-base font-medium"
          >
            Telefon Numaram
          </label>
          <PhoneInput
            specialLabel=""
            placeholder="+90 05** *** ****"
            inputProps={{ required: true, name: "phone" }}
            country="tr"
            // value={input.phone}
            // onChange={(e: string, i: any, a: any, s: any) =>
            //   setInput({ ...input, phone: e, phone_country: i.dialCode })
            // }
            inputClass="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="phone2"
            className="inline-block mb-2 text-base font-medium"
          >
            Telefon Numaram Tekrar
          </label>
          <PhoneInput
            inputProps={{ required: true, name: "phone2" }}
            country="tr"
            placeholder="+90 05** *** ****"
            onChange={(e: any, i: any) =>
              setInput({ ...input, phone_country2: i.dialCode })
            }
            inputClass="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
            specialLabel=""
          />
        </div>
      </div>
    </>
  );
};

const ProfileModal: React.FC<ModalType> = ({
  modal,
  offModal,
  emailChange,
  loading,
  phoneChange,
}) => {
  const [input, setInput] = useState({
    phone: "",
    phone_country: "",
    phone2: "",
    phone_country2: "",
  });
  const handleSubmit: SubmitFunc = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    if (modal.modalTitle === "E-Posta Adresi") {
      emailChange(formData);
    } else if (modal.modalTitle === "Telefon Numaramı") {
      if (formData.get("phone") !== formData.get("phone2")) {
        return toastWarnNotify("Telefon numaralarını aynı giriniz !");
      }
      formData.delete("phone2");
      console.log(
        formData
          .get("phone")
          ?.toString()
          .split(input.phone_country2)[1]
          .replace(/ /g, "")
      );

      const phone: any = formData
        .get("phone")
        ?.toString()
        .split(input.phone_country2)[1]
        .replace(/ /g, "");
      formData.delete("phone");
      formData.append("phone", phone);
      formData.append("phone_country", input.phone_country2);
      phoneChange(formData);
    }
  };
  return (
    <>
      <Modal
        show={modal.isOpen}
        onHide={offModal}
        id="accountModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[35rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] overflow-y-auto px-6 py-8 relative">
          <div className="absolute top-4 right-4">
            <button
              data-modal-close="accountModal"
              className="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"
              onClick={offModal}
            >
              <X className="size-5" />
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col justify-center gap-4">
              <div>
                <h4>{`${modal.modalTitle} Güncelle`}</h4>
              </div>

              {modal.modalTitle === "E-Posta Adresi" && <Eposta />}
              {modal.modalTitle === "Telefon Numaramı" && (
                <Phone setInput={setInput} input={input} />
              )}

              <div className="flex gap-2 justify-between">
                <button
                  type="reset"
                  className="text-slate-500 btn bg-slate-200 border-slate-200 hover:text-slate-600 hover:bg-slate-300 hover:border-slate-300 focus:text-slate-600 focus:bg-slate-300 focus:border-slate-300 focus:ring focus:ring-slate-100 active:text-slate-600 active:bg-slate-300 active:border-slate-300 active:ring active:ring-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 dark:border-zink-600 dark:hover:border-zink-500 dark:text-zink-200 dark:ring-zink-400/50 md:w-[200px] w-full"
                  onClick={offModal}
                >
                  <span className="align-middle">Vazgeç</span>
                </button>
                <button
                  disabled={loading}
                  type="submit"
                  className="flex justify-center items-center gap-2 text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 md:w-[200px] w-full"
                >
                  {loading && (
                    <div className="inline-block size-4 border-2 rounded-full animate-spin border-l-transparent border-white"></div>
                  )}
                  Güncelle
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfileModal;
