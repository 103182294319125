import React, { useCallback, useEffect, useState } from 'react';
import { sendRequestWithCache } from 'helpers/request';
import { useAppSelector } from "../../../redux/hooks";
import { AudienceChart } from './Charts';

interface AudienceData {
    female_orders: string;
    male_orders: string;
    order_date: string;
    total_orders: number;
}

const Audience = () => {
    const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
    const [loading, setLoading] = useState(true);
    const [series, setSeries] = useState<any[]>([]);

    const fetchGendersOfCustomers = useCallback(async () => {
        try {
            const { data } = await sendRequestWithCache("GET", "/user/dashboard/gendersOfCustomers", null, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });
            setSeries(
                [
                    {
                        name: 'Erkek',
                        data: data.map((item: AudienceData) => item.male_orders)
                    },
                    {
                        name: 'Kadın',
                        data: data.map((item: AudienceData) => item.female_orders)
                    }
                ]
            );
        } catch (error) {
            return error
        } finally {
            setLoading(false);
        }
    }, [bearer_token]);

    useEffect(() => {
        fetchGendersOfCustomers();
    }, [fetchGendersOfCustomers]);

    return (
        <React.Fragment>
            <div className="col-span-12 card lg:col-span-6 2xl:col-span-3 relative">
                {loading && (
                    <div className="flex items-center justify-center h-full absolute inset-0">
                        <div className="flex flex-col items-center">
                            <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                        </div>
                    </div>
                )}
                <div className={loading ? 'opacity-0' : ''}>
                    <div className="!pb-0 mb-3 card-body flex items-center justify-between">
                        <h6 className="mb-0">Müşteri Kitlesi</h6>
                    </div>
                    <AudienceChart chartId="audienceChart" series={series} />
                </div>

            </div>
        </React.Fragment>
    );
};

export default Audience;
