import { Dropdown } from "Common/Components/Dropdown";
import TableContainer from "Common/TableContainer";
import iphone from "assets/images/product/iphone.png";
import { Eye, MoreHorizontal } from "lucide-react";
import { Link } from "react-router-dom";
import { PropsType } from "./AllRefund";

const reactTableData = [
  {
    kargoKodu: "7320004301539634",
    id: 1,

    kod: "#TWT5015100365",
    name: "Tiger Nixon",
    position: "07 Şubat 2024 13:24",
    office: "Onur",
    age: 61,
    img: iphone,
    startDate: "2011-04-25",
    salary: "$320,800",
    aCommercesalary: "$122",
    stock: "19",
    reply: true,
    fatura: "200",
    kargoImg: " https://www.araskargo.com.tr/assets/images/aras-logo.svg",
    sebep: "Modeli Beğenmedim",
  },
  {
    kargoKodu: "7320004301539634",
    id: 2,
    kod: "#TWT5015100365",
    name: "Garrett Winters",
    position: "07 Şubat 2024 13:24",
    img: iphone,
    office: "Burak",
    age: 63,
    startDate: "2011-07-25",
    salary: "$170,750",
    aCommercesalary: "$122",
    stock: "14",
    fatura: "200",
    kargoImg: " https://www.araskargo.com.tr/assets/images/aras-logo.svg",
    sebep: "Modeli Beğenmedim",
  },
  {
    kargoKodu: "7320004301539634",
    id: 3,
    kod: "#TWT5015100365",
    name: "Ashton Cox",
    position: "07 Şubat 2024 13:24",
    office: "Hüseyin",
    img: iphone,
    age: 66,
    startDate: "2009-01-12",
    salary: "$86,000",
    aCommercesalary: "$122",
    stock: "18",
    fatura: "200",
    kargoImg: " https://www.araskargo.com.tr/assets/images/aras-logo.svg",
    sebep: "Modeli Beğenmedim",
  },
  {
    kargoKodu: "7320004301539634",
    id: 4,
    kod: "#TWT5015100365",
    name: "Cedric Kelly",
    position: "07 Şubat 2024 13:24",
    office: "Uygar",
    img: iphone,
    age: 22,
    startDate: "2012-03-29",
    salary: "$433,060",
    aCommercesalary: "$122",
    stock: "6",
    fatura: "200",
    kargoImg: " https://www.araskargo.com.tr/assets/images/aras-logo.svg",
    sebep: "Modeli Beğenmedim",
  },
  {
    kargoKodu: "7320004301539634",
    id: 5,
    kod: "#TWT5015100365",
    name: "Airi Satou",
    position: "07 Şubat 2024 13:24",
    img: iphone,
    office: "Tokyo",
    age: 33,
    startDate: "2008-11-28",
    salary: "$162,700",
    aCommercesalary: "$122",
    stock: "10",
    fatura: "200",
    kargoImg: " https://www.araskargo.com.tr/assets/images/aras-logo.svg",
    sebep: "Modeli Beğenmedim",
  },
  {
    kargoKodu: "7320004301539634",
    id: 6,
    kod: "#TWT5015100365",
    name: "Brielle Williamson",
    position: "07 Şubat 2024 13:24",
    img: iphone,
    office: "New York",
    age: 61,
    startDate: "2012-12-02",
    salary: "$372,000",
    aCommercesalary: "$122",
    stock: "50",
    fatura: "200",
    kargoImg: " https://www.araskargo.com.tr/assets/images/aras-logo.svg",
    sebep: "Modeli Beğenmedim",
  },
  {
    kargoKodu: "7320004301539634",
    id: 7,
    kod: "#TWT5015100365",
    name: "Herrod Chandler",
    position: "07 Şubat 2024 13:24",
    office: "San Francisco",
    img: iphone,
    age: 59,
    startDate: "2012-08-06",
    salary: "$137,500",
    aCommercesalary: "$122",
    stock: "100",
    kargoImg: " https://www.araskargo.com.tr/assets/images/aras-logo.svg",
    sebep: "Modeli Beğenmedim",

    fatura: "200",
  },
  {
    id: 8,
    kargo: "Aras Kargo",
    kargoKodu: "7320004301539634",
    kargoImg: " https://www.araskargo.com.tr/assets/images/aras-logo.svg",
    kod: "#TWT5015100365",
    name: "Rhona Davidson",
    position: "07 Şubat 2024 13:24",
    office: "Tokyo",
    img: iphone,
    age: 55,
    startDate: "2010-10-14",
    salary: "$327,900",
    aCommercesalary: "$122",
    stock: "50",
    fatura: "200",
    sebep: "Modeli Beğenmedim",
  },
];

const Rejected: React.FC<PropsType> = ({ data, columns }) => {

  return (
    <>
      <div className="card">
        <div className="card-body">
          <TableContainer
            isPagination={true}
            columns={columns || []}
            data={data || []}
            customPageSize={10}
            divclassName="-mx-5 -mb-5 overflow-x-auto"
            tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
            theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
            thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
            tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5  border-b-[1px] dark:border-zink-600"
            PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
            downloadExcel
            isSelect
            title="İade Tarihi"
          />
        </div>
      </div>
    </>
  );
};

export default Rejected;
