import React from "react";
import Logo from "../../../assets/images/auth/acommerce.jpeg";

const AuthImage = () => {
  return (
    <React.Fragment>
      <div className="flex justify-center items-center w-2/4">
        <div>
          <img src={Logo} alt="aCommerce Logo" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AuthImage;
