import React from 'react'

interface Props {
    loading: boolean;
    color?: string;
}

const LoadingOverlay: React.FC<Props> = ({ loading, color }) => {
    return (
        <React.Fragment>
            {loading && (
                <div className="flex items-center justify-center h-full absolute inset-0">
                    <div className="flex flex-col items-center">
                        <div className={`inline-flex size-10 rounded-full opacity-75 animate-bounce ${color}`}></div>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}

export default LoadingOverlay;