import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Facebook, Github, Mail, Twitter } from "lucide-react";
import { object, string } from "yup";
import { Formik, Form } from "formik";

import Tab from "Common/Components/Tab/Tab";
import withRouter from "Common/withRouter";
import { sendRequestWithCache } from "../../helpers/request";
import AuthDropdown from "Common/Components/auth/AuthDropdown";
import AuthImage from "Common/Components/auth/AuthImage";
import AuthInfo from "Common/Components/auth/AuthInfo";
import AuthBackground from "Common/Components/auth/AuthBackground";
import { loginSuccess } from "../../redux/auth/authSlice";
import { StateType } from "models/models";
import useUser from "../../hooks/useUser";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

export const scheme = object({
  email: string()
    .trim()
    .required("E-Posta zorunludur.")
    .email("E-Postanızı giriniz."),
  password: string()
    .trim()
    .required("Şifre zorunludur.")
    .min(8, "Password en az 8 karakter olmalıdır")
    .max(20, "Şifre en fazla 20 karakter olmalıdı"),
});

const Login = () => {
  document.title = "Giriş | a-Commerce";
  const [state, setState] = useState<StateType>({
    error: false,
    loading: false,
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);
  const { userGet } = useUser();

  useEffect(() => {
    if (currentUser?.bearer_token) navigate("/");
    const bodyElement = document.body;
    bodyElement.classList.add("font-public");
    return () => {
      bodyElement.classList.remove("font-public");
    };
  }, []);

  return (
    <React.Fragment>
      <div className="relative flex flex-col w-full overflow-hidden lg:flex-row to-custom-800 bg-gradient-to-r from-custom-900 dark:to-custom-900 dark:from-custom-950">
        <AuthBackground />
        <div className="min-h-[calc(100vh_-_theme('spacing.4')_*_2)] mx-3 lg:w-[40rem] xl:w-[45rem] 2xl:w-[50rem] shrink-0 px-10 py-14 flex items-center justify-center m-4 bg-white rounded z-10 relative dark:bg-zink-700 dark:text-zink-100  md:mx-4">
          <div className="flex flex-col gap-5 w-full h-full">
            <AuthDropdown />
            <div className="flex-1 flex gap-5 flex-col items-center justify-evenly">
              <AuthImage />
              <h3 className="text-blue-600 text-lg sm:text-2xl">Giriş Yap</h3>
            </div>
            <div className="my-auto flex-auto">
              <Tab.Container defaultActiveKey="emailLogin">
                <div className="lg:w-[25rem] mx-auto">
                  <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={scheme}
                    onSubmit={async (values, actions) => {
                      setState({ loading: true, error: false });
                      const formData = new FormData();
                      formData.append("email", values.email);
                      formData.append("password", values.password);

                      try {
                        const data = await sendRequestWithCache(
                          "POST",
                          "/login",
                          formData,
                          {
                            cache: true,
                            cacheName: "auth",
                          }
                        );
                        dispatch(loginSuccess(data));
                        actions.setSubmitting(false);
                        actions.resetForm();
                        if (data.two_fa_auth) {
                          navigate("/verification");
                        } else {
                          navigate("/");
                          userGet(data);
                        }
                      } catch (err: any) {
                        setState({
                          ...state,
                          loading: false,
                          error: err.response.data.message,
                        });
                      }
                    }}
                    component={({
                      values,
                      handleChange,
                      errors,
                      touched,
                      handleBlur,
                      isSubmitting,
                    }) => (
                      <Form action="/" className="mt-10" id="signInForm1">
                        <div className="mb-3">
                          <label
                            htmlFor="email"
                            className="inline-block mb-2 text-base font-medium"
                          >
                            E-Posta
                          </label>
                          <input
                            type="text"
                            name="email"
                            id="email"
                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            placeholder="Kullanıcı adınız veya e-postanı gir"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email || ""}
                          />

                          {touched.email && Boolean(errors.email) && (
                            <div className="mt-1 text-sm text-red-500">
                              {errors.email}
                            </div>
                          )}
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="password"
                            className="inline-block mb-2 text-base font-medium"
                          >
                            Şifre
                          </label>
                          <input
                            type="password"
                            name="password"
                            id="password"
                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            placeholder="Şifreni gir"
                            value={values.password || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.password && Boolean(errors.password) && (
                            <div className="mt-1 text-sm text-red-500">
                              {errors.password}
                            </div>
                          )}
                          {state.error && (
                            <div className="mt-1 text-sm text-red-500">
                              {state.error}
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="flex items-center gap-2">
                            <input
                              id="emailCheckRememberMe"
                              className="size-4 border rounded-sm appearance-none bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500 checked:disabled:bg-custom-400 checked:disabled:border-custom-400"
                              type="checkbox"
                              value=""
                            />
                            <label
                              htmlFor="emailCheckRememberMe"
                              className="inline-block text-base font-medium align-middle cursor-pointer"
                            >
                              Beni Hatırla
                            </label>
                          </div>
                          <div
                            id="remember-error"
                            className="hidden mt-1 text-sm text-red-500"
                          >
                            Please check the "Remember me" before submitting the
                            form.
                          </div>
                        </div>
                        <div className="mt-10">
                          <button
                            type="submit"
                            className="w-full text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 disabled:opacity-50"
                            disabled={isSubmitting}
                          >
                            Giriş Yap
                          </button>
                        </div>
                      </Form>
                    )}
                  />

                  <div className="relative text-center my-9 before:absolute before:top-3 before:left-0 before:right-0 before:border-t before:border-t-slate-200 dark:before:border-t-zink-500">
                    <h5 className="inline-block px-4 py-0.5 text-sm bg-white text-slate-500 dark:bg-zink-700 dark:text-zink-200 rounded relative">
                      Ya da
                    </h5>
                  </div>

                  <div className="flex flex-wrap justify-center gap-2">
                    <button
                      type="button"
                      className="flex items-center justify-center size-[37.5px] transition-all duration-200 ease-linear p-0 text-custom-500 btn bg-custom-100 border-custom-100 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 active:text-white active:bg-custom-600 active:border-custom-600"
                    >
                      <Facebook className="size-4"></Facebook>
                    </button>
                    <button
                      type="button"
                      className="flex items-center justify-center size-[37.5px] transition-all duration-200 ease-linear p-0 text-orange-500 btn bg-orange-100 border-orange-100 hover:text-white hover:bg-orange-600 hover:border-orange-600 focus:text-white focus:bg-orange-600 focus:border-orange-600 active:text-white active:bg-orange-600 active:border-orange-600"
                    >
                      <Mail className="size-4"></Mail>
                    </button>
                    <button
                      type="button"
                      className="flex items-center justify-center size-[37.5px] transition-all duration-200 ease-linear p-0 text-sky-500 btn bg-sky-100 border-sky-100 hover:text-white hover:bg-sky-600 hover:border-sky-600 focus:text-white focus:bg-sky-600 focus:border-sky-600 active:text-white active:bg-sky-600 active:border-sky-600"
                    >
                      <Twitter className="size-4"></Twitter>
                    </button>
                    <button
                      type="button"
                      className="flex items-center justify-center size-[37.5px] transition-all duration-200 ease-linear p-0 text-slate-500 btn bg-slate-200 border-slate-200 hover:text-white hover:bg-slate-600 hover:border-slate-600 focus:text-white focus:bg-slate-600 focus:border-slate-600 active:text-white active:bg-slate-600 active:border-slate-600"
                    >
                      <Github className="size-4"></Github>
                    </button>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>

        <AuthInfo />
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
