import React, { useCallback, useEffect, useMemo, useState, FormEvent, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { sendRequestWithCache } from 'helpers/request';
import { useAppSelector } from "../../redux/hooks";
import TableContainer from "Common/TableContainer";
import Flatpickr from 'react-flatpickr';
import Select from "react-select";
import SalesCommissionChart from './SalesCommissionChart';
import { CalendarDays } from "lucide-react";
import NotFound from "../../Common/Components/NotFound/NotFound";
import { Info } from "lucide-react";
import moment from "moment";



const PastPage = ({ path }: { path: string }) => {
    const naigate = useNavigate();
    const formRef = useRef<HTMLFormElement>(null);
    const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
    const [loading, setLoading] = useState(true);
    const [ProductListViewData, setProductListViewData] = useState(Array(10).fill({}));
    const [graphData, setGraphData] = useState([]);
    const [filter, setFilter] = useState({
        cancelStartDate: moment().subtract(30, 'days').format('DD MMM, YYYY'),
        cancelEndDate: moment().format('DD MMM, YYYY'),
    });

    const [selectedDate, setSelectedDate] = useState({
        startDate: moment().subtract(30, 'days').toISOString().split('T')[0],
        endDate: moment().toISOString().split('T')[0],
    });

    const columns = useMemo(
        () => [
            {
                header: 'Ödeme Tarihi',
                accessorKey: 'paymentDate',
                enableColumnFilter: false,
            },
            {
                header: 'Satış Tutarı',
                accessorKey: 'total_Price',
                enableColumnFilter: false,
            },
            {
                header: 'Komisyon Kesintisi',
                accessorKey: 'commission',
                enableColumnFilter: false,
            },
            {
                header: 'Kargo Kesintisi',
                accessorKey: 'shipmentPrice',
                enableColumnFilter: false,
            },
            {
                header: 'Diğer',
                accessorKey: 'other',
                enableColumnFilter: false,
            },
            {
                header: 'Toplam Hakediş',
                accessorKey: 'netEarnings',
                enableColumnFilter: false,
            }
        ],
        []
    );

    const handleFilter = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const data = {
            cancelStartDate: formData.get('cancelStartDate') as string,
            cancelEndDate: formData.get('cancelEndDate') as string,
        };
        path === 'date' ? (
            setFilter(data)
        ) : path === 'order' && (
            naigate(`/finance/order-detail/${formData.get('orderNumber')}`)
        )
    }

    const handleFilterReset = () => {
        formRef.current?.cancelStartDate._flatpickr.setDate(moment().subtract(30, 'days').format('DD MMM, YYYY'));
        formRef.current?.cancelEndDate._flatpickr.setDate(moment().format('DD MMM, YYYY'));
    };

    const formatDate = (date: string) => {
        const newDate = new Date(date);
        return newDate.toLocaleDateString("tr-TR", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    }

    const formatPrice = (price: number) => {
         if (typeof price !== "number") return '0,00₺'
        return new Intl.NumberFormat("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price) + "₺";
    };

    const fetchPastPayments = useCallback(async () => {
        setLoading(true);
        try {
            const response = await sendRequestWithCache("POST", "/finance/past-payments", {
                start_date: moment(filter.cancelStartDate, 'DD MMM, YYYY').format('YYYY-MM-DD'),
                end_date: moment(filter.cancelEndDate, 'DD MMM, YYYY').format('YYYY-MM-DD'),
            }, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });

            setProductListViewData(response.data.map((item: {
                commission: number,
                net_earnings: number,
                payment_date: string,
                shipment_price: number,
                total_price: number,
                other_price: number,
            }) => {
                return {
                    commission: formatPrice(item.commission),
                    netEarnings: formatPrice(item.net_earnings),
                    paymentDate: formatDate(item.payment_date),
                    shipmentPrice: formatPrice(item.shipment_price),
                    total_Price: formatPrice(item.total_price),
                    other: formatPrice(item.other_price),
                }
            }));

            setGraphData(response.graph_data.map((item: {
                date: string,
                sales: number,
                commission: number,
                shipping: number,
                other: number,
            }) => {
                return {
                    date: '07 Eyl',
                    sales: item.sales,
                    commission: item.commission,
                    shipping: item.shipping,
                    other: item.other,
                }
            }));

        } catch (error) {
            return error
        } finally {
            setLoading(false);
        }
    }, [bearer_token, filter.cancelStartDate, filter.cancelEndDate]);

    useEffect(() => {
        fetchPastPayments();
    }, [filter, fetchPastPayments]);

    return (
        <React.Fragment>
            <form ref={formRef} onSubmit={handleFilter}>
                <div className="grid grid-cols-1 gap-x-5 md:grid-cols-3 xl:grid-cols-4 flex-1">
                    <div className="mb-4">
                        <label
                            htmlFor="search-type"
                            className="inline-block mb-2 text-base font-medium"
                        >
                            Arama Türü
                        </label>
                        <Select
                            id="search-type"
                            styles={{
                                placeholder: (base) => ({
                                    ...base,
                                    color: '#92AFD3',
                                }),
                                // singleValue: (base) => ({
                                //     ...base,
                                //     color: 'rgba(200, 215, 233, 1)',
                                // }), dark mode için bu renk kullanılacak
                            }}
                            defaultValue={{ value: path, label: path === 'date' ? 'Tarihe Göre Ara' : 'Sipariş No ile Ara' }}
                            onChange={(option) => {
                                naigate(`/finance/payments/past/${option?.value}`);
                            }}
                            options={[
                                { value: "date", label: "Tarihe Göre Ara" },
                                { value: "order", label: "Sipariş No ile Ara" },
                            ]}
                            placeholder="Arama türünü seçiniz"
                        />
                    </div>
                    {
                        path === 'date' ? (
                            <React.Fragment>
                                <div className="relative mb-4">
                                    <label htmlFor="cancel-start-date" className="flex items-center justify-start gap-1 mb-2 text-base font-medium">
                                        Başlangıç Tarihi
                                        <div className="relative group group/item">
                                            <Info size={14} className='text-blue-400' />
                                            <div className="absolute group/edit invisible group-hover/item:visible group-hover/item:opacity-100 left-0 bottom-full mb-2 w-48 p-2 text-xs text-white bg-black rounded-lg opacity-0 transition-opacity duration-200 text-justify">
                                                Seçeceğiniz başlangıç ve bitiş tarihinin aralığı 30 günü geçmemelidir.
                                            </div>
                                        </div>
                                    </label>
                                    <Flatpickr
                                        id="cancel-start-date"
                                        name="cancelStartDate"
                                        onChange={(date) => setSelectedDate({ ...selectedDate, startDate: date[0].toISOString().split('T')[0] })}
                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        options={{
                                            dateFormat: "d M Y",
                                            defaultDate: moment().subtract(30, 'days').toDate(),
                                            locale: {
                                                weekdays: {
                                                    longhand: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
                                                    shorthand: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt']
                                                },
                                                months: {
                                                    longhand: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
                                                    shorthand: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara']
                                                },
                                            }
                                        }}
                                        placeholder='Başlangıç tarihini seçiniz'
                                        lang='tr'
                                    />
                                    <CalendarDays className="absolute size-4 right-3 bottom-3 text-slate-500 dark:text-zink-200" />
                                </div>
                                <div className="relative mb-4">
                                    <label htmlFor="cancel-end-date" className="flex items-center justify-start gap-1 mb-2 text-base font-medium">
                                        Bitiş Tarihi
                                        <div className="relative group group/item">
                                            <Info size={14} className='text-blue-400' />
                                            <div className="absolute group/edit invisible group-hover/item:visible group-hover/item:opacity-100 left-0 bottom-full mb-2 w-48 p-2 text-xs text-white bg-black rounded-lg opacity-0 transition-opacity duration-200 text-justify">
                                                Seçeceğiniz başlangıç ve bitiş tarihinin aralığı 30 günü geçmemelidir.
                                            </div>
                                        </div>
                                    </label>
                                    <Flatpickr
                                        id="cancel-end-date"
                                        name="cancelEndDate"
                                        onChange={(date) => setSelectedDate({ ...selectedDate, endDate: date[0].toISOString().split('T')[0] })}
                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        options={{
                                            maxDate: "today",
                                            dateFormat: "d M Y",
                                            defaultDate: "today",
                                            locale: {
                                                weekdays: {
                                                    longhand: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
                                                    shorthand: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt']
                                                },
                                                months: {
                                                    longhand: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
                                                    shorthand: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara']
                                                },
                                            }
                                        }}
                                        placeholder='Bitiş tarihini seçiniz'
                                    />
                                    <CalendarDays className="absolute size-4 right-3 bottom-3 text-slate-500 dark:text-zink-200" />
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className="mb-4">
                                <label htmlFor="order-number" className="inline-block mb-2 text-base font-medium">Sipariş Numarası</label>
                                <input
                                    type="text"
                                    id="order-number"
                                    name="orderNumber"
                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    placeholder="Sipariş numarasını giriniz"
                                />
                            </div>
                        )
                    }
                </div>
                <div className="flex flex-col-reverse md:flex-row items-start md:items-center justify-between gap-6 md:gap-2 ">
                    {path === 'date' && (
                        <h5 className="text-16">Geçmiş Ödemeler Özeti <span className="text-slate-400 font-medium">({formatDate(filter.cancelStartDate)} - {formatDate(filter.cancelEndDate)})</span></h5>
                    )}
                    <div className="flex flex-1 items-center justify-end gap-2 w-full">
                        <button
                            type="button"
                            onClick={handleFilterReset}
                            className="text-slate-500 btn bg-slate-200 border-slate-200 hover:text-slate-600 hover:bg-slate-300 hover:border-slate-300 focus:text-slate-600 focus:bg-slate-300 focus:border-slate-300 focus:ring focus:ring-slate-100 active:text-slate-600 active:bg-slate-300 active:border-slate-300 active:ring active:ring-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 dark:border-zink-600 dark:hover:border-zink-500 dark:text-zink-200 dark:ring-zink-400/50 md:w-[200px] w-full"
                        >
                            <span className="align-middle">Temizle</span>
                        </button>
                        <button
                            disabled={new Date(selectedDate.endDate).getTime() - new Date(selectedDate.startDate).getTime() > 2592000000}
                            className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 md:w-[200px] w-full disabled:opacity-50"
                        >
                            Filtrele
                        </button>
                    </div>
                </div>
            </form>
            {path === 'date' ? (
                <React.Fragment>
                    <div className="grid grid-cols-12 gap-5">
                        <div className="card shadow-none bg-gray-100 dark:bg-gray-800 px-4 py-6 col-span-12 lg:col-span-4 relative">
                            {loading && (
                                <div className="flex items-center justify-center h-full absolute inset-0">
                                    <div className="flex flex-col items-center">
                                        <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-emerald-400"></div>
                                    </div>
                                </div>
                            )}
                            <div className={loading ? 'opacity-0' : ''}>
                                <div className="flex justify-between items-center mb-4">
                                    <div className="flex items-center">
                                        <span className="bg-blue-500 w-2 h-2 rounded-full inline-block mr-2"></span>
                                        <p className="text-sm text-gray-600 dark:text-gray-400">Toplam Satış Tutarı</p>
                                    </div>
                                    <span className="text-slate-500 dark:text-slate-300 font-medium">{formatPrice(graphData.reduce((acc: number, item: { sales: number }) => acc + item.sales, 0))}</span>
                                </div>
                                <div className="flex justify-between items-center mb-4">
                                    <div className="flex items-center">
                                        <span className="bg-orange-300 w-2 h-2 rounded-full inline-block mr-2"></span>
                                        <p className="text-sm text-gray-600 dark:text-gray-400">Toplam Komisyon Kesintisi</p>
                                    </div>
                                    <span className="text-slate-500 dark:text-slate-300 font-medium">{formatPrice(graphData.reduce((acc: number, item: { commission: number }) => acc + item.commission, 0))}</span>
                                </div>
                                <div className="flex justify-between items-center mb-4">
                                    <div className="flex items-center">
                                        <span className="bg-purple-500 w-2 h-2 rounded-full inline-block mr-2"></span>
                                        <p className="text-sm text-gray-600 dark:text-gray-400">Toplam Kargo</p>
                                    </div>
                                    <span className="text-slate-500 dark:text-slate-300 font-medium">{formatPrice(graphData.reduce((acc: number, item: { shipping: number }) => acc + item.shipping, 0))}</span>
                                </div>
                                <div className="flex justify-between items-center mb-4">
                                    <div className="flex items-center">
                                        <span className="bg-pink-600 w-2 h-2 rounded-full inline-block mr-2"></span>
                                        <p className="text-sm text-gray-600 dark:text-gray-400">Diğer</p>
                                    </div>
                                    <span className="text-slate-500 dark:text-slate-300 font-medium">{formatPrice(graphData.reduce((acc: number, item: { other: number }) => acc + item.other, 0))}</span>
                                </div>

                                <hr className="mb-3 border-gray-300 dark:border-gray-700" />
                                <div className="flex justify-between items-center">
                                    <p className="text-sm text-gray-600 dark:text-gray-400 font-semibold">Toplam Ödenen Tutar</p>
                                    <span className="text-green-500 font-semibold">{formatPrice(graphData.reduce((acc: number, item: { sales: number }) => acc + item.sales, 0) + graphData.reduce((acc: number, item: { commission: number }) => acc + item.commission, 0) + graphData.reduce((acc: number, item: { shipping: number }) => acc + item.shipping, 0) + graphData.reduce((acc: number, item: { other: number }) => acc + item.other, 0))}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none bg-gray-100 dark:bg-gray-800 px-4 py-6 col-span-12 lg:col-span-8 relative">
                            {loading && (
                                <div className="flex items-center justify-center h-full absolute inset-0">
                                    <div className="flex flex-col items-center">
                                        <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-pink-400"></div>
                                    </div>
                                </div>
                            )}
                            <div className={loading ? 'opacity-0' : ''}>
                                <SalesCommissionChart graphData={graphData} />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12 relative">
                        {loading && (
                            <div className="flex items-center justify-center h-full absolute inset-0">
                                <div className="flex flex-col items-center">
                                    <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-gray-800"></div>
                                </div>
                            </div>
                        )}
                        <div className={`xl:col-span-12 ${loading ? 'opacity-0' : ''}`}>
                            {ProductListViewData.length > 0 ? (
                                <TableContainer
                                    isPagination={true}
                                    columns={columns || []}
                                    data={ProductListViewData}
                                    customPageSize={10}
                                    divclassName="-mx-5 -mb-5 overflow-x-auto"
                                    tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                                    theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                                    thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                                    tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                                    PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                                />
                            ) : (
                                <NotFound />
                            )}
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <NotFound />
            )}
        </React.Fragment>
    );
};

export default PastPage;
