import React from 'react';
import WelcomeWidget from './WelcomeWidget';
import OrderStatistics from './OrderStatistics';
import Widgets from './MediumWidgets';
import SalesRevenue from './SalesRevenue';
import Statistics from './Statistics';
import ProductsOrders from './ProductsOrders';
import Audience from './Audience';
import SalesMonth from './SalesMonth';
import TopSellingProducts from './TopSellingProducts';
import UpcomingInterview from './Announcements';

const Analytics = () => {

  return (
    <React.Fragment>
      <div className="mt-5 grid grid-cols-12 gap-x-5">
        <WelcomeWidget />
        <OrderStatistics />
        <Widgets />
        <SalesRevenue />
        <Statistics />
        <ProductsOrders />
        <Audience />
        <SalesMonth />
        <TopSellingProducts />
        <UpcomingInterview />
      </div>
    </React.Fragment>
  );
};

export default Analytics;
