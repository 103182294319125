import { X } from "lucide-react";
import { useFormik } from "formik";
import { object, string } from "yup";
import * as Yup from "yup";
import Modal from "../Modal";
import { sendRequestWithCache } from "helpers/request";
import { useAppSelector } from "../../../redux/hooks";

interface Props {
  passwordOnHide: () => void;
}

const scheme = object({
  password: Yup.string().trim().required("Şifre zorunludur."),

  new_password: string()
    .trim()
    .required("Şifre zorunludur.")
    .min(10, "Password en az 10 karakter olmalıdır")
    .max(20, "Şifre en fazla 20 karakter olmalıdı")
    .matches(/\d+/, "Password bir sayı içermelidir")
    .matches(/[!,?{}><%&$#£+-.]+/, "Password bir özel karakter içermelidir")
    .matches(/[a-z]/, "Password bir küçük harf içermelidir")
    .matches(/[A-Z]/, "Password bir büyük harf içermelidir"),
  new_password_confirmation: string()
    .trim()
    .required("Şifre zorunludur.")
    .min(10, "Password en az 10 karakter olmalıdır")
    .max(20, "Şifre en fazla 20 karakter olmalıdı")
    .matches(/\d+/, "Password bir sayı içermelidir")
    .matches(/[!,?{}><%&$#£+-.]+/, "Password bir özel karakter içermelidir")
    .matches(/[a-z]/, "Password bir küçük harf içermelidir")
    .matches(/[A-Z]/, "Password bir büyük harf içermelidir"),
});

const PasswordModal: React.FC<Props> = ({ passwordOnHide }) => {
  const { currentUser } = useAppSelector((state) => state.auth);

  const asd = async (values: any, actions: any) => {
    const formData = new FormData();
    formData.append("password", values.password);
    formData.append("new_password", values.new_password);
    formData.append(
      "new_password_confirmation",
      values.new_password_confirmation
    );
    try {
      const res = await sendRequestWithCache(
        "POST",
        `/user/change/password
    `,
        formData,
        {},
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );
      console.log(res);
      actions.setSubmitting(false);
      actions.resetForm();
      passwordOnHide();
    } catch (error) {
      actions.setSubmitting(false);
      console.log(error);
    }
  };

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    // enableReinitialize: true,

    initialValues: {
      password: "",
      new_password: "",
      new_password_confirmation: "",
    },
    validationSchema: scheme,
    onSubmit: (values: any, actions: any) => {
      console.log(actions);
      console.log("values", values);
      asd(values, actions);
      // const formData = new FormData(values);

      // try {
      //   const res = await sendRequestWithCache(
      //     "POST",
      //     `/user/change/password
      // `,
      //     formData,
      //     {},
      //     {
      //       headers: {
      //         Authorization: `Bearer ${currentUser.bearer_token}`,
      //       },
      //     }
      //   );
      //   console.log(res);
      //   actions.setSubmitting(false);
      //   actions.resetForm();
      // } catch (error) {
      //   actions.setSubmitting(false);
      //   console.log(error);
      // }
    },
  });
  const onSubmit = async (e: any) => {
    e.preventDefault();
    validation.handleSubmit();
  };
  return (
    <Modal
      show={true}
      onHide={passwordOnHide}
      id="accountModal"
      modal-center="true"
      className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
      dialogClassName="w-screen md:w-[35rem] bg-white shadow rounded-md dark:bg-zink-600"
    >
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] overflow-y-auto px-6 py-8 relative">
        <div className="absolute top-4 right-4">
          <button
            data-modal-close="accountModal"
            className="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"
            onClick={passwordOnHide}
          >
            <X className="size-5" />
          </button>
        </div>
        <form onSubmit={onSubmit}>
          <div className="flex flex-col justify-center gap-4">
            <div>
              <h4>Şifremi Güncelle</h4>
            </div>

            <div className="flex flex-col gap-4">
              <div>
                <label
                  htmlFor="password"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Mevcut Şifreniz
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Mevcut Şifrenizi Giriniz"
                  required
                  onChange={validation.handleChange}
                  value={validation.values.password || ""}
                />
                {/* {validation.touched.password &&
                  Boolean(validation.errors.password) && (
                    <div className="mt-1 text-sm text-red-500">
                      {validation.errors.password}
                    </div>
                  )} */}
              </div>
              <div>
                <label
                  htmlFor="new_password"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Yeni Şifreniz
                </label>
                <input
                  type="password"
                  name="new_password"
                  id="new_password"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Yeni Şifrenizi Giriniz"
                  onChange={validation.handleChange}
                  value={validation.values.new_password || ""}
                />
                {validation.touched.new_password &&
                  Boolean(validation.errors.new_password) && (
                    <div className="mt-1 text-sm text-red-500">
                      {validation.errors.new_password}
                    </div>
                  )}
              </div>
              <div>
                <label
                  htmlFor="new_password_confirmation"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Yeni Şifrenizi Doğrulayınız
                </label>
                <input
                  type="password"
                  name="new_password_confirmation"
                  id="new_password_confirmation"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Yeni Şifrenizi Giriniz"
                  onChange={validation.handleChange}
                  value={validation.values.new_password_confirmation || ""}
                />
                {validation.touched.new_password_confirmation &&
                  Boolean(validation.errors.new_password_confirmation) && (
                    <div className="mt-1 text-sm text-red-500">
                      {validation.errors.new_password_confirmation}
                    </div>
                  )}
              </div>
              <div className="flex flex-col gap-4">
                <h6>Yeni Şifreniz Şu Kriterlere Uymalıdır:</h6>
                <div>
                  <ul className="text-gray-400 ml-2">
                    <li>• En az 10 karakter olmalıdır.</li>
                    <li>• En az bir rakam içermelidir.</li>
                    <li>• En az bir büyük ve bir küçük harf içermelidir.</li>
                    <li>• En az bir özel karakter içermelidir.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="flex gap-2 justify-between">
              <button
                type="reset"
                className="text-slate-500 btn bg-slate-200 border-slate-200 hover:text-slate-600 hover:bg-slate-300 hover:border-slate-300 focus:text-slate-600 focus:bg-slate-300 focus:border-slate-300 focus:ring focus:ring-slate-100 active:text-slate-600 active:bg-slate-300 active:border-slate-300 active:ring active:ring-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 dark:border-zink-600 dark:hover:border-zink-500 dark:text-zink-200 dark:ring-zink-400/50 md:w-[200px] w-full"
                onClick={passwordOnHide}
              >
                <span className="align-middle">Vazgeç</span>
              </button>
              <button
                type="submit"
                disabled={validation.isSubmitting}
                className="flex justify-center items-center gap-2 text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 md:w-[200px] w-full"
              >
                {validation.isSubmitting && (
                  <div className="inline-block size-4 border-2 rounded-full animate-spin border-l-transparent border-white"></div>
                )}
                Güncelle
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default PasswordModal;
