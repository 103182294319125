import React, { useEffect, useState, Dispatch, SetStateAction, useCallback } from "react";
import { useAppSelector } from "../../../redux/hooks";
import ReactApexChart from "react-apexcharts";
import useChartColors from "Common/useChartColors";
import { sendRequestWithCache } from 'helpers/request';

interface SalesMonthChartProps {
    chartId: string;
    series: Array<{ type: string; name: string; data: Array<{ x: string; y: number }> }>;
}

interface AudienceChartProps {
    chartId: string;
    series: Array<{ name: string; data: Array<number> }>;
}

interface SalesRevenueOverviewChartProps {
    chartId: string;
    series: Array<{ name: string; data: Array<number> }>;
}

interface OrderStatisticsChartProps {
    chartId: string;
    setLoading: Dispatch<SetStateAction<boolean>>;
}

const formatPrice = (price: number) => {
     if (typeof price !== "number") return '0,00₺'
    return new Intl.NumberFormat("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price) + "₺";
};

const OrderStatisticsChart = ({ chartId, setLoading }: OrderStatisticsChartProps) => {
    const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
    const [dailyOrders, setDailyOrders] = useState([]);

    const fetchDailyOrders = useCallback(async () => {
        try {
            const { data } = await sendRequestWithCache("GET", "/user/dashboard/dailyOrders", null, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });
            setDailyOrders(data);
        } catch (error) {
            return error
        } finally {
            setLoading(false);
        }
    }, [bearer_token, setLoading]);

    useEffect(() => {
        fetchDailyOrders();
    }, [fetchDailyOrders]);

    const chartColors = useChartColors(chartId);

    const categories = dailyOrders.map((item: { hour: string }) => item.hour + ":00");

    const series = [{
        name: '',
        data: dailyOrders.map((item: { order_count: number }) => item.order_count)
    }];

    const options: any = {
        yaxis: {
            min: 0,
            forceNiceScale: true,
            labels: {
                formatter: function (val: number) {
                    return val;
                }
            }
        },
        chart: {
            type: 'line',
            height: 310,
            toolbar: {
                show: false,
            },
        },
        tooltip: {
            x: {
                show: false
            },
            y: {
                formatter: function (val: number) {
                    return val + " Sipariş";
                }
            }
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        colors: chartColors,
        dataLabels: {
            enabled: false
        },
        grid: {
            show: true,
            padding: {
                top: -20,
                right: 0,
            }
        },
        markers: {
            hover: {
                sizeOffset: 4
            }
        },
        xaxis: {
            categories: categories,
            labels: {
                formatter: function (val: string) {
                    return val;
                }
            }
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series}
                id={chartId}
                className="apex-charts"
                type='line'
                height={310}
            />
        </React.Fragment>
    );
};

const SalesRevenueOverviewChart = ({ chartId, series }: SalesRevenueOverviewChartProps) => {

    const chartColors = useChartColors(chartId);

    var options: any = {
        chart: {
            type: 'bar',
            height: 300,
            stacked: true,
            stackType: '100%',
            toolbar: {
                show: false,
            },
        },
        xaxis: {
            categories: ["Oca", "Şub", "Mar", "Nis", "May", "Haz", "Tem", "Ağu", "Eyl", "Eki", "Kas", "Ara"],
        },
        tooltip: {
            y: {
                formatter: function (val: number) {
                    return formatPrice(val);
                }
            }
        },
        grid: {
            show: true,
            padding: {
                top: -20,
                right: -10,
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
            },
        },
        colors: chartColors,
        fill: {
            opacity: 1
        },
        legend: {
            position: 'bottom',
        },
    };
    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series}
                data-chart-colors='["bg-custom-500", "bg-custom-400", "bg-custom-300"]'
                id={chartId}
                className="apex-charts"
                type='bar'
                height={300}
            />
        </React.Fragment>
    );
};

const AudienceChart = ({ chartId, series }: AudienceChartProps) => {

    const chartColors = useChartColors(chartId);

    const options: any = {
        chart: {
            type: 'bar',
            height: 390,
            stacked: true,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: true
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 6,
                columnWidth: '44%',
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 600
                        }
                    }
                }
            },
        },
        xaxis: {
            type: 'category',
            categories: [
                'Pzt',
                'Sal',
                'Çar',
                'Per',
                'Cum',
                'Cmt',
                'Paz',
            ],
        },
        colors: chartColors,
        legend: {
            position: 'top',
            horizontalAlign: 'right',
        },
        fill: {
            opacity: 1
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series}
                id={chartId}
                data-chart-colors='["bg-sky-500", "bg-orange-400", "bg-green-500", "bg-yellow-500"]'
                className="-mt-9 apex-charts"
                type='bar'
                height={390}
            />
        </React.Fragment>
    );
};

const SalesMonthChart = ({ chartId, series }: SalesMonthChartProps) => {

    const chartColors = useChartColors(chartId);

    const options: any = {
        chart: {
            height: 285,
            type: 'line',
            animations: {
                speed: 500
            },
            toolbar: {
                show: false,
            },
        },
        yaxis: {
            show: false
        },
        tooltip: {
            x: {
                show: false
            },
            y: {
                formatter: function (val: number) {
                    return formatPrice(val);
                }
            }
        },
        colors: chartColors,
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight',
            width: 2
        },
        grid: {
            show: true,
            padding: {
                top: -8,
                left: 10,
                right: 0,
            }
        },
        legend: {
            show: true,
            customLegendItems: ['Profit Median'],
            inverseOrder: true
        },
        markers: {
            hover: {
                sizeOffset: 5
            }
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series}
                data-chart-colors='["bg-orange-500", "bg-sky-500"]'
                id={chartId}
                className="apex-charts"
                type='line'
                height={285}
            />
        </React.Fragment>
    );
};


export {
    OrderStatisticsChart,
    SalesRevenueOverviewChart,
    AudienceChart,
    SalesMonthChart,
};