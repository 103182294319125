import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import AuthDropdown from "Common/Components/auth/AuthDropdown";
import AuthImage from "Common/Components/auth/AuthImage";
import AuthInfo from "Common/Components/auth/AuthInfo";
import AuthBackground from "Common/Components/auth/AuthBackground";
import authPost from "assets/images/auth-email.png";
import { sendRequestWithCache } from "helpers/request";
import { StateType } from "models/models";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { loginSuccess } from "../../redux/auth/authSlice";
import { getItem, setItem } from "helpers/localStorageControl";
import useUser from "../../hooks/useUser";

const Verification = () => {
  document.title = "Hesap Doğrulama | a-Commerce";
  const [state, setState] = useState<StateType>({
    error: false,
    loading: false,
  });
  const [otp, setOtp] = useState<string>("");
  const navigate = useNavigate();
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { userGet } = useUser();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setState({ error: false, loading: true });

    const formData = new FormData();
    formData.append("code", otp);
    formData.append("token", currentUser.control_token!);
    formData.append("user_id", currentUser.user_details.user_id.toString());
    try {
      const data = await sendRequestWithCache("POST", "/2fa", formData, {});
      userGet(data);
      const existingData = getItem("auth");
      existingData.data = {
        ...currentUser,
        bearer_token: data.bearerToken,
        control_token: undefined,
      };

      setItem("auth", existingData);

      dispatch(
        loginSuccess({
          ...currentUser,
          bearer_token: data.bearerToken,
          control_token: undefined,
        })
      );
      setState({ error: false, loading: false });
      navigate("/");
    } catch (err: any) {
      setState({ error: err.response?.data.message, loading: false });
      setOtp("");
    }
  };

  useEffect(() => {
    if (currentUser.two_fa_auth === true) return;
    else if (currentUser.two_fa_auth === false) {
      navigate("/");
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <React.Fragment>
      <div className="relative flex flex-col w-full overflow-hidden lg:flex-row to-custom-800 bg-gradient-to-r from-custom-900 dark:to-custom-900 dark:from-custom-950">
        <AuthBackground />
        <div className="min-h-[calc(100vh_-_theme('spacing.4')_*_2)] mx-3 lg:w-[40rem] xl:w-[45rem] 2xl:w-[50rem] shrink-0 px-10 py-14 flex items-center justify-center m-4 bg-white rounded z-10 relative dark:bg-zink-700 dark:text-zink-100 md:mx-4 ">
          <div className="flex gap-5 flex-col w-full h-full">
            <AuthDropdown />

            <div className="flex-1 flex gap-5 flex-col items-center justify-evenly">
              <AuthImage />

              <div className="text-center w-1/4 mx-auto">
                <img
                  src={authPost}
                  alt="verification-logo"
                  className="block w-full h-full"
                />
              </div>
            </div>

            <div className="my-auto flex-auto">
              <div className="mt-8 text-center">
                <h4 className="mb-2 text-custom-500 dark:text-custom-500">
                  E-Mail Doğrula
                </h4>
                <p className="mb-8 text-slate-500 dark:text-zink-200">
                  Lütfen{" "}
                  <b className="font-medium">
                    {currentUser.user_details?.masked_email}
                  </b>{" "}
                  adresine gönderilen
                  <b className="font-medium"> 6</b> haneli kodu giriniz.
                </p>
              </div>

              <form autoComplete="off" onSubmit={handleSubmit}>
                <div>
                  <OtpInput
                    containerStyle={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "12px",
                    }}
                    placeholder="000000"
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    // inputType="number"
                    inputStyle={{
                      width: "5rem",
                      height: "2rem",
                      border: "1px solid #D6D6D6",
                      borderRadius: "6px",
                    }}
                  />
                </div>
                {state.error && (
                  <div className="mt-2 text-sm text-red-500 flex justify-center">
                    <p>{state.error}</p>
                  </div>
                )}

                <div className="mt-5">
                  <button
                    type="submit"
                    className="w-full text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 disabled:opacity-50"
                    disabled={state.loading}
                  >
                    Onayla
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <AuthInfo />
      </div>
    </React.Fragment>
  );
};

export default Verification;
