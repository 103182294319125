import { Link } from "react-router-dom";
import Select from "react-select";
import { Check, MoreHorizontal, X, Eye, Repeat } from "lucide-react";
import { CalendarDays, Info } from "lucide-react";
import { useEffect, useState } from "react";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "Common/BreadCrumb";
import { Dropdown } from "Common/Components/Dropdown";
import { Nav } from "Common/Components/Tab/Nav";
import Tab from "Common/Components/Tab/Tab";
import AllRefund from "./route/AllRefund";
import CreatedDemand from "./route/CreatedDemand";
import Shipped from "./route/Shipped";
import Waiting from "./route/Waiting";
import Confirmed from "./route/Confirmed";
import Rejected from "./route/Rejected";
import Disputed from "./route/Disputed";
import Analysis from "./route/Analysis";
import { useAppSelector } from "../../redux/hooks";
import { sendRequestWithCache } from "helpers/request";
import NotFound from "pages/finance/NotFound";
import { EventFunc, SubmitFunc } from "models/models";
import { getItem } from "helpers/localStorageControl";

interface CurrentDataType {
  loading: boolean;
  data: [];
  error: boolean;
}
interface InputStateType {
  name: string;
  orderNumber: string;
  code: string;
  reason: any;
  startDate: string;
  endDate: string;
}

const RefundProcessing = () => {
  const pathname = window.location.pathname;
  const path = pathname.split("/")[2];
  const { currentUser } = useAppSelector((state) => state.auth);
  const [currentData, setCurrentData] = useState<CurrentDataType>({
    loading: false,
    data: getItem(path) || [],
    error: false,
  });
  const [inputState, setInputState] = useState<InputStateType>({
    name: "",
    orderNumber: "",
    code: "",
    reason: "",
    startDate: "",
    endDate: "",
  });
  const { loading, data, error } = currentData;

  const get = async (formData: any = null, search: boolean = false) => {
    setCurrentData({ data: [], loading: true, error: false });
    try {
      const data = await sendRequestWithCache(
        "POST",
        `/order/returns-orders/${path}`,
        formData,
        search ? {} : { cache: true, cacheName: path, cacheTime: 300 },
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );
      setCurrentData({ data, loading: false, error: false });
    } catch (error) {
      setCurrentData({
        data: [],
        loading: false,
        error: true,
      });
    }
  };

  const handleChange: EventFunc = (event) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };
  const dateFunc = (date: string, name: string) => {
    setInputState({ ...inputState, [name]: date });
  };

  useEffect(() => {
    get();
  }, [path]);

  const columns = [
    {
      header: "Sipariş Bilgileri",
      accessorKey: "product.product_code",
      enableColumnFilter: false,
      enableSorting: true,
      cell: (cell: any) => {
        return (
          <div className="flex items-center gap-2 w-full overflow-hidden">
            <div className=" flex items-center justify-center font-medium rounded shrink-0 text-slate-800 dark:text-zink-50 ">
              {cell.getValue() && (
                <div className="flex flex-col">
                  <p className="text-custom-500">{cell.getValue()}</p>
                  <div className="text-xs grid gap-2 w-full">
                    <p className="">Sipariş Tarihi: </p>
                    <p>
                      {moment(cell.row.original.created_at).format(
                        "DD/MM/YYYY HH:mm"
                      )}
                    </p>
                    <p>İade Talep Tarihi:</p>
                    <p>
                      {moment(cell.row.original.order.created_at).format(
                        "DD/MM/YYYY HH:mm"
                      )}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      header: "Alıcı",
      accessorKey: "customer.full_name",
      enableColumnFilter: false,
      enableSorting: true,
    },
    {
      header: "Adet",
      accessorKey: "product.stock",
      enableColumnFilter: false,
      enableSorting: true,
    },
    {
      header: "Ürün Bilgileri",
      accessorKey: "product.description",
      enableColumnFilter: false,
      enableSorting: true,
      cell: (cell: any) => {
        return (
          <div className="flex items-center gap-2 w-full overflow-hidden">
            <div className="h-16 flex items-center justify-center font-medium rounded shrink-0 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
              {cell.row.original.product.thumbnail && (
                <div className="h-16 w-16 rounded ">
                  <img
                    src={cell.row.original.product.thumbnail}
                    alt="pic"
                    className="h-full w-full rounded"
                  />
                </div>
              )}
            </div>
            <div className="grow w-[150px]">
              <h6
                className="mb-1 truncate text-wrap whitespace-nowrap line-clamp-2"
                title={cell.getValue()}
              >
                {/* <Link to="#!" className="name"> */}
                {cell.getValue()}
                {/* </Link> */}
              </h6>
              {/* <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.designation}
              </p> */}
            </div>
          </div>
        );
      },
    },
    {
      header: "Birim Fiyatı",
      accessorKey: "product.price",
      enableColumnFilter: false,
      enableSorting: true,
      cell: (cell: any) => <p>₺{cell.getValue()}</p>,
    },
    {
      header: "Kargo",
      accessorKey: "order.cargo_number",
      enableColumnFilter: false,
      enableSorting: true,
      cell: (cell: any) => {
        return (
          <div className="grid gap-2">
            <div className="mx-auto w-[75px]">
              <img
                src="https://www.araskargo.com.tr/assets/images/aras-logo.svg"
                alt=""
                className="w-full"
              />
            </div>
            <div className="text-center">
              <p className="text-gray-400 text-sm">Kargo Kodu</p>

              <p>{cell.getValue()}</p>
            </div>
            <div className="flex justify-center">
              <button
                type="button"
                className="bg-white text-slate-500 btn border-slate-500 hover:text-white hover:bg-slate-600 hover:border-slate-600 focus:text-white focus:bg-slate-600 focus:border-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:border-slate-600 active:ring active:ring-slate-100 dark:bg-zink-700 dark:hover:bg-slate-500 dark:ring-slate-400/20 dark:focus:bg-slate-500 text-xs"
              >
                Kargo Takip
              </button>
            </div>
          </div>
        );
      },
    },
    {
      header: "Fatura",
      accessorKey: "product.original_price",
      enableColumnFilter: false,
      enableSorting: true,
      cell: (cell: any) => {
        return (
          <div className="flex flex-col justify-center items-center gap-1">
            <p>Toplam Tutar</p>
            <p>₺{cell.getValue()}</p>
          </div>
        );
      },
    },
    {
      header: "İade Sebebi",
      accessorKey: "sebep",
      enableColumnFilter: false,
      enableSorting: true,
      cell: (cell: any) => {
        return (
          <div className="flex flex-col gap-1 max-w-[200px]">
            <p className="text-gray-400">Müşteri İade Sebebi:</p>
            <p
              className="truncate text-wrap whitespace-nowrap line-clamp-2"
              title={cell.row.original.return_reason}
            >
              {cell.row.original.return_reason}
            </p>

            {cell.row.original.return_note && (
              <>
                <p className="text-gray-400">Müşteri Notu:</p>
                <p
                  className="truncate text-wrap whitespace-nowrap line-clamp-2"
                  title={cell.row.original.return_note}
                >
                  {cell.row.original.return_note}
                </p>
              </>
            )}
          </div>
        );
      },
    },
    {
      header: "İşlemler",
      enableColumnFilter: false,
      enableSorting: true,
      cell: () => (
        <Dropdown className="relative">
          <Dropdown.Trigger
            className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
            id="usersAction1"
          >
            <MoreHorizontal className="size-3" />
          </Dropdown.Trigger>
          <Dropdown.Content
            placement="right-end"
            className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600"
            aria-labelledby="usersAction1"
          >
            {path === "action_required" && (
              <>
                <li>
                  <Link
                    className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                    to="/pages-account"
                  >
                    <Check className="inline-block size-4 ltr:mr-1 rtl:ml-1" />
                    <span className="align-middle">Onayla</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                    to="/pages-account"
                  >
                    <X className="inline-block size-4 ltr:mr-1 rtl:ml-1" />
                    <span className="align-middle">Ret Talebi</span>
                  </Link>
                </li>
              </>
            )}
            {(path === "all" ||
              path === "approved" ||
              path === "rejected" ||
              path === "analyzing" ||
              path === "disputed") && (
                <li>
                  <Link
                    className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                    to="/pages-account"
                  >
                    <Eye className="inline-block size-3 ltr:mr-1 rtl:ml-1" />
                    <span className="align-middle">Detaylı Bigli Gör</span>
                  </Link>
                </li>
              )}
            {path === "all" && (
              <li>
                <Link
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                  to="/pages-account"
                >
                  <Eye className="inline-block size-3 ltr:mr-1 rtl:ml-1" />
                  <span className="align-middle">
                    Mesafeli Satış Sözleşmesi
                  </span>
                </Link>
              </li>
            )}
            {(path === "requested" || path === "dispatched") && (
              <li>
                <Link
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                  to="/pages-account"
                >
                  <Repeat className="inline-block size-4 ltr:mr-1 rtl:ml-1" />
                  <span className="align-middle">İade Teslim Aldım</span>
                </Link>
              </li>
            )}
          </Dropdown.Content>
        </Dropdown>
      ),
    },
  ];

  const handleReset = () => {
    setInputState({
      ...inputState,
      name: "",
      orderNumber: "",
      code: "",
      reason: "",
      startDate: "",
      endDate: "",
    });
  };
  const handleSelect = (e: { label: string; value: string }) => {
    setInputState({ ...inputState, reason: e });
  };

  const handleSubmit: SubmitFunc = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (inputState.name.length > 0) {
      formData.append("customer_name", inputState.name);
    }
    if (inputState.orderNumber.length > 0) {
      formData.append("order_number", inputState.orderNumber);
    }
    if (inputState.code.length > 0) {
      formData.append("return_code", inputState.code);
    }
    if (inputState.reason?.label?.length > 0) {
      formData.append("return_reason", inputState.reason.label);
    }
    if (inputState.startDate) {
      formData.append("start_date", inputState.startDate);
    }
    if (inputState.endDate) {
      formData.append("end_date", inputState.endDate);
    }

    get(formData, true);
  };
  return (
    <div>
      <BreadCrumb title="İade İşlemleri" pageTitle="Siparişler & Kargo" />
      <div className="card">
        <div className="card-body flex flex-col gap-5">
          <Tab.Container defaultActiveKey={path}>
            <Nav className="flex flex-wrap w-full text-sm font-medium text-center border-b border-slate-200 dark:border-zink-500 nav-tabs">
              <Nav.Item eventKey="all" className="group">
                <Link
                  to="/refundprocessing/all"
                  data-tab-toggle
                  data-target="all"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  {/* <Home className="inline-block size-4 mr-1"></Home>{" "} */}

                  <span className="align-middle">Tüm İadeler</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="requested" className="group">
                <Link
                  to="/refundprocessing/requested"
                  data-tab-toggle
                  data-target="requested"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  {/* <User2 className="inline-block size-4 mr-1"></User2>{" "} */}
                  <span className="align-middle">Talep Oluşturulan</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="dispatched" className="group">
                <Link
                  to="/refundprocessing/dispatched"
                  data-tab-toggle
                  data-target="dispatched"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  {/* <Settings className="inline-block size-4 mr-1"></Settings>{" "} */}
                  <span className="align-middle">Kargoya Verilen</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="action_required" className="group">
                <Link
                  to="/refundprocessing/action_required"
                  data-tab-toggle
                  data-target="action_required"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  {/* <Settings className="inline-block size-4 mr-1"></Settings>{" "} */}
                  <span className="align-middle">Aksiyon Bekleyen</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="approved" className="group">
                <Link
                  to="/refundprocessing/approved"
                  data-tab-toggle
                  data-target="approved"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  {/* <Settings className="inline-block size-4 mr-1"></Settings>{" "} */}
                  <span className="align-middle">Onaylanan</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="rejected" className="group">
                <Link
                  to="/refundprocessing/rejected"
                  data-tab-toggle
                  data-target="rejected"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  {/* <Settings className="inline-block size-4 mr-1"></Settings>{" "} */}
                  <span className="align-middle">Rededilen</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="analyzing" className="group">
                <Link
                  to="/refundprocessing/analyzing"
                  data-tab-toggle
                  data-target="analyzing"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  {/* <Settings className="inline-block size-4 mr-1"></Settings>{" "} */}
                  <span className="align-middle">Analiz</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="disputed" className="group">
                <Link
                  to="/refundprocessing/disputed"
                  data-tab-toggle
                  data-target="disputed"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  {/* <Settings className="inline-block size-4 mr-1"></Settings>{" "} */}
                  <span className="align-middle">İhtilaflı</span>
                </Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
          <form
            action="#!"
            className="flex flex-col gap-5"
            onSubmit={handleSubmit}
          >
            <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 xl:grid-cols-4">
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Müşteri Adı
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Müşteri Adı"
                  onChange={handleChange}
                  value={inputState.name}
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="orderNumber"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Sipariş No
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <input
                  type="text"
                  id="orderNumber"
                  name="orderNumber"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Sipariş No"
                  onChange={handleChange}
                  value={inputState.orderNumber}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="code"
                  className="inline-block mb-2 text-base font-medium"
                >
                  İade Kodu
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <input
                  type="text"
                  id="code"
                  name="code"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="İade Kodu"
                  onChange={handleChange}
                  value={inputState.code}
                  disabled
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="reason"
                  className="inline-block mb-2 text-base font-medium"
                >
                  İade Sebebi
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <Select
                  className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  id="category"
                  name="category"
                  data-choices
                  // data-choices-multiple-groups="true"
                  // isMulti
                  placeholder="Kategori seçiniz"
                  options={[
                    { value: "0", label: "Kusurlu Ürün" },
                    { value: "1", label: "Yanlış Ürün" },
                    // { value: "2", label: "Beden Uymama" },
                    { value: "3", label: "Ürün Kalitesini Beğenmeme" },
                    { value: "4", label: "Ürün Modelini Beğenmeme" },
                  ]}
                  onChange={(event: any) => handleSelect(event)}
                  value={inputState.reason}
                  isSearchable
                  isClearable
                />
                {/* Ï
                <input
                  type="text"
                  id="reason"
                  name="reason"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="İade Sebebi"
                  value={inputState.reason}
                  onChange={handleChange}
                /> */}
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="startDate"
                  className="inline-flex items-center gap-1 mb-2 text-base font-medium"
                >
                  Talep Başlangıç Tarihi
                  <div className="relative group group/item">
                    <Info size={14} className="text-blue-400" />
                    <div className="absolute group/edit invisible group-hover/item:visible group-hover/item:opacity-100 left-0 bottom-full mb-2 w-48 p-2 text-xs text-white bg-black rounded-lg opacity-0 transition-opacity duration-200 text-justify">
                      Seçeceğiniz başlangıç ve bitiş tarihinin aralığı 30 günü
                      geçmemelidir.
                    </div>
                  </div>
                </label>
                <Flatpickr
                  options={{
                    dateFormat: "d M, Y",
                    defaultDate: moment().subtract(30, 'days').toDate(),
                    locale: {
                      weekdays: {
                        longhand: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
                        shorthand: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt']
                      },
                      months: {
                        longhand: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
                        shorthand: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara']
                      },
                    }
                  }}
                  placeholder="Talep Başlangıç Tarihi"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  onChange={(_: any, i: string) => dateFunc(i, "startDate")}
                />
                <CalendarDays className="absolute size-4 right-3 bottom-3 text-slate-500 dark:text-zink-200" />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="endDate"
                  className="inline-flex items-center gap-1 mb-2 text-base font-medium"
                >
                  Talep Bitiş Tarihi
                  <div className="relative group group/item">
                    <Info size={14} className="text-blue-400" />
                    <div className="absolute group/edit invisible group-hover/item:visible group-hover/item:opacity-100 left-0 bottom-full mb-2 w-48 p-2 text-xs text-white bg-black rounded-lg opacity-0 transition-opacity duration-200 text-justify">
                      Seçeceğiniz başlangıç ve bitiş tarihinin aralığı 30 günü
                      geçmemelidir.
                    </div>
                  </div>
                </label>
                <Flatpickr
                  options={{
                    dateFormat: "d M, Y",
                    minDate: inputState.startDate,
                    defaultDate: "today",
                    maxDate: "today",
                    locale: {
                      weekdays: {
                        longhand: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
                        shorthand: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt']
                      },
                      months: {
                        longhand: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
                        shorthand: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara']
                      },
                    }
                  }}
                  value={inputState.endDate}
                  placeholder="Talep Bitiş Tarihi"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  onChange={(_: any, i: string) => dateFunc(i, "endDate")}
                />
                <CalendarDays className="absolute size-4 right-3 bottom-3 text-slate-500 dark:text-zink-200" />
              </div>
            </div>
            <div className="flex justify-end gap-2">
              <button
                type="reset"
                className="text-slate-500 btn bg-slate-200 border-slate-200 hover:text-slate-600 hover:bg-slate-300 hover:border-slate-300 focus:text-slate-600 focus:bg-slate-300 focus:border-slate-300 focus:ring focus:ring-slate-100 active:text-slate-600 active:bg-slate-300 active:border-slate-300 active:ring active:ring-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 dark:border-zink-600 dark:hover:border-zink-500 dark:text-zink-200 dark:ring-zink-400/50 md:w-[172px] w-full"
                onClick={handleReset}
              >
                <span className="align-middle">Temizle</span>
              </button>
              <button
                type="submit"
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 md:w-[172px] w-full"
              >
                Filtrele
              </button>
            </div>
          </form>
        </div>
      </div>
      {loading && (
        <div className="flex flex-col items-center justify-center">
          <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-yellow-400"></div>
          <p>Yükleniyor...</p>
        </div>
      )}
      {!loading && (error || data.length === 0) && (
        <div className="noresult">
          <div className="py-6 text-center">
            <NotFound title="Üzgünüm! Sonuç bulunamadı." />
          </div>
        </div>
      )}

      {path === "all" && data?.length > 0 && !loading && !error && (
        <AllRefund data={data} columns={columns} />
      )}
      {path === "requested" && data.length > 0 && !loading && !error && (
        <CreatedDemand data={data} columns={columns} />
      )}
      {path === "approved" && data.length > 0 && !loading && !error && (
        <Confirmed data={data} columns={columns} />
      )}
      {path === "dispatched" && !loading && !error && data.length > 0 && (
        <Shipped data={data} columns={columns} />
      )}
      {path === "action_required" && !loading && !error && data.length > 0 && (
        <Waiting data={data} columns={columns} />
      )}
      {path === "rejected" && !loading && !error && data.length > 0 && (
        <Rejected data={data} columns={columns} />
      )}
      {path === "analyzing" && !loading && !error && data.length > 0 && (
        <Analysis data={data} columns={columns} />
      )}
      {path === "disputed" && !loading && !error && data.length > 0 && (
        <Disputed data={data} columns={columns} />
      )}
    </div>
  );
};

export default RefundProcessing;
