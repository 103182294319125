import { Link } from "react-router-dom";
import BreadCrumb from "Common/BreadCrumb";
import { Nav } from "Common/Components/Tab/Nav";
import Tab from "Common/Components/Tab/Tab";
import Profile from "./Profile";
import Company from "./Company";
import Documents from "./Documents";

const Account = () => {
  const pathname = window.location.pathname;
  const path = pathname.split("/")[2];
  return (
    <>
      <BreadCrumb title="Hesap Bilgileri" />
      <div className="card">
        <div className="card-body flex flex-col gap-5">
          <Tab.Container defaultActiveKey={path}>
            <Nav className="flex flex-wrap w-full text-sm font-medium text-center border-b border-slate-200 dark:border-zink-500 nav-tabs">
              <Nav.Item eventKey="profile" className="group">
                <Link
                  to="/account/profile"
                  data-tab-toggle
                  data-target="/account/profile"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  <span className="align-middle">Kullanıcı Bilgileri</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="company" className="group">
                <Link
                  to="/account/company"
                  data-tab-toggle
                  data-target="/account/company"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  <span className="align-middle">Firma Bilgileri</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="documents" className="group">
                <Link
                  to="/account/documents"
                  data-tab-toggle
                  data-target="/account/documents"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  <span className="align-middle">Sözleşme ve Belgeler</span>
                </Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>

          {path === "profile" && <Profile />}
          {path === "company" && <Company />}
          {path === "documents" && <Documents />}
        </div>
      </div>
    </>
  );
};

export default Account;
