import React, { useMemo, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { sendRequestWithCache } from 'helpers/request';
import BreadCrumb from 'Common/BreadCrumb'
import TableContainer from 'Common/TableContainer';
import { useAppSelector } from "../../redux/hooks";
import { ArrowLeft } from 'lucide-react';
import NotFound from "../../Common/Components/NotFound/NotFound";

interface ProductsType {
  commission: number;
  net_earnings: number;
  price: number;
  product_name: string;
  transaction_type: string;
  amount: number;
  netAmount: number;
}

interface FinanceDetailsType {
  data: {
    cart_id: number;
    order_number: string;
    payment_channel: string;
  }
  payment_date: string;
  estimated_payment_date: string;
}

const OrderDetail = () => {
  const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);
  const [financeDetails, setFinanceDetails] = useState<FinanceDetailsType | null>(null);
  const [products, setProducts] = useState<ProductsType[]>([]);
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        header: 'İşlem Tipi',
        accessorKey: 'processType',
        enableColumnFilter: false,
      },
      {
        header: 'Detay (Ürün & Açıklama)',
        accessorKey: 'detail',
        enableColumnFilter: false,
      },
      {
        header: 'Tutar',
        accessorKey: 'amount',
        enableColumnFilter: false,
      },
      {
        header: 'Komisyon',
        accessorKey: 'commission',
        enableColumnFilter: false,
      },
      {
        header: 'Net Tutar',
        accessorKey: 'netAmount',
        enableColumnFilter: false,
      }
    ],
    []
  );

  const fetchFinanceDetails = useCallback(async () => {
    setLoading(true);
    try {
      const response = await sendRequestWithCache("GET", `/finance/payment-details/${id}`, null, {}, {
        headers: {
          Authorization: `Bearer ${bearer_token}`,
        },
      });
      setFinanceDetails(response);
      setProducts(response.data.products.map((item: ProductsType) => ({
        processType: item.transaction_type,
        detail: item.product_name,
        amount: formatPrice(item.price),
        commission: formatPrice(item.commission),
        netAmount: formatPrice(item.net_earnings)
      })));
    } catch (error) {
      return error
    } finally {
      setLoading(false);
    }
  }, [bearer_token, id]);

  useEffect(() => {
    fetchFinanceDetails();
  }, [fetchFinanceDetails]);

  const formatPrice = (price: number) => {
     if (typeof price !== "number") return '0,00₺'
    return new Intl.NumberFormat("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price) + "₺";
  };

  const parsePrice = (priceString: number | string): number => {
    if (typeof priceString === 'string') {
      const numericString = priceString.replace(/[.,₺]/g, (match) => {
        if (match === ',') return '.';
        return '';
      });
      return parseFloat(numericString);
    }
    return priceString;
  };

  const footerContent = () => {
    const totalAmount = products.reduce((total, product) => total + parsePrice(product.amount), 0);
    const totalCommission = products.reduce((total, product) => total + parsePrice(product.commission), 0);
    const totalNetAmount = products.reduce((total, product) => total + parsePrice(product.netAmount), 0);

    return [
      'Toplam',
      `${products.length} ADET İŞLEM`,
      `${formatPrice(totalAmount)}`,
      `${formatPrice(totalCommission)}`,
      `${formatPrice(totalNetAmount)}`
    ];
  };

  return (
    <React.Fragment>
      <BreadCrumb title='Sipariş Detayı' pageTitle='Ödemeler' />
      <div className="card shadow-none">
        <div className='card-body flex gap-2 items-center'>
          <button onClick={() => {
            navigate('/finance/payments/past/order')
          }} className='btn bg-slate-100 text-white p-1.5 leading-3 rounded-md'>
            <ArrowLeft className='size-6 text-black rounded-md' />
          </button>
          {financeDetails ? (
            <div className='text-lg'>
              <b>{financeDetails.data.order_number}</b> No'lu Sipariş Detayı
            </div>
          ) : loading ? (
            <div className="flex flex-wrap items-center gap-5 px-3 py-2">
              <div className="inline-flex size-4 rounded-full opacity-75 animate-ping bg-slate-900 dark:bg-zink-300"></div>
              <div className="inline-flex size-4 rounded-full opacity-75 animate-ping bg-slate-900 dark:bg-zink-300"></div>
              <div className="inline-flex size-4 rounded-full opacity-75 animate-ping bg-slate-900 dark:bg-zink-300"></div>
            </div>
          ) : (
            <div className='text-lg'>
              Sipariş Detayı Bulunamadı
            </div>

          )}
        </div>
      </div>
      {/*  <div className="card shadow-none">
            <div className="card-body">
              <div className='flex items-center gap-4'>
                <h5 className='text-[16px] font-normal text-slate-500'>Ödeme Tarihleri:</h5>
                <button className='btn bg-blue-500 text-white py-1.5 leading-3'>Tümü</button>
                <button className='btn bg-gray-400 text-white py-1.5 leading-3'>02.05.2022</button>
              </div>
            </div> 
      </div> */}
      <div className="card shadow-none relative">
        {loading && (
          <div className="flex items-center justify-center h-full absolute inset-0">
            <div className="flex flex-col items-center">
              <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
            </div>
          </div>
        )}
        <div className="card-body">
          {/* <div className='mb-4'>
            <h3 className='inline-block text-xl'>02.05.2022</h3>
            <span className='text-xl'> Tarihli Türkiye Ödeme Detayı</span>
          </div> */}
          <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12 mb-2">
            <div className="xl:col-span-12">
              {products.length > 0 ? (
                <TableContainer
                  isPagination={false}
                  columns={columns || []}
                  data={products}
                  customPageSize={10}
                  divclassName="-mx-5 -mb-5 overflow-x-auto"
                  tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                  theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                  thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                  tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                  PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                  isTfoot
                  footerContent={footerContent()}
                />
              ) : (
                <NotFound className={`${loading ? 'opacity-0' : ''}`} />
              )}
            </div>
          </div>
          {/* <div className='card shadow-none bg-green-500 text-white mt-8 mb-1'>
            <div className="card-body">Bu işlemlerin 02.05.2022 tarihi itibariyle bakiyenizi etkilediği tutar:</div>
          </div> */}
        </div>
      </div>
    </React.Fragment >
  )
}

export default OrderDetail