import React, { useCallback, useEffect, useState } from 'react'
import { sendRequestWithCache } from 'helpers/request';
import { useAppSelector } from "../../redux/hooks";
import { Link } from 'react-router-dom';
import BreadCrumb from "Common/BreadCrumb";
import NetTurnover from './NetTurnover';
import NetOrderQuantity from './NetOrderQuantity';
import NetSalesQuantity from './NetSalesQuantity';
import { ShoppingCart, Package, PackageX, Truck, Wallet2, RefreshCcw, ChevronRight } from 'lucide-react';
import moment from 'moment';
import LoadingOverlay from 'Common/Components/LoadingOverlay';

function HomeReports() {
    const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
    const [loading, setLoading] = useState(true);

    const formatPrice = (price: number) => {
         if (typeof price !== "number") return '0,00₺'
        return new Intl.NumberFormat("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price) + "₺";
    };

    const formatDate = (date: string) => {
        const newDate = new Date(date);
        return newDate.toLocaleDateString('tr-TR', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' });
    }

    const [reportData, setReportData] = useState<{
        monthly_net_revenue_report: {
            revenue: number,
        }[],
        monthly_net_order_count_report: {
            orderCount: number,
        }[],
        monthly_net_sales_count_report: {
            net_sales_count: number,
        }[],
        product_performance: {
            product_id: number,
            title: string,
            image: string,
            description: string,
            quantity: number,
        }[],
        net_revenue: number,
        net_sales_count: number,
        sales_count: number,
        cancelled_order_count: number,
        returned_order_count: number,
        six_month_net_revenue: number,
        six_month_sales_count: number,
        last_updated: string,
    }>({
        monthly_net_revenue_report: [],
        monthly_net_order_count_report: [],
        monthly_net_sales_count_report: [],
        product_performance: [],
        net_revenue: 0,
        net_sales_count: 0,
        sales_count: 0,
        cancelled_order_count: 0,
        returned_order_count: 0,
        six_month_net_revenue: 0,
        six_month_sales_count: 0,
        last_updated: '',
    });


    const fetchReportData = useCallback(async () => {
        setLoading(true);

        const params = new URLSearchParams({
            start_date: moment().subtract(12, 'months').format('YYYY-MM-DD'),
            end_date: moment().format('YYYY-MM-DD'),
            seller_id: String(6),
        });

        try {
            const response = await sendRequestWithCache("POST", `/reports?${params.toString()}`, null, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });
            setReportData(response);
        } catch (error) {
            return error
        } finally {
            setLoading(false);
        }
    }, [bearer_token]);

    useEffect(() => {
        fetchReportData();
    }, [fetchReportData]);

    return (
        <React.Fragment>
            <BreadCrumb title='Anasayfa' pageTitle='Rapolarım' />
            <div className='card'>
                <div className="card-body flex md:flex-row flex-col items-center gap-5">
                    {
                        loading ? (
                            <div className="flex flex-wrap items-center gap-5 px-3 py-2">
                                <div className="inline-flex size-4 my-[-5.5px] rounded-full opacity-75 animate-ping bg-slate-900 dark:bg-zink-300"></div>
                                <div className="inline-flex size-4 my-[-5.5px] rounded-full opacity-75 animate-ping bg-slate-900 dark:bg-zink-300"></div>
                                <div className="inline-flex size-4 my-[-5.5px] rounded-full opacity-75 animate-ping bg-slate-900 dark:bg-zink-300"></div>
                            </div>
                        ) : (
                            <React.Fragment>
                                <div>
                                    <h5 className='inline-block text-base font-normal text-slate-500'>6 Aylık Net Ciro:</h5> {formatPrice(reportData.six_month_net_revenue)}
                                </div>
                                <div>
                                    <h5 className='inline-block text-base font-normal text-slate-500'>6 Aylık Net Sipariş:</h5> {reportData.six_month_sales_count}
                                </div>
                                <div className='md:ml-auto'>
                                    <h5 className='inline-block text-base font-normal text-slate-500'>Son Güncellenme:</h5> {formatDate(reportData.last_updated)}
                                </div>
                            </React.Fragment>
                        )
                    }
                </div>
            </div>
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-2">
                <div className="card relative">
                    {LoadingOverlay({ loading, color: 'bg-custom-500' })}
                    <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                        <div className='flex items-center justify-between mb-8'>
                            <h6 className="mb-4 text-15">Net Ciro</h6>
                            <button
                                type="button"
                                className="btn border-slate-200 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 mb-2"
                            >
                                Excel ile indir{" "}
                                <i className="align-baseline ltr:pr-1 rtl:pl-1 ri-download-2-line"></i>
                            </button>
                        </div>
                        <NetTurnover chartId="basicArea" monthlyNetRevenueReport={reportData.monthly_net_revenue_report} />
                    </div>
                </div>
                <div className='grid grid-cols-1 gap-x-5 xl:grid-cols-2'>
                    <div className="card relative">
                        {LoadingOverlay({ loading, color: 'bg-custom-500' })}
                        <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                            <div className="flex items-center mb-3">
                                <h6 className="grow text-15">Ürün Performansım</h6>
                            </div>
                            <ul className="flex flex-col gap-5">
                                {
                                    reportData.product_performance.map((product, index) => (
                                        <li key={index} className="flex items-center gap-3">
                                            <div className="flex items-center justify-center rounded-md bg-slate-100 dark:bg-zink-600 min-w-10 min-h-10 max-w-10 max-h-10 p-2">
                                                <img src={product.image} alt="" className="w-full h-full" />
                                            </div>
                                            <div className="overflow-hidden grow">
                                                <h6 className="truncate">{product.title}</h6>
                                                <p className='text-slate-500 dark:text-zink-200 truncate'>{product.description}</p>
                                            </div>
                                            <h6 className="shrink-0"><ShoppingCart className="inline-block size-4 align-middle text-slate-500 dark:text-zink-200 ltr:mr-1 rtl:ml-1"></ShoppingCart> {product.quantity}</h6>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='card relative p-4'>
                        {LoadingOverlay({ loading, color: 'bg-custom-500' })}
                        <div className={`${loading ? 'opacity-0' : ''}`}>
                            <h4 className='text-15 mb-2'>Satış & Operasyon Özeti</h4>
                            <Link to='#' className="block border-b-[1px] dark:border-slate-400 py-3">
                                <div className="flex items-center gap-4">
                                    <div className="flex items-center justify-center rounded-full size-12 bg-slate-200 text-slate-400 dark:bg-custom-500/20">
                                        <Wallet2 />
                                    </div>
                                    <div>
                                        <h5>{formatPrice(reportData.net_revenue)}</h5>
                                        <p className="text-slate-500 dark:text-zink-200 font-light">Net Ciro</p>
                                    </div>
                                    <ChevronRight className='ml-auto' />
                                </div>
                            </Link >
                            <Link to='#' className="block border-b-[1px] dark:border-slate-400 py-3">
                                <div className="flex items-center gap-4">
                                    <div className="flex items-center justify-center rounded-full size-12 bg-slate-200 text-slate-400 dark:bg-custom-500/20">
                                        <Package />
                                    </div>
                                    <div>
                                        <h5>{reportData.net_sales_count}</h5>
                                        <p className="text-slate-500 dark:text-zink-200 font-light">Net Sipariş Adedi</p>
                                    </div>
                                    <ChevronRight className='ml-auto' />
                                </div>
                            </Link>
                            <Link to='#' className="block border-b-[1px] dark:border-slate-400 py-3">
                                <div className="flex items-center gap-4">
                                    <div className="flex items-center justify-center rounded-full size-12 bg-slate-200 text-slate-400 dark:bg-custom-500/20">
                                        <Truck />
                                    </div>
                                    <div>
                                        <h5>{reportData.sales_count}</h5>
                                        <p className="text-slate-500 dark:text-zink-200 font-light">Net Satış Adedi</p>
                                    </div>
                                    <ChevronRight className='ml-auto' />
                                </div>
                            </Link>
                            <Link to='#' className="block border-b-[1px] dark:border-slate-400 py-3">
                                <div className="flex items-center gap-4">
                                    <div className="flex items-center justify-center rounded-full size-12 bg-slate-200 text-slate-400 dark:bg-custom-500/20">
                                        <PackageX />
                                    </div>
                                    <div>
                                        <h5>{reportData.cancelled_order_count}</h5>
                                        <p className="text-slate-500 dark:text-zink-200 font-light">Toplam İptal Adedi</p>
                                    </div>
                                    <ChevronRight className='ml-auto' />
                                </div>
                            </Link>
                            <Link to='#' className="block pt-3">
                                <div className="flex items-center gap-4">
                                    <div className="flex items-center justify-center rounded-full size-12 bg-slate-200 text-slate-400 dark:bg-custom-500/20">
                                        <RefreshCcw />
                                    </div>
                                    <div>
                                        <h5>{reportData.returned_order_count}</h5>
                                        <p className="text-slate-500 dark:text-zink-200 font-light">Toplam İade Adedi</p>
                                    </div>
                                    <ChevronRight className='ml-auto' />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="card relative">
                    {LoadingOverlay({ loading, color: 'bg-custom-500' })}
                    <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                        <div className='flex items-center justify-between mb-8'>
                            <h6 className="mb-4 text-15">Net Sipariş Adedi</h6>
                            <button
                                type="button"
                                className="btn border-slate-200 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 mb-2"
                            >
                                Excel ile indir{" "}
                                <i className="align-baseline ltr:pr-1 rtl:pl-1 ri-download-2-line"></i>
                            </button>
                        </div>
                        <NetOrderQuantity chartId="basicArea" monthlyNetOrderCountReport={reportData.monthly_net_order_count_report} />
                    </div>
                </div>
                <div className="card relative">
                    {LoadingOverlay({ loading, color: 'bg-custom-500' })}
                    <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                        <div className='flex items-center justify-between mb-8'>
                            <h6 className="mb-4 text-15">Net Satış Adedi</h6>
                            <button
                                type="button"
                                className="btn border-slate-200 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 mb-2"
                            >
                                Excel ile indir{" "}
                                <i className="align-baseline ltr:pr-1 rtl:pl-1 ri-download-2-line"></i>
                            </button>
                        </div>
                        <NetSalesQuantity chartId="basicArea" monthlyNetSalesCountReport={reportData.monthly_net_sales_count_report} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default HomeReports;