import React, { useRef, useState, FormEvent, useEffect } from "react";
import { Nav } from "../../Common/Components/Tab/Nav";
import Tab from "../../Common/Components/Tab/Tab";
import Select from "react-select";
import { CalendarDays, Info } from "lucide-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ProductReviewsDataByDate from "./ProductReviewsDataByDate";
import ProductReviewsDataByProduct from "./ProductReviewsDataByProduct";
import SellerReviewsData from "./SellerReviewsData";
import Flatpickr from 'react-flatpickr';
import BreadCrumb from "Common/BreadCrumb";
import moment from "moment";

const ProductReviews = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const formRef = useRef<any>(null);
    const pointsRef = useRef<any>(null);
    const categoriesRef = useRef<any>(null);
    const brandsRef = useRef<any>(null);
    const negativeEffectsRef = useRef<any>(null);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const listParam = urlParams.get('list');
    const [filter, setFilter] = useState({
        startDate: moment().subtract(30, 'days').format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        orderNumber: "",
        averageProductRating: "",
        productCode: "",
        productRating: [],
        productCategory: [],
        productBrand: [],
    });

    const [selectedOption, setSelectedOption] = useState<any>({
        points: [],
        categories: [],
        brands: [],
        negativeEffects: []
    });

    const handleFilter = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);
        const data = {
            startDate: formData.get("startDate") as string,
            endDate: formData.get("endDate") as string,
            orderNumber: formData.get("orderNumber") as string,
            averageProductRating: formData.get("averageProductRating") as string,
            productCode: formData.get("productCode") as string,
            productRating: selectedOption.points,
            productCategory: selectedOption.categories,
            productBrand: selectedOption.brands,
            negativeEffects: selectedOption.negativeEffects,
        };

        setFilter(data);
    }

    const handleFilterReset = () => {
        formRef.current?.startDate?._flatpickr.setDate(moment().subtract(30, 'days').toDate());
        formRef.current?.endDate?._flatpickr.setDate('today');
        pointsRef.current?.clearValue();
        categoriesRef.current?.clearValue();
        brandsRef.current?.clearValue();
        negativeEffectsRef.current?.clearValue();
    };

    useEffect(() => {
        handleFilterReset();
    }, [pathname]);

    useEffect(() => {
        if (pathname === "/reviews/product-reviews" && (!listParam || (listParam !== "date-based" && listParam !== "product-based"))) {
            navigate({ search: "?list=date-based" });
        }
    }, [listParam, navigate, pathname]);

    return (
        <React.Fragment>
            <BreadCrumb title={
                pathname === "/reviews/product-reviews" ? "Ürün Değerlendirmeleri" :
                    pathname === "/reviews/seller-reviews" ? "Satıcı Değerlendirmeleri" :
                        pathname === "/reviews/seller-rating" ? "Satıcı Puanı" : ""

            } pageTitle='Değerlendirmeler' />
            <div className="card">
                <div className="card-body flex flex-col gap-5">
                    <Tab.Container defaultActiveKey={pathname}>
                        <Nav className="flex flex-wrap w-full text-sm font-medium text-center border-b border-slate-200 dark:border-zink-500 nav-tabs">
                            <Nav.Item eventKey="/reviews/product-reviews" className="group">
                                <Link
                                    to="/reviews/product-reviews?list=date-based"
                                    data-tab-toggle
                                    className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                                >
                                    <span className="align-middle">Ürün Değerlendirmeleri</span>
                                </Link>
                            </Nav.Item>
                            <Nav.Item eventKey="/reviews/seller-reviews" className="group">
                                <Link
                                    to="/reviews/seller-reviews"
                                    data-tab-toggle
                                    className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                                >
                                    <span className="align-middle">Satıcı Değerlendirmeleri</span>
                                </Link>
                            </Nav.Item>
                            {/* <Nav.Item eventKey="/reviews/seller-rating" className="group">
                                <Link
                                    // to="/reviews/seller-rating"
                                    to="#"
                                    data-tab-toggle
                                    className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px] pointer-events-none cursor-not-allowed"
                                >
                                    <span className="align-middle">Satıcı Puanı</span>
                                </Link>
                            </Nav.Item> */}
                        </Nav>
                    </Tab.Container>
                    {pathname === "/reviews/product-reviews" && (
                        <div className="flex gap-2">
                            <button
                                onClick={() => navigate({ search: "?list=date-based" })}
                                className={`btn leading-none rounded-xl ${listParam === "date-based" ? "bg-custom-500 text-white hover:bg-custom-600" : "bg-white text-black dark:text-white hover:bg-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 border border-black dark:border-white"}`}
                            >
                                Tarih Bazlı
                            </button>
                            <button
                                onClick={() => navigate({ search: "?list=product-based" })}
                                className={`btn leading-none rounded-xl ${listParam === "product-based" ? "bg-custom-500 text-white hover:bg-custom-600" : "bg-white text-black dark:text-white hover:bg-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 border border-black dark:border-white"}`}
                            >
                                Ürün Bazlı
                            </button>
                        </div>
                    )}
                    <form onSubmit={handleFilter} ref={formRef}>
                        <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 xl:grid-cols-4 flex-1">
                            {(listParam === "date-based" || pathname === "/reviews/seller-reviews") ? (
                                <React.Fragment>
                                    <div className="relative mb-4">
                                        <label htmlFor="start-date" className="inline-flex items-center gap-1 mb-2 text-base font-medium">
                                            Başlangıç Tarihi
                                            <div className="relative group group/item">
                                                <Info size={14} className='text-blue-400' />
                                                <div className="absolute group/edit invisible group-hover/item:visible group-hover/item:opacity-100 left-0 bottom-full mb-2 w-48 p-2 text-xs text-white bg-black rounded-lg opacity-0 transition-opacity duration-200 text-justify">
                                                    Seçeceğiniz başlangıç ve bitiş tarihinin aralığı 30 günü geçmemelidir.
                                                </div>
                                            </div>
                                        </label>
                                        <Flatpickr
                                            id="start-date"
                                            name="startDate"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            options={{
                                                dateFormat: "Y-m-d",
                                                defaultDate: moment().subtract(30, 'days').toDate(),
                                                enableTime: false,
                                                locale: {
                                                    weekdays: {
                                                        longhand: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
                                                        shorthand: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt']
                                                    },
                                                    months: {
                                                        longhand: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
                                                        shorthand: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara']
                                                    },
                                                }
                                            }}
                                            placeholder='Başlangıç tarihini seçiniz'
                                            lang='tr'
                                        />
                                        <CalendarDays className="absolute size-4 right-3 bottom-3 text-slate-500 dark:text-zink-200" />
                                    </div>
                                    <div className="relative mb-4">
                                        <label htmlFor="end-date" className="inline-flex items-center gap-1 mb-2 text-base font-medium">
                                            Bitiş Tarihi
                                            <div className="relative group group/item">
                                                <Info size={14} className='text-blue-400' />
                                                <div className="absolute group/edit invisible group-hover/item:visible group-hover/item:opacity-100 left-0 bottom-full mb-2 w-48 p-2 text-xs text-white bg-black rounded-lg opacity-0 transition-opacity duration-200 text-justify">
                                                    Seçeceğiniz başlangıç ve bitiş tarihinin aralığı 30 günü geçmemelidir.
                                                </div>
                                            </div>
                                        </label>
                                        <Flatpickr
                                            id="end-date"
                                            name="endDate"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            options={{
                                                dateFormat: "Y-m-d",
                                                defaultDate: "today",
                                                maxDate: "today",
                                                enableTime: false,
                                                locale: {
                                                    weekdays: {
                                                        longhand: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
                                                        shorthand: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt']
                                                    },
                                                    months: {
                                                        longhand: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
                                                        shorthand: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara']
                                                    },
                                                },
                                            }}
                                            placeholder='İptal bitiş tarihini seçiniz'
                                        />
                                        <CalendarDays className="absolute size-4 right-3 bottom-3 text-slate-500 dark:text-zink-200" />
                                    </div>
                                </React.Fragment>
                            ) : listParam === "product-based" && (
                                <React.Fragment>
                                    <div className="mb-4">
                                        <label
                                            htmlFor="order-number"
                                            className="inline-block mb-2 text-base font-medium"
                                        >
                                            Sipariş Numarası
                                        </label>
                                        <input
                                            type="text"
                                            id="order-number"
                                            name="orderNumber"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            placeholder="Sipariş numarasını giriniz"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label
                                            htmlFor="average-product-rating"
                                            className="inline-block mb-2 text-base font-medium"
                                        >
                                            Ortalama Ürün Puanı
                                        </label>
                                        <Select
                                            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            options={[
                                                { value: "1", label: "1" },
                                                { value: "2", label: "2" },
                                                { value: "3", label: "3" },
                                            ]}
                                            placeholder="Ortalama puanı seçiniz"
                                            isSearchable={false}
                                            name="averageProductRating"
                                            id="averageProductRating"
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                            <div className="mb-4">
                                <label
                                    htmlFor="product-code"
                                    className="inline-block mb-2 text-base font-medium"
                                >
                                    Ürün Kodu
                                </label>
                                <input
                                    type="text"
                                    id="product-code"
                                    name="productCode"
                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    placeholder="Ürün kodunu giriniz"
                                />
                            </div>
                            <div className="mb-4">
                                <label
                                    htmlFor="product-rating"
                                    className="inline-block mb-2 text-base font-medium"
                                >
                                    Puanlar
                                </label>
                                <Select
                                    className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    isMulti
                                    options={[
                                        { value: "1", label: "1" },
                                        { value: "2", label: "2" },
                                        { value: "3", label: "3" },
                                        { value: "4", label: "4" },
                                        { value: "5", label: "5" },
                                    ]}
                                    ref={pointsRef}
                                    onChange={(event) => {
                                        setSelectedOption({
                                            ...selectedOption,
                                            points: event.map((item: any) => item.value)
                                        })
                                    }}
                                    placeholder="Puanını seçiniz"
                                    isSearchable={false}
                                    name="productRating"
                                    id="productRating"
                                />

                            </div>
                            {
                                pathname === "/reviews/seller-reviews" && (
                                    <div className="mb-4">
                                        <label
                                            htmlFor="negative-effects"
                                            className="inline-block mb-2 text-base font-medium"
                                        >
                                            Olumsuz Etkiler
                                        </label>
                                        <Select
                                            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            isMulti
                                            options={[
                                                { value: "1", label: "Özensiz paketleme" },
                                                { value: "2", label: "Eksik veya hatalı ürün" },
                                                { value: "3", label: "Fatura eksik" },

                                            ]}
                                            ref={negativeEffectsRef}
                                            onChange={(event) => {
                                                setSelectedOption({
                                                    ...selectedOption,
                                                    categories: event.map((item: any) => item.value)
                                                })
                                            }}
                                            placeholder="Kategoriyi Seçiniz"
                                            isSearchable
                                            name="negativeEffects"
                                            id="negativeEffects"
                                        />
                                    </div>
                                )
                            }
                            {(listParam === "date-based" || pathname === "/reviews/seller-reviews") && (
                                <React.Fragment>
                                    <div className="mb-4">
                                        <label
                                            htmlFor="product-category"
                                            className="inline-block mb-2 text-base font-medium"
                                        >
                                            Kategoriler
                                        </label>
                                        <Select
                                            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            isMulti
                                            options={[
                                                { value: "1", label: "Elektronik" },
                                                { value: "2", label: "Giyim" },
                                                { value: "3", label: "Spor" },
                                                { value: "4", label: "Kozmetik" },
                                                { value: "5", label: "Kitap" },
                                            ]}
                                            ref={categoriesRef}
                                            onChange={(event) => {
                                                setSelectedOption({
                                                    ...selectedOption,
                                                    categories: event.map((item: any) => item.value)
                                                })
                                            }}
                                            placeholder="Kategoriyi seçiniz"
                                            isSearchable
                                            name="productCategory"
                                            id="productCategory"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label
                                            htmlFor="product-brand"
                                            className="inline-block mb-2 text-base font-medium"
                                        >
                                            Markalar
                                        </label>
                                        <Select
                                            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            isMulti
                                            options={[
                                                { value: "1", label: "Apple" },
                                                { value: "2", label: "Samsung" },
                                                { value: "3", label: "Nike" },
                                                { value: "4", label: "Adidas" },
                                                { value: "5", label: "Loreal" },
                                            ]}
                                            ref={brandsRef}
                                            onChange={(event) => {
                                                setSelectedOption({
                                                    ...selectedOption,
                                                    brands: event.map((item: any) => item.value)
                                                })
                                            }}
                                            placeholder="Markayı seçiniz"
                                            isSearchable
                                            name="productBrand"
                                            id="productBrand"
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                            <div className="grid-item flex items-end gap-2 mb-4 xl:col-start-4 xl:col-end-5">
                                <button
                                    type="button"
                                    onClick={handleFilterReset}
                                    className="flex-1 text-slate-500 btn bg-slate-200 border-slate-200 hover:text-slate-600 hover:bg-slate-300 hover:border-slate-300 focus:text-slate-600 focus:bg-slate-300 focus:border-slate-300 focus:ring focus:ring-slate-100 active:text-slate-600 active:bg-slate-300 active:border-slate-300 active:ring active:ring-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 dark:border-zink-600 dark:hover:border-zink-500 dark:text-zink-200 dark:ring-zink-400/50 md:w-[200px] w-full"
                                >
                                    <span className="align-middle">Temizle</span>
                                </button>
                                <button
                                    type="submit"
                                    className="flex-1 text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 md:w-[200px] w-full"
                                >
                                    Filtrele
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {
                pathname === "/reviews/product-reviews" ? (
                    listParam === "date-based" ? (
                        <ProductReviewsDataByDate filter={filter} />
                    ) : listParam === "product-based" ? (
                        <ProductReviewsDataByProduct filter={filter} />
                    ) : null
                ) : pathname === "/reviews/seller-reviews" ? (
                    <SellerReviewsData />
                ) : null
            }
        </React.Fragment >
    );
};

export default ProductReviews;
