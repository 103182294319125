// dashboard
import Ecommerce from "pages/Dashboards/Ecommerce";
import UserProfile from "pages/Authentication/UserProfile";
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/LogOut";
import Verification from "pages/Authentication/Verification";
import Product from "pages/product/Product";
import AddProduct from "pages/product/addProduct/AddProduct";
import Sales from "pages/Sales";
import CanceledSales from "pages/CanceledSales";
import SalesDetail from "pages/Sales/SalesDetail";
import Questions from "pages/questions/Questions";
import RefundProcessing from "pages/refundProcessing/RefundProcessing";
import Finance from "pages/finance/Finance";
import Finans from "pages/invoiceListing/InvoiceListing";
import OrderDetail from "pages/finance/OrderDetail";
import ActiveProduct from "pages/product/route/ActiveProduct";
import WaitingProduct from "pages/product/route/WaitingProduct";
import InActiveproduct from "pages/product/route/InActiveproduct";
import AllProduct from "pages/product/route/AllProduct";
import ProductReviews from "../pages/reviews/ProductReviews";
import HomeReports from "../pages/reports/HomeReports";
import SalesReports from "../pages/reports/SalesReports";
import CanceledReports from "../pages/reports/CanceledReports";

import Account from "pages/account/Account";

import ReturnReports from "../pages/reports/ReturnReports";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>; // Use React.ComponentType to specify the type of the component
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  // Dashboard
  { path: "/", component: Ecommerce },
  { path: "/product/*", component: Product },

  // Product

  { path: "/product/addproduct", component: AddProduct },

  { path: "/addproduct/allProducts", component: AllProduct },
  { path: "/addproduct/activeProducts", component: ActiveProduct },
  { path: "/addproduct/waitingProducts", component: WaitingProduct },
  { path: "/addproduct/inactiveProducts", component: InActiveproduct },

  // Questions
  { path: "/questions", component: Questions },

  // Refund Processing
  { path: "/refundprocessing/all", component: RefundProcessing },
  { path: "/refundprocessing/requested", component: RefundProcessing },
  { path: "/refundprocessing/approved", component: RefundProcessing },
  { path: "/refundprocessing/dispatched", component: RefundProcessing },
  { path: "/refundprocessing/action_required", component: RefundProcessing },
  { path: "/refundprocessing/rejected", component: RefundProcessing },
  { path: "/refundprocessing/analyzing", component: RefundProcessing },
  { path: "/refundprocessing/disputed", component: RefundProcessing },

  // profile
  { path: "/user-profile", component: UserProfile },

  // order
  { path: "/shipping-phase/all-sales", component: Sales },
  { path: "/shipping-phase/new-sales", component: Sales },
  { path: "/shipping-phase/processed-sales", component: Sales },
  { path: "/shipping-phase/transported-sales", component: Sales },
  { path: "/shipping-phase/delivered-sales", component: Sales },
  // { path: "/resend-sales", component: Sales },
  // { path: "/pending-sales", component: Sales },
  { path: "/sales-detail/:id", component: SalesDetail },

  // canceled orders
  { path: "/canceled-sales/all-canceled-sales", component: CanceledSales },
  { path: "/canceled-sales/customer-canceled", component: CanceledSales },
  { path: "/canceled-sales/i-canceled", component: CanceledSales },
  { path: "/canceled-sales/a-commerce-canceled", component: CanceledSales },

  // finance
  { path: "/finance/payments/:path", component: Finance },
  { path: "/finance/payments/past/:path", component: Finance },
  { path: "/finance/order-detail/:id", component: OrderDetail },
  { path: "/finance/invoiceListing", component: Finans },

  // reviews
  { path: "/reviews/product-reviews", component: ProductReviews },
  { path: "/reviews/seller-reviews", component: ProductReviews },

  // reports
  { path: "/reports", component: HomeReports },
  { path: "/reports/sales-reports", component: SalesReports },
  { path: "/reports/canceled-reports", component: CanceledReports },


  // account
  { path: "/account/profile", component: Account },
  { path: "/account/company", component: Account },
  { path: "/account/documents", component: Account },

  { path: "/reports/return-reports", component: ReturnReports },

];

const publicRoutes = [
  // authentication
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/verification", component: Verification },
];

export { authProtectedRoutes, publicRoutes };
