import React, { useState } from "react";
import Modal from "../Modal";
import { X } from "lucide-react";
import { SubmitFunc } from "models/models";

interface PropsType {
  modal: {
    title: string;
    isOpen: boolean;
    description: string;
    info: string;
    modalTitle: string;
    scope: string;
  };
  onHide: () => void;
  companyGet: any;
}

const CompanyModal: React.FC<PropsType> = ({ modal, onHide, companyGet }) => {
  const [loading, setloading] = useState(false);
  const handleSubmit: SubmitFunc = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    companyGet(modal.scope, formData, setloading);
    e.currentTarget.reset();
  };
  return (
    <Modal
      show={modal.isOpen}
      onHide={onHide}
      id="companyModal"
      modal-center="true"
      className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
      dialogClassName="w-screen md:w-[35rem] bg-white shadow rounded-md dark:bg-zink-600"
    >
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] overflow-y-auto px-6 py-8 relative">
        <div className="absolute top-4 right-4">
          <button
            data-modal-close="companyModal"
            className="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"
            onClick={onHide}
          >
            <X className="size-5" />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col justify-center gap-4">
            <div>
              <h4>{`${modal.modalTitle} Güncelle`}</h4>
            </div>
            <div>
              <p className="text-gray-400">{modal.info}</p>
            </div>
            <div>
              <label
                htmlFor={modal.scope}
                className="inline-block mb-2 text-base font-medium"
              >
                {modal.title}
              </label>
              <input
                type="text"
                name={modal.scope}
                id={modal.scope}
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder={modal.title}
                required
                // onChange={handleChange}
                // value={state.stock}
              />
            </div>
            <div>
              <p className="text-gray-400">{modal.description}</p>
            </div>

            <div className="flex gap-2 justify-between">
              <button
                type="reset"
                onClick={onHide}
                className="text-slate-500 btn bg-slate-200 border-slate-200 hover:text-slate-600 hover:bg-slate-300 hover:border-slate-300 focus:text-slate-600 focus:bg-slate-300 focus:border-slate-300 focus:ring focus:ring-slate-100 active:text-slate-600 active:bg-slate-300 active:border-slate-300 active:ring active:ring-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 dark:border-zink-600 dark:hover:border-zink-500 dark:text-zink-200 dark:ring-zink-400/50 md:w-[200px] w-full"
              >
                <span className="align-middle">Vazgeç</span>
              </button>
              <button
                type="submit"
                className="flex justify-center items-center gap-2 text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 md:w-[200px] w-full"
              >
                {loading && (
                  <div className="inline-block size-4 border-2 rounded-full animate-spin border-l-transparent border-white"></div>
                )}
                <span>Güncelle</span>
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CompanyModal;
