import React, { useMemo } from "react";
import { ToastContainer } from "react-toastify";

// Images
import TableContainer from "Common/TableContainer";
import { Link } from "react-router-dom";
import NotFound from "../../Common/Components/NotFound/NotFound";

interface ProductListViewData {
  listOfProducts: {
    orderId: string;
    orderDate: string;
    receiver: string;
    img: string;
    productInfo: string;
    // designation: string;
    unitPrice: string;
    quantity: string;
    cancellationDate: string;
    cancellationReason: string;
    whoCancelled: string;
    slug: string;
  }[] | Object[];
  loading?: boolean;
};

const CancellationData = ({ listOfProducts, loading }: ProductListViewData) => {

  const formatDate = (date: string) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString("tr-TR", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  const formatPrice = (price: number) => {
     if (typeof price !== "number") return '0,00₺'
    return new Intl.NumberFormat("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price) + "₺";
  };

  const columns = useMemo(
    () => [
      {
        header: (
          <div className="flex items-center h-full">
            <input
              id="CheckboxAll"
              className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
              type="checkbox"
            />
          </div>
        ),
        enableSorting: false,
        id: "checkAll",
        cell: (cell: any) => {
          return (
            <div className="flex items-center h-full">
              <input
                id="Checkbox1"
                className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
                type="checkbox"
              />
            </div>
          );
        },
      },
      {
        header: 'Sipariş Bilgileri',
        accessorKey: 'orderId',
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2 w-full overflow-hidden">
            <div className="grow">
              <h6 className="mb-1 text-blue-400 font-normal">
                {cell.getValue()}
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                Sipariş Tarihi:
              </p>
              <p className="text-slate-500 dark:text-zink-200">
                {formatDate(cell.row.original.orderDate)}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: 'Alıcı',
        accessorKey: 'receiver',
        enableColumnFilter: false,
      },
      {
        header: 'Adet',
        accessorKey: 'quantity',
        enableColumnFilter: false,
      },
      {
        header: 'Ürün Bilgileri',
        accessorKey: 'productInfo',
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-4">
            <div className="h-32 w-32 p-2">
              <img
                src={cell.row.original.img}
                alt=""
                className="h-full w-full object-contain rounded"
              />
            </div>
            <div className="flex-1">
              <h6 className="mb-1">
                <Link to={cell.row.original.slug} className="name text-wrap line-clamp-2">
                  {cell.getValue()}
                </Link>
              </h6>
              {/* <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.designation}
              </p> */}
            </div>
          </div>
        ),
      },
      {
        header: 'Birim Fiyat',
        accessorKey: 'unitPrice',
        enableColumnFilter: false,
        cell: (cell: any) => (
          formatPrice(cell.getValue())
        ),
      },
      {
        header: 'İptal Tarihi',
        accessorKey: 'cancellationDate',
        enableColumnFilter: false,
        cell: (cell: any) => (
          formatDate(cell.getValue())
        ),
      },
      {
        header: 'İptal Nedeni',
        accessorKey: 'cancellationReason',
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div>
            <h6 className="mb-1 text-nowrap">{cell.row.original.whoCancelled}:</h6>
            <p className="text-slate-500 dark:text-zink-200 text-nowrap">{cell.getValue()}</p>
          </div>
        ),
      }
    ],
    []
  );

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card relative">
            {loading && (
              <div className="flex items-center justify-center h-full absolute inset-0">
                <div className="flex flex-col items-center">
                  <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                </div>
              </div>
            )}
            <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
              {listOfProducts.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns}
                  data={listOfProducts}
                  customPageSize={10}
                  divclassName="-mx-5 -mb-5 overflow-x-auto"
                  tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                  theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                  thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                  tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                  PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                  isSelect
                  title="İptal Edilenler"
                  downloadExcel
                />
              ) : (
                <NotFound />
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
};

export default CancellationData;
