import React from "react";
import CountUp from 'react-countup';
import { Boxes, PackagePlus, Loader, Truck, PackageCheck, PackageX } from 'lucide-react';
import OrdersOverviewChart from "./charts";

import { ToastContainer } from "react-toastify";

const SalesChart = () => {

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} limit={1} />
            <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 2xl:grid-cols-12">
                <div className="2xl:col-span-2 2xl:row-span-1">
                    <div className="card">
                        <div className="flex items-center gap-3 card-body">
                            <div className="flex items-center justify-center size-12 rounded-md text-15 bg-custom-50 text-custom-500 dark:bg-custom-500/20 shrink-0"><Boxes /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={15876} separator="," className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">Toplam Sipariş</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="2xl:col-span-2 2xl:row-span-1">
                    <div className="card">
                        <div className="flex items-center gap-3 card-body">
                            <div className="flex items-center justify-center size-12 rounded-md text-15 bg-sky-50 text-sky-500 dark:bg-sky-500/20 shrink-0"><PackagePlus /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={3874} separator="," className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">Yeni Sipariş</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="order-last md:col-span-2 2xl:col-span-8 2xl:row-span-3 card 2xl:order-none">
                    <div className="card-body">
                        <h6 className="mb-4 text-gray-800 text-15 dark:text-zink-50">Siparişlere Genel Bakış</h6>
                        <OrdersOverviewChart id="ordersOverview" />
                    </div>
                </div>
                <div className="2xl:col-span-2 2xl:row-span-1">
                    <div className="card">
                        <div className="flex items-center gap-3 card-body">
                            <div className="flex items-center justify-center size-12 text-yellow-500 rounded-md text-15 bg-yellow-50 dark:bg-yellow-500/20 shrink-0"><Loader /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={1548} separator="," className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">Kargo Bekleyenler</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="2xl:col-span-2 2xl:row-span-1">
                    <div className="card">
                        <div className="flex items-center gap-3 card-body">
                            <div className="flex items-center justify-center size-12 text-purple-500 rounded-md text-15 bg-purple-50 dark:bg-purple-500/20 shrink-0"><Truck /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={9543} separator="," className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">Kargoya Verilen</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="2xl:col-span-2 2xl:row-span-1">
                    <div className="card">
                        <div className="flex items-center gap-3 card-body">
                            <div className="flex items-center justify-center size-12 text-green-500 rounded-md text-15 bg-green-50 dark:bg-green-500/20 shrink-0"><PackageCheck /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={30914} separator="," className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">Teslim Edilen</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="2xl:col-span-2 2xl:row-span-1">
                    <div className="card">
                        <div className="flex items-center gap-3 card-body">
                            <div className="flex items-center justify-center size-12 text-red-500 rounded-md text-15 bg-red-50 dark:bg-red-500/20 shrink-0"><PackageX /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={3863} separator="," className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">İptal Edilen</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SalesChart;