import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface SalesCommissionChartProps {
    graphData: {
        date: string;
        sales: number;
        commission: number;
        shipping: number;
        advertising: number;
    }[];
}

const SalesCommissionChart = ({ graphData }: SalesCommissionChartProps) => {
    const chartOptions: ApexOptions = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: false,
            toolbar: {
                show: false
            }
        },
        xaxis: {
            categories: graphData.map(item => item.date),
            labels: {
                style: {
                    fontSize: '12px'
                }
            }
        },
        yaxis: {
            title: {
                text: 'TL',
                style: {
                    fontSize: '12px'
                }
            }
        },

        stroke: {
            width: 1,
        },

        colors: ['#007BFF', '#FFC107', '#6F42C1', '#DC3545'],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '80%',
            },
        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        }
    };

    return (
        <ReactApexChart
            options={chartOptions}
            series={[
                {
                    name: 'Satış Tutarı',
                    data: graphData.map(item => item.sales)
                },
                {
                    name: 'Komisyon Kesintisi',
                    data: graphData.map(item => item.commission)
                },
                {
                    name: 'Kargo Kesintisi',
                    data: graphData.map(item => item.shipping)
                },
                {
                    name: 'Reklam Harcaması',
                    data: graphData.map(item => item.advertising)
                }
            ]}
            type="bar"
            height={167}
        />
    );
};

export default SalesCommissionChart;
