import React, { useMemo } from "react";
import { ToastContainer } from "react-toastify";
import { Eye, FileEdit, MoreHorizontal } from "lucide-react";
import TableContainer from "Common/TableContainer";
import { Dropdown } from "Common/Components/Dropdown";
import { ProductProps } from "../Product";
import defaultImage from "assets/images/faq.png";

const ActiveProduct: React.FC<ProductProps> = ({ currentData, modalOpen }) => {
  const { tableData } = currentData;

  const columns = useMemo(
    () => [
      {
        header: (
          <div className="flex items-center h-full">
            <input
              id="CheckboxAll"
              className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
              type="checkbox"
            />
          </div>
        ),
        enableSorting: false,
        id: "checkAll",
        cell: (cell: any) => {
          return (
            <div className="flex items-center h-full">
              <input
                id="Checkbox1"
                className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
                type="checkbox"
              />
            </div>
          );
        },
      },
      {
        header: "Ürün Bilgisi",
        accessorKey: "title",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2 w-full overflow-hidden">
            <div className="h-16 w-16 flex items-center justify-center font-medium rounded shrink-0 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
              {cell.row.original.thumbnail ? (
                <img
                  src={cell.row.original.thumbnail}
                  alt="pic"
                  className="h-full w-full rounded object-container"
                />
              ) : (
                <div className="bg-red-500">
                  <img
                    src={defaultImage}
                    alt="picture"
                    className="h-full w-full rounded"
                  />
                </div>
              )}
            </div>
            <div className="grow w-[150px]">
              <h6 className="mb-1 w-full truncate underline" title={cell.getValue()}>
                {/* <Link to="#!" className="name"> */}
                {cell.getValue()}
                {/* </Link> */}
              </h6>
              <p className="text-slate-500 dark:text-zink-200 text-xs">
               Ürün Kodu: {cell.row.original.product_code}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "Kategori",
        accessorKey: "category.name",
        enableColumnFilter: false,
      },
      {
        header: "Marka",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <span>{cell.row.original.brand[0]?.name}</span>;
        },
      },
      {
        header: "Komisyon",
        accessorKey: "tax",
        enableColumnFilter: false,
      },
      {
        header: "Piyasa Satış Fiyatı",
        accessorKey: "original_price",
        enableColumnFilter: false,
      },
      {
        header: "a-Commerce Satış Fiyatı",
        accessorKey: "price",
        enableColumnFilter: false,
      },
      {
        header: "Stok",
        accessorKey: "stock",
        enableColumnFilter: false,
      },
      {
        header: "İşlemler",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <Dropdown className="relative dropdown">
            <Dropdown.Trigger
              className="flex items-center justify-center size-[30px] dropdown-toggle p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
              id="productAction1"
              data-bs-toggle="dropdown"
            >
              <MoreHorizontal className="size-3" />
            </Dropdown.Trigger>
            <Dropdown.Content
              placement={cell.row.index ? "top-end" : "right-end"}
              className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md dropdown-menu min-w-[10rem] dark:bg-zink-600"
              aria-labelledby="productAction1"
            >
              <li>
                <button
                  type="button"
                  className="w-full text-left block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                >
                  <Eye className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Detaya Git</span>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="w-full text-left block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                  onClick={() => {
                    modalOpen("archives", Number(cell.getValue()));
                  }}
                >
                  <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Arşivle</span>
                </button>
              </li>
            </Dropdown.Content>
          </Dropdown>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="usersTable">
            <div className="card-body">
              <TableContainer
                isPagination={true}
                columns={columns}
                data={tableData}
                customPageSize={15}
                divclassName="-mx-5 -mb-5 overflow-x-auto"
                tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-b-[1px] dark:border-zink-600"
                PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                downloadExcel
              />
            </div>
          </div>
        </div>
      </div>
      {/* User Modal  */}
    </React.Fragment>
  );
};

export default ActiveProduct;
