import React, { useCallback, useEffect, useState } from 'react'
import { sendRequestWithCache } from 'helpers/request';
import { useAppSelector } from "../../redux/hooks";
import { Wallet2 } from 'lucide-react';
import ProductBasedSalesReportsTable from "./ProductBasedSalesReportsTable";
import useChartColors from "Common/useChartColors";
import ReactApexChart from "react-apexcharts";
import LoadingOverlay from 'Common/Components/LoadingOverlay';

interface ProductBasedSalesReportsTableProps {
    filter: {
        startDate: string;
        endDate: string;
    };
}

const ProductBasedSalesReports = ({ filter }: ProductBasedSalesReportsTableProps) => {
    const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
    const [loading, setLoading] = useState(true);
    const [reportSalesSummary, setReportSalesSummary] = useState<any>([]);
    const chartColors = useChartColors('updatingDonutChart');
    const [summaryTable, setSummaryTable] = useState(1);

    const series = reportSalesSummary[0] ? (
        summaryTable === 1 ? (
            Object.keys(reportSalesSummary[0].original.cancellations.reasons).map((key) => {
                return reportSalesSummary[0]?.original.cancellations.reasons[key].count;
            })
        ) : (
            Object.keys(reportSalesSummary[0].original.returns.reasons).map((key) => {
                return reportSalesSummary[0]?.original.returns.reasons[key].count;
            })
        )
    ) : [0, 0, 0];
    const options: any = {
        chart: {
            height: 350,
            type: 'donut',
        },
        colors: chartColors,
        labels: reportSalesSummary[0] ? (
            summaryTable === 1 ? (
                Object.keys(reportSalesSummary[0].original.cancellations.reasons).map((key) => {
                    return reportSalesSummary[0]?.original.cancellations.reasons[key].reason;
                })
            ) : (
                Object.keys(reportSalesSummary[0].original.returns.reasons).map((key) => {
                    return reportSalesSummary[0]?.original.returns.reasons[key].reason;
                })
            )
        ) : ['', '', ''],
        legend: {
            position: 'bottom'
        },
    };

    const formatPrice = (price: number) => {
         if (typeof price !== "number") return '0,00₺'
        return new Intl.NumberFormat("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price) + "₺";
    };

    const fetchReportSalesSummary = useCallback(async () => {
        setLoading(true);
        const params = new URLSearchParams({
            start_date: filter.startDate,
            end_date: filter.endDate,
            scope: 'product',
        });

        try {
            const response = await sendRequestWithCache("POST", `/reports/sales/summary?${params}`, null, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });
            setReportSalesSummary(response);
        } catch (error) {
            return error
        } finally {
            setLoading(false);
        }
    }, [filter, bearer_token]);

    useEffect(() => {
        fetchReportSalesSummary();
    }, [filter, fetchReportSalesSummary]);

    return (
        <React.Fragment>
            <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
                <div className="card relative">
                    <LoadingOverlay loading={loading} color="bg-custom-500" />
                    <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                        <div className="bg-blue-400/20 p-5 rounded-md mb-2.5">
                            <h5 className="text-blue-400 text-base mb-2.5">Brüt Satış</h5>
                            <div className="flex items-center justify-between">
                                <p className="text-16">{reportSalesSummary[0]?.original.total_sales.count} Adet</p>
                                <p className="text-16">{formatPrice(reportSalesSummary[0]?.original.total_sales.total)}</p>
                            </div>
                        </div>
                        <div className="bg-red-400/20 p-5 rounded-md mb-2.5">
                            <h5 className="text-red-400 text-base mb-2.5">İptaller</h5>
                            <div className="flex items-center justify-between">
                                <p className="text-16">{reportSalesSummary[0]?.original.total_cancellations.count} Adet</p>
                                <p className="text-16">{formatPrice(reportSalesSummary[0]?.original.total_cancellations.total)}</p>
                            </div>
                        </div>
                        <div className="bg-yellow-400/20 p-5 rounded-md mb-2.5">
                            <h5 className="text-yellow-400 text-base mb-2.5">İadeler</h5>
                            <div className="flex items-center justify-between">
                                <p className="text-16">{reportSalesSummary[0]?.original.total_returns.count} Adet</p>
                                <p className="text-16">{formatPrice(reportSalesSummary[0]?.original.total_returns.total)}</p>
                            </div>
                        </div>
                        <div className="border-b-[1px] dark:border-slate-400 mb-2" />
                        <div className="bg-green-400/20 p-5 rounded-md">
                            <h5 className="text-green-400 text-base mb-2.5">Net Satış</h5>
                            <div className="flex items-center justify-between">
                                <p className="text-16">{reportSalesSummary[0]?.original.net_sales.count} Adet</p>
                                <p className="text-16">{formatPrice(reportSalesSummary[0]?.original.net_sales.total)}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card relative">
                    <LoadingOverlay loading={loading} color="bg-custom-500" />
                    <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                        <h6 className="mb-4 text-15">İptal & İade Özeti</h6>
                        <div className="flex gap-2">
                            <button
                                onClick={() => setSummaryTable(1)}
                                className={`btn leading-none rounded-xl ${summaryTable === 1 ? "bg-custom-500 text-white hover:bg-custom-600" : "bg-white text-black dark:text-white hover:bg-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 border border-black dark:border-white"}`}
                            >
                                İptal
                            </button>
                            <button
                                onClick={() => setSummaryTable(2)}
                                className={`btn leading-none rounded-xl ${summaryTable === 2 ? "bg-custom-500 text-white hover:bg-custom-600" : "bg-white text-black dark:text-white hover:bg-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 border border-black dark:border-white"}`}
                            >
                                İade
                            </button>
                        </div>
                        <div>
                            <ReactApexChart
                                dir="ltr"
                                options={options}
                                series={series || []}
                                data-chart-colors='["bg-custom-500", "bg-orange-500", "bg-green-500", "bg-sky-500", "bg-yellow-500"]'
                                id='updatingDonutChart'
                                className="apex-charts"
                                height={350}
                                type="donut"
                            />
                        </div>
                    </div>
                </div>
                <div className='card relative'>
                    <LoadingOverlay loading={loading} color="bg-custom-500" />
                    <div className={`card-body ${loading ? 'opacity-0' : ''} h-full`}>
                        <h4 className='text-15 mb-2'>Diğer Metrikler</h4>
                        <div className="flex flex-col justify-between h-[calc(100%-30px)]">
                            <div className="block py-3">
                                <div className="flex items-center gap-4">
                                    <div className="flex items-center justify-center rounded-full size-14 bg-blue-400/20 text-blue-400 dark:bg-custom-500/20">
                                        <Wallet2 />
                                    </div>
                                    <div>
                                        <h5 className="text-16">Ortalama Komisyon Oranı</h5>
                                        <p className="text-16">%{reportSalesSummary[0]?.original.average_commission_rate}</p>
                                    </div>
                                </div>
                            </div >
                            <div className="block py-3">
                                <div className="flex items-center gap-4">
                                    <div className="flex items-center justify-center rounded-full size-14 bg-orange-400/20 text-orange-400 dark:bg-custom-500/20">
                                        <Wallet2 />
                                    </div>
                                    <div>
                                        <h5 className="text-16">Toplam Komisyon Tutarı</h5>
                                        <p className="text-16">{formatPrice(reportSalesSummary[0]?.original.total_commission_amount)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="block py-3">
                                <div className="flex items-center gap-4">
                                    <div className="flex items-center justify-center rounded-full size-14 bg-yellow-400/20 text-yellow-400 dark:bg-custom-500/20">
                                        <Wallet2 />
                                    </div>
                                    <div>
                                        <h5 className="text-16">Ortalama Komsiyon Tutarı</h5>
                                        <p className="text-16">{formatPrice(reportSalesSummary[0]?.original.average_commission_amount)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="block py-3">
                                <div className="flex items-center gap-4">
                                    <div className="flex items-center justify-center rounded-full size-14 bg-red-400/20 text-red-400 dark:bg-custom-500/20">
                                        <Wallet2 />
                                    </div>
                                    <div>
                                        <h5 className="text-16">Ortalama Satış Fiyatı</h5>
                                        <p className="text-16">{formatPrice(reportSalesSummary[0]?.original.average_sale_price)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ProductBasedSalesReportsTable productBasedSalesReports={reportSalesSummary[1]?.original} loading={loading} />
        </React.Fragment>
    )
}

export default ProductBasedSalesReports;