import React, { useEffect, useState } from "react";
import { X } from "lucide-react";
import OtpInput from "react-otp-input";
import Modal from "../Modal";
import { SubmitFunc } from "models/models";
interface PropType {
  twoFaModal: { isOpen: boolean; info: string; token: string };
  onHide: () => void;
  verifyPost: any;
  verifyPhone: any;
}

const VerificationModal: React.FC<PropType> = ({
  twoFaModal,
  onHide,
  verifyPost,
  verifyPhone,
}) => {
  const [state, setState] = useState({ value: "", loading: false, scope: 1 });
  const [timeLeft, setTimeLeft] = useState(120);
  useEffect(() => {
    // Eğer zaman sıfırdan büyükse, zamanlayıcıyı başlat
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      // Cleanup: Bileşen unmount edildiğinde veya timeLeft değiştiğinde interval'ı temizle
      return () => clearInterval(intervalId);
    }
  }, [timeLeft]);
  useEffect(() => {
    if (state.scope === 2) {
      setTimeLeft(120);
    }
  }, [state.scope]);

  // Zamanı dakika ve saniye olarak formatla
  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const handleSubmit: SubmitFunc = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("code", state.value);
    formData.append("token", twoFaModal.token);
    if (twoFaModal.info.includes("numara")) {
      return verifyPhone(formData, state, setState);
    }
    verifyPost(formData, state, setState);
  };

  return (
    <>
      <Modal
        show={twoFaModal.isOpen}
        onHide={onHide}
        id="verificationModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] overflow-y-auto px-6 py-8 relative">
          <div className="absolute top-4 right-4">
            <button
              onClick={onHide}
              data-modal-close="verificationModal"
              className="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"
            >
              <X className="size-5" />
            </button>
          </div>
          <form action="" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-4">
              <div>
                <h6>Kodu Doğrula</h6>
              </div>
              <div>
                <p className="text-gray-400">
                  {`${twoFaModal.info} gelen 6 haneli doğrulama
                  kodunu giriniz.`}
                </p>
              </div>
              <div>
                <OtpInput
                  containerStyle={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "5px",
                  }}
                  placeholder="000000"
                  value={state.value}
                  onChange={(e: any) => setState({ ...state, value: e })}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props: any) => <input {...props} />}
                  // inputType="number"
                  inputStyle={{
                    width: "3rem",
                    height: "2rem",
                    border: "1px solid #D6D6D6",
                    borderRadius: "6px",
                    background: "transparent",
                  }}
                />
              </div>
              <div className="flex justify-center items-center">
                <p>{formatTime(timeLeft)}</p>
              </div>
              <div className="flex gap-2 justify-between">
                <button
                  type="reset"
                  className="text-slate-500 btn bg-slate-200 border-slate-200 hover:text-slate-600 hover:bg-slate-300 hover:border-slate-300 focus:text-slate-600 focus:bg-slate-300 focus:border-slate-300 focus:ring focus:ring-slate-100 active:text-slate-600 active:bg-slate-300 active:border-slate-300 active:ring active:ring-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 dark:border-zink-600 dark:hover:border-zink-500 dark:text-zink-200 dark:ring-zink-400/50 md:w-[200px] w-full"
                  //   onClick={offModal}
                >
                  <span className="align-middle">Kodu Tekrar Gönder</span>
                </button>
                <button
                  disabled={state.loading}
                  type="submit"
                  className="flex justify-center items-center gap-2 text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 md:w-[200px] w-full"
                >
                  {state.loading && (
                    <div className="inline-block size-4 border-2 rounded-full animate-spin border-l-transparent border-white"></div>
                  )}
                  Güncelle
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VerificationModal;
