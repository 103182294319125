import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Filtering from "./Filtering";
import BrandBasedReturnReportsTable from "./BrandBasedReturnReportsTable";
import ProductBasedReturnReports from "./ProductBasedReturnReports";
import CategoryBasedReturnReportsTable from "./CategoryBasedReturnReportsTable";
import moment from "moment";

const CanceledReports = () => {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const listParam = urlParams.get('list');
    const [filter, setFilter] = useState({
        startDate: moment().subtract(12, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
    });

    return (
        <React.Fragment>
            <Filtering setFilter={setFilter} listParam={listParam} />
            {listParam === 'product-based' ? (
                <ProductBasedReturnReports filter={filter} />
            ) : listParam === 'brand-based' ? (
                <BrandBasedReturnReportsTable filter={filter} />
            ) : listParam === 'category-based' ? (
                <CategoryBasedReturnReportsTable filter={filter} />
            ) : (
                null
            )}
        </React.Fragment >
    )
}

export default CanceledReports;