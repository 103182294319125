import { useEffect, useState } from "react";
import { CalendarDays, MoreHorizontal, Info } from "lucide-react";
import { Link } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { Nav } from "../../Common/Components/Tab/Nav";
import Tab from "../../Common/Components/Tab/Tab";
import BreadCrumb from "../../Common/BreadCrumb";
import TableContainer from "../../Common/TableContainer";
import iphone from "assets/images/product/iphone.png";
import DetailModal from "./modal/DetailModal";
import RepliesModal from "./modal/RepliesModal";
import { Dropdown } from "Common/Components/Dropdown";
import { sendRequestWithCache } from "helpers/request";
import { useAppSelector } from "../../redux/hooks";
import { getItem } from "helpers/localStorageControl";
import { EventFunc, SubmitFunc } from "models/models";
import NotFound from "pages/finance/NotFound";

interface DetailStateType {
  isOpen: boolean;
}
interface InputValueType {
  product_name: string;
  keyword: string;
  brand_id: any;
  state: any;
  start_date: string;
  end_date: string;
}

const Questions = () => {
  const pathname = window.location.pathname;
  const path = pathname.split("/")[1];
  const [currentQuestions, setCurrentQuestions] = useState({
    data: getItem("questions")?.data || [],
    tableData: getItem("questions")?.data?.data || [],
    loading: false,
    error: false,
  });
  const { loading, error, tableData } = currentQuestions;
  const [userBrands, setUserBrands] = useState([]);
  const [extraLargeModal, setExtraLargeModal] = useState<boolean>(false);
  const [detailState, setDetailState] = useState<DetailStateType>({
    isOpen: false,
  });
  const [inputValue, setInputValue] = useState<InputValueType>({
    product_name: "",
    keyword: "",
    brand_id: "",
    state: "",
    start_date: "",
    end_date: "",
  });
  const { currentUser } = useAppSelector((state) => state.auth);

  const extraLargeToggle = () => setExtraLargeModal(!extraLargeModal);

  const columns = [
    {
      header: "Ürün Bilgileri",
      accessorKey: "product.title",
      enableColumnFilter: false,
      enableSorting: true,
      cell: (cell: any) => {
        return (
          <div className="flex items-center gap-2 w-full overflow-hidden">
            {cell.row.original.state === 1 && (
              <div className="rounded-full h-1 w-1 bg-custom-500" />
            )}
            {cell.row.original.state === 2 && (
              <div className="rounded-full h-1 w-1 bg-red-500" />
            )}
            {cell.row.original.state === 3 && (
              <div className="rounded-full h-1 w-1 bg-yelleow-500" />
            )}
            {cell.row.original.state === 4 && (
              <div className="rounded-full h-1 w-1 bg-green-500" />
            )}

            <div className="h-16 flex items-center justify-center font-medium rounded shrink-0 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
              {cell.row.original.product.thumbnail && (
                <>
                  <img
                    src={cell.row.original.product.thumbnail}
                    alt=""
                    className="h-full rounded"
                  />
                </>
              )}
            </div>
            <div className="grow w-[100px]">
              <h6
                className="mb-1 truncate text-wrap whitespace-nowrap line-clamp-2"
                title={cell.getValue()}
              >
                {cell.getValue()}
              </h6>
            </div>
          </div>
        );
      },
    },
    {
      header: "Oluşturulma Tarihi",
      accessorKey: "created_at",
      enableColumnFilter: false,
      enableSorting: true,
      cell: (cell: any) => {
        return <p>{moment(cell.getValue()).format("DD/MM/YYYY HH:mm")} </p>;
      },
    },
    {
      header: "Müşteri Adı-Soyadı",
      accessorKey: "user.full_name",
      enableColumnFilter: false,
      enableSorting: true,
      cell: (cell: any) => {
        return <p>{cell.getValue()}</p>;
      },
    },
    {
      header: "Soru Detayı",
      accessorKey: "question",
      enableColumnFilter: false,
      enableSorting: true,
      cell: (cell: any) => (
        <div className="flex flex-col justify-center items-start gap-1">
          <button
            type="button"
            className="bg-white border-white text-custom-500 hover:text-custom-700 focus:text-custom-700 active:text-custom-700 dark:bg-zink-700 dark:border-zink-700"
          >
            {cell.row.original.state_text}
          </button>
          <p>{cell.getValue()}</p>
        </div>
      ),
    },
    {
      header: "İşlemler",
      enableColumnFilter: false,
      enableSorting: true,
      cell: (cell: any) => (
        <Dropdown className="relative">
          <Dropdown.Trigger
            className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
            id="usersAction1"
          >
            <MoreHorizontal className="size-3" />
          </Dropdown.Trigger>
          <Dropdown.Content
            placement="right-end"
            className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600"
            aria-labelledby="usersAction1"
          >
            <li>
              {Number(cell.row.index) === 0 ? (
                <Link
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                  to="#"
                  onClick={extraLargeToggle}
                >
                  {/* <Eye className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "} */}
                  <span className="align-middle">Cevapla</span>
                </Link>
              ) : (
                <Link
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                  to="#"
                  onClick={() =>
                    setDetailState((state) => ({
                      ...state,
                      isOpen: true,
                    }))
                  }
                >
                  {/* <Eye className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "} */}
                  <span className="align-middle">Detaya git</span>
                </Link>
              )}
            </li>
            <li>
              <Link
                className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                to="#"
                onClick={extraLargeToggle}
              >
                {/* <Eye className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "} */}
                <span className="align-middle">Sorun Bildir </span>
              </Link>
            </li>
          </Dropdown.Content>
        </Dropdown>
      ),
    },
  ];

  const getQuestions = async (formData: any = null) => {
    setCurrentQuestions({ ...currentQuestions, loading: true, error: false });
    try {
      const data = await sendRequestWithCache(
        "POST",
        `/product/questions
        `,
        formData,
        formData
          ? {}
          : {
              cache: true,
              cacheName: "questions",
              cacheTime: 300,
            },
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );
      setCurrentQuestions({
        ...currentQuestions,
        data,
        tableData: data.data,
        loading: false,
        error: false,
      });
    } catch (error) {
      setCurrentQuestions({
        ...currentQuestions,
        data: [],
        tableData: [],
        loading: false,
        error: true,
      });
    }
  };
  const userBrandsGet = async () => {
    try {
      const { data } = await sendRequestWithCache(
        "GET",
        `/user/brands`,
        null,
        { cache: true, cacheName: "userBrands", cacheTime: 3000 },
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );

      setUserBrands(data);
    } catch (error) {
      setUserBrands([]);
    }
  };

  const handleSubmit: SubmitFunc = (event) => {
    event.preventDefault();
    const formData = new FormData();
    if (inputValue.brand_id?.value?.length > 0) {
      formData.append("brand_id", inputValue.brand_id.value.toString());
    }
    if (inputValue.keyword.length > 0) {
      formData.append("keyword", inputValue.keyword);
    }
    if (inputValue.product_name.length > 0) {
      formData.append("product_name", inputValue.product_name);
    }
    if (inputValue.start_date.length > 0) {
      formData.append(
        "start_date",
        moment(inputValue.start_date).format("YYYY/MM/DD")
      );
    }
    if (inputValue.end_date.length > 0) {
      formData.append(
        "end_date",
        moment(inputValue.end_date).format("YYYY/MM/DD")
      );
    }
    if (inputValue.state.length > 0) {
      formData.append("state", inputValue.state);
    }
    getQuestions(formData);
  };
  const handleChange: EventFunc = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };
  const dateFunc = (data: string, state: string) => {
    setInputValue({ ...inputValue, [state]: data });
  };
  const handleSelect = (e: { label: string; value: string }, state: string) => {
    setInputValue({ ...inputValue, [state]: e });
  };

  useEffect(() => {
    getQuestions();
    userBrandsGet();
  }, []);

  return (
    <>
      <BreadCrumb title="Müşteri Soruları" pageTitle="Ürün Soruları" />
      <div className="card">
        <div className="card-body flex flex-col gap-5">
          <Tab.Container defaultActiveKey="questions">
            <Nav className="flex flex-wrap w-full text-sm font-medium text-center border-b border-slate-200 dark:border-zink-500 nav-tabs">
              <Nav.Item eventKey={path} className="group">
                <Link
                  to="/questions"
                  data-tab-toggle
                  data-target="questions"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  {/* <Home className="inline-block size-4 mr-1"></Home>{" "} */}

                  <span className="align-middle">Ürün Soruları</span>
                  <p
                    className="align-middle text-gray-400
                  "
                  >
                    0 Soru
                  </p>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="ProfileIcon" className="group">
                <Link
                  to="#"
                  data-tab-toggle
                  data-target="ProfileIcon"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  {/* <User2 className="inline-block size-4 mr-1"></User2>{" "} */}
                  <span className="align-middle">Sipariş Soruları</span>
                  <p
                    className="align-middle text-gray-400
                  "
                  >
                    0 Soru
                  </p>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="settingIcon" className="group">
                <Link
                  to="#"
                  data-tab-toggle
                  data-target="settingIcon"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  {/* <Settings className="inline-block size-4 mr-1"></Settings>{" "} */}
                  <span className="align-middle">Hazır Cevaplar</span>
                  <p
                    className="align-middle text-gray-400
                  "
                  >
                    0 Soru
                  </p>
                </Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
          <form
            action="#!"
            className="flex flex-col gap-5"
            onSubmit={handleSubmit}
          >
            <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 xl:grid-cols-4">
              <div className="mb-4">
                <label
                  htmlFor="statu"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Statü
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <Select
                  className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  id="state"
                  name="state"
                  // data-choices
                  // data-choices-multiple-groups="true"
                  // isMulti
                  isClearable
                  options={[
                    { value: "1", label: "Cevaplanan" },
                    { value: "2", label: "Cevap Bekleyen" },
                    { value: "3", label: "Rededilen" },
                    { value: "4", label: "Sorun Bildiren" },
                  ]}
                  onChange={(e: any) => handleSelect(e, "state")}
                  placeholder="Statü"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="product_name"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Ürün Adı
                </label>
                <input
                  type="text"
                  id="product_name"
                  name="product_name"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Ürün adı"
                  onChange={handleChange}
                  value={inputValue.product_name}
                />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="start_date"
                  className="inline-flex items-center gap-1 mb-2 text-base font-medium"
                >
                  Başlangıç Tarihi
                  <div className="relative group group/item">
                    <Info size={14} className="text-blue-400" />
                    <div className="absolute group/edit invisible group-hover/item:visible group-hover/item:opacity-100 left-0 bottom-full mb-2 w-48 p-2 text-xs text-white bg-black rounded-lg opacity-0 transition-opacity duration-200 text-justify">
                      Seçeceğiniz başlangıç ve bitiş tarihinin aralığı 30 günü
                      geçmemelidir.
                    </div>
                  </div>
                </label>
                <Flatpickr
                  options={{
                    dateFormat: "d M, Y",
                    defaultDate: moment().subtract(30, "days").toDate(),
                    locale: {
                      weekdays: {
                        longhand: [
                          "Pazar",
                          "Pazartesi",
                          "Salı",
                          "Çarşamba",
                          "Perşembe",
                          "Cuma",
                          "Cumartesi",
                        ],
                        shorthand: [
                          "Paz",
                          "Pzt",
                          "Sal",
                          "Çar",
                          "Per",
                          "Cum",
                          "Cmt",
                        ],
                      },
                      months: {
                        longhand: [
                          "Ocak",
                          "Şubat",
                          "Mart",
                          "Nisan",
                          "Mayıs",
                          "Haziran",
                          "Temmuz",
                          "Ağustos",
                          "Eylül",
                          "Ekim",
                          "Kasım",
                          "Aralık",
                        ],
                        shorthand: [
                          "Oca",
                          "Şub",
                          "Mar",
                          "Nis",
                          "May",
                          "Haz",
                          "Tem",
                          "Ağu",
                          "Eyl",
                          "Eki",
                          "Kas",
                          "Ara",
                        ],
                      },
                    },
                  }}
                  placeholder="Başlangıç tarihi seçiniz"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  onChange={(_: any, i: string) => dateFunc(i, "start_date")}
                />
                <CalendarDays className="absolute size-4 right-3 bottom-3 text-slate-500 dark:text-zink-200" />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="cityInput"
                  className="inline-flex items-center gap-1 mb-2 text-base font-medium"
                >
                  Bitiş Tarihi
                  <div className="relative group group/item">
                    <Info size={14} className="text-blue-400" />
                    <div className="absolute group/edit invisible group-hover/item:visible group-hover/item:opacity-100 left-0 bottom-full mb-2 w-48 p-2 text-xs text-white bg-black rounded-lg opacity-0 transition-opacity duration-200 text-justify">
                      Seçeceğiniz başlangıç ve bitiş tarihinin aralığı 30 günü
                      geçmemelidir.
                    </div>
                  </div>
                </label>
                <Flatpickr
                  options={{
                    dateFormat: "d M, Y",
                    defaultDate: "today",
                    maxDate: "today",
                    minDate: inputValue.start_date,
                    locale: {
                      weekdays: {
                        longhand: [
                          "Pazar",
                          "Pazartesi",
                          "Salı",
                          "Çarşamba",
                          "Perşembe",
                          "Cuma",
                          "Cumartesi",
                        ],
                        shorthand: [
                          "Paz",
                          "Pzt",
                          "Sal",
                          "Çar",
                          "Per",
                          "Cum",
                          "Cmt",
                        ],
                      },
                      months: {
                        longhand: [
                          "Ocak",
                          "Şubat",
                          "Mart",
                          "Nisan",
                          "Mayıs",
                          "Haziran",
                          "Temmuz",
                          "Ağustos",
                          "Eylül",
                          "Ekim",
                          "Kasım",
                          "Aralık",
                        ],
                        shorthand: [
                          "Oca",
                          "Şub",
                          "Mar",
                          "Nis",
                          "May",
                          "Haz",
                          "Tem",
                          "Ağu",
                          "Eyl",
                          "Eki",
                          "Kas",
                          "Ara",
                        ],
                      },
                    },
                  }}
                  placeholder="Bitiş tarihi seçiniz"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  onChange={(_: any, i: string) => dateFunc(i, "end_date")}
                />
                <CalendarDays className="absolute size-4 right-3 bottom-3 text-slate-500 dark:text-zink-200" />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="brand_id"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Marka
                </label>
                <Select
                  className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  id="brand_id"
                  name="brand_id"
                  // data-choices
                  // data-choices-multiple-groups="true"
                  // isMulti
                  isClearable
                  options={userBrands}
                  onChange={(e: any) => handleSelect(e, "brand_id")}
                  placeholder="Marka"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="keyword"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Anahtar Kelime ile Arama
                </label>
                <input
                  type="text"
                  id="keyword"
                  name="keyword"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Anahtar kelime"
                  onChange={handleChange}
                  value={inputValue.keyword}
                />
              </div>
            </div>
            <div className="flex justify-end gap-2">
              <button
                type="reset"
                className="text-slate-500 btn bg-slate-200 border-slate-200 hover:text-slate-600 hover:bg-slate-300 hover:border-slate-300 focus:text-slate-600 focus:bg-slate-300 focus:border-slate-300 focus:ring focus:ring-slate-100 active:text-slate-600 active:bg-slate-300 active:border-slate-300 active:ring active:ring-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 dark:border-zink-600 dark:hover:border-zink-500 dark:text-zink-200 dark:ring-zink-400/50 md:w-[172px] w-full"
              >
                <span className="align-middle">Temizle</span>
              </button>
              <button
                type="submit"
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 md:w-[172px] w-full"
              >
                Filtrele
              </button>
            </div>
          </form>
        </div>
      </div>
      <>
        {loading && (
          <div className="flex flex-col items-center justify-center">
            <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-yellow-400"></div>
            <p>Yükleniyor...</p>
          </div>
        )}
        {!loading && (error || tableData.length === 0) && (
          <div className="noresult">
            <div className="py-6 text-center">
              <NotFound title="Üzgünüm! Sonuç bulunamadı." />
            </div>
          </div>
        )}
        {tableData?.length > 0 && !loading && !error && (
          <div className="card">
            <div className="card-body">
              <TableContainer
                isPagination={true}
                columns={columns || []}
                data={tableData || []}
                customPageSize={10}
                divclassName="-mx-5 -mb-5 overflow-x-auto"
                tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-b-[1px] dark:border-zink-600"
                PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
              />
            </div>
          </div>
        )}
      </>
      <RepliesModal
        extraLargeToggle={extraLargeToggle}
        extraLargeModal={extraLargeModal}
      />
      <DetailModal detailState={detailState} setDetailState={setDetailState} />
    </>
  );
};

export default Questions;
