import React, { useCallback, useEffect, useMemo, useState } from "react";
import { sendRequestWithCache } from 'helpers/request';
import { useAppSelector } from "../../redux/hooks";
import NotFound from "../../Common/Components/NotFound/NotFound";
import TableContainer from "Common/TableContainer";
import { Truck, Info } from "lucide-react";

interface FinanceFuturePayments {
    completed_orders: {
        [key: string]: number;
    };
    processing_orders: {
        [key: string]: number;
    };
}

const FuturePage = () => {
    const [loadingFuturePayments, setLoadingFuturePayments] = useState(true);
    const [loadingFuturePaymentsChart, setLoadingFuturePaymentsChart] = useState(true);
    const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
    const [futurePayments, setFuturePayments] = useState<FinanceFuturePayments>({
        completed_orders: {
            commission: 0,
            net_earning: 0,
            shipment_price: 0,
            total_sales_amount: 0,
        },
        processing_orders: {
            total_sales_amount: 0,
        },
    });
    const [futurePaymentsChart, setFuturePaymentsChart] = useState(Array(10).fill({}));
    const columns = useMemo(
        () => [
            {
                header: 'Ödeme Tarihi',
                accessorKey: 'paymentDate',
                enableColumnFilter: false,
            },
            {
                header: 'Satış Tutarı',
                accessorKey: 'salesAmount',
                enableColumnFilter: false,
            },
            {
                header: 'Komisyon Kesintisi',
                accessorKey: 'commission',
                enableColumnFilter: false,
            },
            {
                header: 'Kargo Kesintisi',
                accessorKey: 'shipmentPrice',
                enableColumnFilter: false,
            },
            {
                header: 'Diğer',
                accessorKey: 'other',
                enableColumnFilter: false,
            },
            {
                header: 'Tahmini Tutar',
                accessorKey: 'estimatedAmount',
                enableColumnFilter: false,
            }
        ],
        []
    );

    const formatDate = (date: string) => {
        const newDate = new Date(date);
        return newDate.toLocaleDateString("tr-TR", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    }

    const formatPrice = (price: number) => {
         if (typeof price !== "number") return '0,00₺'
        return new Intl.NumberFormat("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price) + "₺";
    };


    const fetchFuturePayments = useCallback(async () => {
        setLoadingFuturePayments(true);
        try {
            const response = await sendRequestWithCache("GET", "/finance/future-payments", null, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });
            setFuturePayments(response);
        } catch (error) {
            return error
        } finally {
            setLoadingFuturePayments(false);
        }
    }, [bearer_token]);

    const fetchFinance = useCallback(async () => {
        setLoadingFuturePaymentsChart(true);
        try {
            const { payments } = await sendRequestWithCache("POST", "/finance", null, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });
            setFuturePaymentsChart(payments.map((payment: {
                total_price: number;
                commission: number;
                net_earnings: number;
                payment_date: string;
                shipment_price: number;
                other: number;
            }) => {
                return {
                    paymentDate: formatDate(payment.payment_date),
                    salesAmount: formatPrice(payment.total_price),
                    commission: formatPrice(payment.commission),
                    shipmentPrice: formatPrice(payment.shipment_price),
                    other: formatPrice(payment.other),
                    estimatedAmount: formatPrice(payment.net_earnings),
                };
            }));

        } catch (error) {
            return error
        } finally {
            setLoadingFuturePaymentsChart(false);
        }
    }, [bearer_token]);

    useEffect(() => {
        fetchFuturePayments();
        fetchFinance();
    }, [fetchFuturePayments, fetchFinance]);

    return (
        <React.Fragment>
            <div className="grid grid-cols-12 gap-5 mb-[-1rem]">
                <div className="card col-span-12 md:col-span-4 p-2 rounded-md text-custom-500 bg-custom-100 dark:bg-custom-400/20 dark:border-t-custom-500/50 mb-2 md:mb-5 relative">
                    {loadingFuturePayments && (
                        <div className="flex items-center justify-center h-full absolute inset-0">
                            <div className="flex flex-col items-center">
                                <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                            </div>
                        </div>
                    )}
                    <div className={`card-body ${loadingFuturePayments ? 'opacity-0' : ''}`}>
                        <div className="flex items-center gap-2 mb-4">
                            <h5 className="font-normal text-16 truncate">Onaylanan / Kargolanan Siparişler</h5>
                            <span><Info className="size-6 text-custom-500" /></span>
                        </div>
                        <div className="flex flex-row lg:flex-row md:flex-col items-center gap-4 lg:gap-4 md:gap-0">
                            <span><Truck className="size-12 text-custom-500" /></span>
                            <div>
                                <div className="text-sm text-slate-400 truncate mb-2">Toplam Satış Tutarı</div>
                                <span className="text-black dark:text-white text-start lg:text-start md:text-center block">{formatPrice(futurePayments.processing_orders.total_sales_amount)}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card col-span-12 md:col-span-8 p-2 rounded-md text-custom-500 bg-custom-100 dark:bg-custom-400/20 dark:border-t-custom-500/50 relative">
                    {loadingFuturePayments && (
                        <div className="flex items-center justify-center h-full absolute inset-0">
                            <div className="flex flex-col items-center">
                                <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500" />
                            </div>
                        </div>
                    )}
                    <div className={`card-body ${loadingFuturePayments ? 'opacity-0' : ''}`}>
                        <div className="flex items-center gap-2 mb-4">
                            <h5 className="font-normal text-16 truncate">Teslim Edilen Siparişler</h5>
                            <span><Info className="size-6 text-custom-500" /></span>
                        </div>
                        <div className="grid grid-cols-12 gap-2">
                            <div className="col-span-6 lg:col-span-3">
                                <div className="text-sm text-slate-400 truncate mb-2">Toplam Satış Tutarı</div>
                                <span className="text-black dark:text-white">{formatPrice(futurePayments.completed_orders.total_sales_amount)}</span>
                            </div>
                            <div className="col-span-6 lg:col-span-3">
                                <div className="text-sm text-slate-400 truncate mb-2">Tahmini Komisyon Kesintisi</div>
                                <span className="text-black dark:text-white">{formatPrice(futurePayments.completed_orders.commission)}</span>
                            </div>
                            <div className="col-span-6 lg:col-span-3">
                                <div className="text-sm text-slate-400 truncate mb-2">Tahmini Kargo Kesintisi</div>
                                <span className="text-black dark:text-white">{formatPrice(futurePayments.completed_orders.shipment_price)}</span>
                            </div>
                            <div className="col-span-6 lg:col-span-3">
                                <div className="text-sm text-slate-400 truncate mb-2">Toplam Tahmini Tutar</div>
                                <span className="text-black dark:text-white">{formatPrice(futurePayments.completed_orders.net_earning)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12 relative">
                {loadingFuturePaymentsChart && (
                    <div className="flex items-center justify-center h-full absolute inset-0">
                        <div className="flex flex-col items-center">
                            <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-green-400" />
                        </div>
                    </div>
                )}
                <div className={`xl:col-span-12 ${loadingFuturePaymentsChart ? 'opacity-0' : ''}`}>
                    {futurePaymentsChart.length > 0 ? (
                        <TableContainer
                            isPagination
                            columns={columns || []}
                            data={futurePaymentsChart}
                            customPageSize={10}
                            divclassName="-mx-5 -mb-5 overflow-x-auto"
                            tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                            theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                            thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                            tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                            PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                        />
                    ) : (
                        <NotFound />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default FuturePage;
