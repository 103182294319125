import { ChevronRight } from "lucide-react";

import {
  SelectStateType,
  categoriyGetType,
} from "pages/product/addProduct/AddProduct";

interface MultiSelectProps {
  multiSelectState: SelectStateType;
  categoryGet: categoriyGetType;
  selectCategory: string;
}
interface itemType {
  category_id: number;
  hasChilds: boolean;
  home_featured: number;
  id: number;
  image: string;
  name: string;
  slug: string;
}

interface Replicate {
  data: any;
  index: number;
  categoryGet: categoriyGetType;
  loading: any;
}

const Replicate: React.FC<Replicate> = ({
  data,
  index,
  categoryGet,
  loading,
}) => {
  return (
    <>
      {/* {loading[data.length] ? ( */}

      <div
        className=" min-w-[260px]  border-r p-2 dark:border-zink-500 "
        key={index}
      >
        <div className="w-full flex items-center">
          <input
            type="text"
            id="lastNameInput2"
            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
            placeholder="Kategoriyi seçiniz"
          />
        </div>
        <div className="p-2">
          <ul className="flex flex-col overflow-y-auto h-[200px] max-h-52 cursor-pointer">
            {data.map((item: itemType) => (
              <li
                className="p-2 border-b flex justify-between items-center dark:border-zink-500 "
                onClick={() => categoryGet(item.id, index + 1, item.name)}
                key={item.id}
              >
                <span>{item.name}</span>
                {item.hasChilds && (
                  <span className="">
                    <ChevronRight size={20} />
                  </span>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {loading[data.length] && (
        <div className="w-[50px] h-[200px] flex justify-center items-center">
          <div className="inline-block size-6 border-2 rounded-full animate-spin border-l-transparent border-custom-500"></div>
        </div>
      )}
    </>
  );
};

const MultiSelect: React.FC<MultiSelectProps> = ({
  multiSelectState,
  categoryGet,
  selectCategory,
}) => {
  return (
    <div className="border w-full mt-4 rounded-md bg-transparent dark:border-zink-500 animate-slide-in ">
      <div className="flex items-center overflow-auto w-full bg-transparent ">
        {multiSelectState.data.map((item: any, index: number) => {
          return (
            <Replicate
              data={multiSelectState.data[index]}
              loading={multiSelectState.loading}
              index={index}
              categoryGet={categoryGet}
              key={index}
            />
          );
        })}
      </div>

      <div className="w-full bg-gray-300 p-2 flex gap-5 dark:bg-zink-600 rounded-bl-md rounded-br-md">
        <span className="font-bold">Seçilen:</span>
        <span>{selectCategory}</span>
      </div>
    </div>
  );
};

export default MultiSelect;
