import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Nav } from "../../Common/Components/Tab/Nav";
import Tab from "../../Common/Components/Tab/Tab";
import Flatpickr from "react-flatpickr";
import { CalendarDays, Info } from "lucide-react";

import AllSales from "./AllSales";
import NewSales from "./NewSales";
import ProcessedSales from "./ProcessedSales";
import TransportedSales from "./TransportedSales";
import DeliveredSales from "./DeliveredSales";
import moment from "moment";

export interface ProductProps {
  handleDelete: () => void;
  deleteToggle: () => void;
  deleteModal: boolean;
}
export interface column {
  header: string;
  accessorKey: string;
  enableColumnFilter: boolean;
  enableSorting: boolean;
}
const SalesTransactions = ({ deliveryDate }: { deliveryDate: boolean }) => {
  const { pathname } = useLocation();

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body flex flex-col gap-5">
          <Tab.Container defaultActiveKey={pathname}>
            <Nav className="flex flex-wrap w-full text-sm font-medium text-center border-b border-slate-200 dark:border-zink-500 nav-tabs">
              <Nav.Item eventKey="/shipping-phase/all-sales" className="group">
                <Link
                  to="/shipping-phase/all-sales"
                  data-tab-toggle
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  <span className="align-middle">Tüm Siparişler</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="/shipping-phase/new-sales" className="group">
                <Link
                  to="/shipping-phase/new-sales"
                  data-tab-toggle
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  <span className="align-middle">Yeni</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="/shipping-phase/processed-sales" className="group">
                <Link
                  to="/shipping-phase/processed-sales"
                  data-tab-toggle
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  <span className="align-middle">İşleme Alınanlar</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="/shipping-phase/transported-sales" className="group">
                <Link
                  to="/shipping-phase/transported-sales"
                  data-tab-toggle
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  <span className="align-middle">Taşıma Durumunda</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="/shipping-phase/delivered-sales" className="group">
                <Link
                  to="/shipping-phase/delivered-sales"
                  data-tab-toggle
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  <span className="align-middle">Teslim Edilen</span>
                </Link>
              </Nav.Item>
              {/* <Nav.Item eventKey="/shipping-phase/resend-sales" className="group">
                <Link
                  to="/shipping-phase/resend-sales"
                  data-tab-toggle
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  <span className="align-middle">Yeniden Gönderimler</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="/shipping-phase/pending-sales" className="group">
                <Link
                  to="/shipping-phase/pending-sales"
                  data-tab-toggle
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  <span className="align-middle">Askıdaki Siparişler</span>
                </Link>
              </Nav.Item> */}
            </Nav>
          </Tab.Container>
          <form action="#!" className="xl:flex gap-5">
            <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 xl:grid-cols-3 flex-1">
              <div className="mb-4">
                <label
                  htmlFor="firstNameInput2"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Müşteri Adı
                </label>
                <input
                  type="text"
                  id="customer-firstname"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Müşteri adını giriniz"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="lastNameInput2"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Sipariş Numarası
                </label>
                <input
                  type="text"
                  id="customer-lastname"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Sipariş numarısını giriniz"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="UsernameInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Ürün Adı
                </label>
                <input
                  type="text"
                  id="product-name"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Ürünün adını giriniz"
                />
              </div>
              <div className="relative mb-4">
                <label htmlFor="sales-date" className="inline-flex items-center gap-1 mb-2 text-base font-medium">
                  Sipariş Tarihi
                  <div className="relative group group/item">
                    <Info size={14} className='text-blue-400' />
                    <div className="absolute group/edit invisible group-hover/item:visible group-hover/item:opacity-100 left-0 bottom-full mb-2 w-48 p-2 text-xs text-white bg-black rounded-lg opacity-0 transition-opacity duration-200 text-justify">
                      Seçeceğiniz başlangıç ve bitiş tarihinin aralığı 30 günü geçmemelidir.
                    </div>
                  </div>
                </label>
                <Flatpickr
                  id="sales-date"
                  options={{
                    dateFormat: "d M, Y",
                    maxDate: moment().subtract(30, 'days').format('DD MMM, YYYY'),
                    enableTime: false,
                    locale: {
                      weekdays: {
                        longhand: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
                        shorthand: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt']
                      },
                      months: {
                        longhand: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
                        shorthand: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara']
                      },
                    }
                  }}
                  placeholder="Sipariş tarhini seçiniz"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                />
                <CalendarDays className="absolute size-4 right-3 bottom-3 text-slate-500 dark:text-zink-200" />
              </div>
              {
                deliveryDate && (
                  <div className="relative mb-4">
                    <label htmlFor="delivery-date" className="inline-flex items-center gap-1 mb-2 text-base font-medium">
                      Teslimat Tarihi
                      <div className="relative group group/item">
                        <Info size={14} className='text-blue-400' />
                        <div className="absolute group/edit invisible group-hover/item:visible group-hover/item:opacity-100 left-0 bottom-full mb-2 w-48 p-2 text-xs text-white bg-black rounded-lg opacity-0 transition-opacity duration-200 text-justify">
                          Seçeceğiniz başlangıç ve bitiş tarihinin aralığı 30 günü geçmemelidir.
                        </div>
                      </div>
                    </label>
                    <Flatpickr
                      id="delivery-date"
                      options={{
                        dateFormat: "d M, Y",
                        defaultDate: "today",
                        enableTime: false,
                        locale: {
                          weekdays: {
                            longhand: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
                            shorthand: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt']
                          },
                          months: {
                            longhand: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
                            shorthand: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara']
                          },
                        }
                      }}
                      placeholder="Teslimat tarhini seçiniz"
                      className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    />
                    <CalendarDays className="absolute size-4 right-3 bottom-3 text-slate-500 dark:text-zink-200" />
                  </div>
                )
              }
            </div>
            <div className="flex flex-1 items-end justify-end gap-2">
              <button
                type="button"
                className="text-slate-500 btn bg-slate-200 border-slate-200 hover:text-slate-600 hover:bg-slate-300 hover:border-slate-300 focus:text-slate-600 focus:bg-slate-300 focus:border-slate-300 focus:ring focus:ring-slate-100 active:text-slate-600 active:bg-slate-300 active:border-slate-300 active:ring active:ring-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 dark:border-zink-600 dark:hover:border-zink-500 dark:text-zink-200 dark:ring-zink-400/50 md:w-[200px] w-full"
              >
                <span className="align-middle">Temizle</span>
              </button>
              <button
                type="submit"
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 md:w-[200px] w-full"
              >
                Filtrele
              </button>
            </div>
          </form>
        </div>
      </div>

      {
        pathname === "/shipping-phase/all-sales" ? (
          <AllSales />
        ) : pathname === "/shipping-phase/new-sales" ? (
          <NewSales />
        ) : pathname === "/shipping-phase/processed-sales" ? (
          <ProcessedSales />
        ) : pathname === "/shipping-phase/transported-sales" ? (
          <TransportedSales />
        ) : pathname === "/shipping-phase/delivered-sales" ? (
          <DeliveredSales />
        ) : null
      }
    </React.Fragment>
  );
};

export default SalesTransactions;
