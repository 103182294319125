import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";

// Front
import LayoutReducer from "./layouts/reducer";
// userProfile
import ProfileReducer from "./auth/profile/reducer";

export const store = configureStore({
  reducer: {
    layout: LayoutReducer,
    auth: authReducer,
    profile: ProfileReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
