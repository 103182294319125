import { useEffect, useMemo, useState } from "react";
import { CalendarDays, Search, Info } from "lucide-react";
import Select from "react-select";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "Common/BreadCrumb";
import { Nav } from "Common/Components/Tab/Nav";
import Tab from "Common/Components/Tab/Tab";
import TableContainer from "Common/TableContainer";
import { sendRequestWithCache } from "helpers/request";
import { useAppSelector } from "../../redux/hooks";
import NotFound from "pages/finance/NotFound";
import { getItem } from "helpers/localStorageControl";
import { SubmitFunc } from "models/models";
import moment from "moment";

interface InputValuesType {
  invoice_type: any;
  start_date: string;
  end_date: string;
  order_code: string;
}

const InvoiceListing = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const [currentData, setCurrentData] = useState({
    loading: false,
    error: false,
    data: getItem("invoice")?.data?.data?.data || [],
  });
  const [inputValues, setInputValues] = useState<InputValuesType>({
    invoice_type: "",
    start_date: "",
    end_date: "",
    order_code: "",
  });
  const { loading, error, data } = currentData;

  const invoiceType = [
    { value: "4", label: "Satış Faturası" },
    // { value: "1", label: "Komisyon" },
  ];

  const columns = useMemo(
    () => [
      {
        header: "Fatura Numarası",
        accessorKey: "id_code",
        enableColumnFilter: false,
      },
      {
        header: "Sipariş Numarası",
        accessorKey: "order.orderId",
        enableColumnFilter: false,
      },
      {
        header: "Fatura Tarihi",
        accessorKey: "faturaTarihi",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div>
            <p>{moment(cell.getValue()).format("LL")}</p>
          </div>
        ),
      },
      {
        header: "Tutar",
        accessorKey: "invoice_amount",
        enableColumnFilter: false,
      },
      {
        header: "Fatura Tipi",
        accessorKey: "invoice_type",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const data: any = invoiceType.find(
            (item: any) => Number(item.value) === Number(cell.getValue())
          );

          return (
            <div>
              <p>{data.label}</p>
            </div>
          );
        },
      },
      {
        header: "işlemler",
        enableColumnFilter: false,
        enableSorting: true,
        cell: () => {
          return (
            <div>
              <button
                type="button"
                className="bg-white text-custom-500 btn border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:bg-zink-700 dark:hover:bg-custom-500 dark:ring-custom-400/20 dark:focus:bg-custom-500"
              >
                Fatura İndir
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  const getInvoice = async (formData: any = null, search: boolean = false) => {
    setCurrentData({ ...currentData, error: false, loading: true });
    try {
      const { data } = await sendRequestWithCache(
        "POST",
        `/invoice/search/user`,
        formData,
        search ? {} : { cache: true, cacheName: "invoice", cacheTime: 3000 },
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );
      setCurrentData({
        ...currentData,
        data: data.data,
        error: false,
        loading: false,
      });
    } catch (error) {
      setCurrentData({ ...currentData, data: [], error: true, loading: false });
    }
  };

  useEffect(() => {
    getInvoice();
  }, []);

  const handleSubmit: SubmitFunc = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (inputValues.invoice_type?.value?.length > 0) {
      formData.append("invoice_type", inputValues.invoice_type.value);
    }
    if (inputValues.order_code.length > 0) {
      formData.append("order_code", inputValues.order_code);
    }
    if (inputValues.start_date.length > 0) {
      formData.append("start_date", inputValues.start_date);
    }
    if (inputValues.end_date.length > 0) {
      formData.append("end_date", inputValues.end_date);
    }
    getInvoice(formData, true);
  };

  const handleChange = (event: any, name: string) => {
    setInputValues({ ...inputValues, [name]: event });
  };

  const clearState = () => {
    setInputValues({
      invoice_type: "",
      start_date: "",
      end_date: "",
      order_code: "",
    });
  };

  return (
    <>
      <BreadCrumb title="Fatura Listeleme" pageTitle="Finans" />
      <div className="card">
        <div className="card-body flex flex-col gap-5">
          <Tab.Container defaultActiveKey="homeIcon">
            <Nav className="flex flex-wrap w-full text-sm font-medium text-center border-b border-slate-200 dark:border-zink-500 nav-tabs">
              <Nav.Item eventKey="homeIcon" className="group">
                <Link
                  to="/finance/invoiceListing"
                  data-tab-toggle
                  data-target="homeIcon"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  <span className="align-middle">Benim Kestiğim Faturalar</span>
                </Link>
              </Nav.Item>
              <Nav.Item eventKey="ProfileIcon" className="group">
                <Link
                  to="#"
                  data-tab-toggle
                  data-target="ProfileIcon"
                  className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                >
                  <span className="align-middle">
                    a-Commerce'in Kestiği Faturalar
                  </span>
                </Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
          <form
            action="#!"
            className="flex flex-col gap-5"
            onSubmit={handleSubmit}
          >
            <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 xl:grid-cols-4">
              <div className="mb-4">
                <label
                  htmlFor="invoice_type"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Fatura Tipi
                </label>

                <Select
                  className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  id="invoice_type"
                  name="invoice_type"
                  data-choices
                  // data-choices-multiple-groups="true"
                  // isMulti
                  placeholder="Fatura tipi"
                  options={invoiceType}
                  onChange={(e: any) => handleChange(e, "invoice_type")}
                  value={inputValues.invoice_type}
                  isSearchable
                  isClearable
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="order_code"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Sipariş Numarası
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <input
                  type="search"
                  id="order_code"
                  name="order_code"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Sipariş numarası giriniz"
                  onChange={(e: any) =>
                    handleChange(e.target.value, "order_code")
                  }
                  value={inputValues.order_code}
                />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="start_date"
                  className="inline-flex items-center gap-1 mb-2 text-base font-medium"
                >
                  Başlangıç Tarihi
                  <div className="relative group group/item">
                    <Info size={14} className="text-blue-400" />
                    <div className="absolute group/edit invisible group-hover/item:visible group-hover/item:opacity-100 left-0 bottom-full mb-2 w-48 p-2 text-xs text-white bg-black rounded-lg opacity-0 transition-opacity duration-200 text-justify">
                      Seçeceğiniz başlangıç ve bitiş tarihinin aralığı 30 günü
                      geçmemelidir.
                    </div>
                  </div>
                </label>
                <Flatpickr
                  options={{
                    dateFormat: "d M, Y",
                    defaultDate: moment().subtract(30, 'days').toDate(),
                    locale: {
                      weekdays: {
                        longhand: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
                        shorthand: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt']
                      },
                      months: {
                        longhand: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
                        shorthand: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara']
                      },
                    }
                  }}
                  onChange={(e: any, date: string) =>
                    handleChange(
                      moment(date).format("YYYY-MM-DD"),
                      "start_date"
                    )
                  }
                  placeholder="Başlangıc Tarihi"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                />
                <CalendarDays className="absolute size-4 right-3 bottom-3 text-slate-500 dark:text-zink-200" />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="end_date"
                  className="inline-flex items-center gap-1 mb-2 text-base font-medium"
                >
                  Bitiş Tarihi
                  <div className="relative group group/item">
                    <Info size={14} className="text-blue-400" />
                    <div className="absolute group/edit invisible group-hover/item:visible group-hover/item:opacity-100 left-0 bottom-full mb-2 w-48 p-2 text-xs text-white bg-black rounded-lg opacity-0 transition-opacity duration-200 text-justify">
                      Seçeceğiniz başlangıç ve bitiş tarihinin aralığı 30 günü
                      geçmemelidir.
                    </div>
                  </div>
                </label>
                <Flatpickr
                  options={{
                    dateFormat: "d M, Y",
                    minDate: inputValues.start_date,
                    defaultDate: "today",
                    maxDate: "today",
                    locale: {
                      weekdays: {
                        longhand: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
                        shorthand: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt']
                      },
                      months: {
                        longhand: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
                        shorthand: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara']
                      },
                    }
                  }}
                  onChange={(e: any, date: string) =>
                    handleChange(moment(date).format("YYYY-MM-DD"), "end_date")
                  }
                  placeholder="Bitiş Tarihi"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                />
                <CalendarDays className="absolute size-4 right-3 bottom-3 text-slate-500 dark:text-zink-200" />
              </div>
            </div>
            <div className="flex justify-end gap-2">
              <button
                type="reset"
                onClick={() => clearState()}
                className="text-slate-500 btn bg-slate-200 border-slate-200 hover:text-slate-600 hover:bg-slate-300 hover:border-slate-300 focus:text-slate-600 focus:bg-slate-300 focus:border-slate-300 focus:ring focus:ring-slate-100 active:text-slate-600 active:bg-slate-300 active:border-slate-300 active:ring active:ring-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 dark:border-zink-600 dark:hover:border-zink-500 dark:text-zink-200 dark:ring-zink-400/50 md:w-[170px] w-full"
              >
                <span className="align-middle">Temizle</span>
              </button>
              <button
                type="submit"
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 md:w-[170px] w-full"
              >
                Filtrele
              </button>
            </div>
          </form>
        </div>
      </div>
      {loading && (
        <div className="flex flex-col items-center justify-center">
          <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-yellow-400"></div>
          <p>Yükleniyor...</p>
        </div>
      )}
      {!loading && error && (
        <div className="noresult">
          <div className="py-6 text-center">
            <NotFound title="Üzgünüm! Sonuç bulunamadı." />
          </div>
        </div>
      )}

      {data.length === 0 && !loading && !error && (
        <div className="noresult">
          <div className="py-6 text-center">
            <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
            <h5 className="mt-2 mb-1">Ekli ürün yok.</h5>
            <Link to="/addproduct">Ürün eklemek için buraya tıklayınız.</Link>
          </div>
        </div>
      )}

      {!loading && !error && data.length > 0 && (
        <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
          <div className="xl:col-span-12">
            <div className="card" id="usersTable">
              <div className="card-body">
                <TableContainer
                  isPagination={true}
                  columns={columns || []}
                  data={data || []}
                  customPageSize={10}
                  divclassName="-mx-5 -mb-5 overflow-x-auto"
                  tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                  theadclassName="text-center relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                  thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                  tdclassName="text-center px-3.5 py-2.5 first:pl-5 last:pr-5"
                  PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InvoiceListing;
