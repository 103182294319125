import { Camera, Pencil } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import ProfileModal from "Common/Components/account.tsx/ProfileModal";
import VerificationModal from "Common/Components/account.tsx/VerificationModal";
import { sendRequestWithCache } from "helpers/request";
import { useAppSelector } from "../../redux/hooks";
import { toastErrorNotify, toastSuccessNotify } from "helpers/ToasNotify";
import { getItem, setItem } from "helpers/localStorageControl";
import NotFound from "pages/finance/NotFound";
import PasswordModal from "Common/Components/account.tsx/PasswordModal";

export type OpenFunc = (title: string, modalTitle: string) => void;

const Profile: React.FC = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const [passModal, setPassModal] = useState({ isOpen: false });
  const [modal, setModal] = useState({
    title: "",
    isOpen: false,
    info: "",
    modalTitle: "",
  });
  const [currentData, setCurrentData] = useState({
    data: getItem("profile")?.data?.data[0] || [],
    loading: false,
    error: false,
  });
  const [twoFaModal, setTwoFaModal] = useState({
    isOpen: false,
    info: "",
    token: "",
  });
  const ref = useRef({ email: "", exPhone: "" });

  const [loading, setLoading] = useState(false);
  const { data } = currentData;

  // şifre-email-telefon numarası değiştirme modalını acma
  const openModal: OpenFunc = (modalTitle, title) => {
    setModal({ isOpen: true, title, modalTitle, info: "" });
  };

  // şifre-email-telefon numarası değiştirme modalını kapatma
  const offModal = () => {
    setModal({
      isOpen: false,
      title: "",
      info: "",
      modalTitle: "",
    });
  };

  // email değiştirme fonksiyonu
  const emailChange = async (formData: any) => {
    setLoading(true);
    try {
      const res = await sendRequestWithCache(
        "POST",
        `/user/change/email
    `,
        formData,
        {},
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );
      setLoading(false);
      offModal();
      setTwoFaModal({
        ...twoFaModal,
        isOpen: true,
        token: res.token,
        info: `${res.email} adresine`,
      });

      toastSuccessNotify(res.message);
    } catch (error) {
      toastErrorNotify(
        "Mevcut mail adresinize doğrulama kodu gönderilemedi !!!"
      );
      setLoading(false);
    }
  };
  const phoneChange = async (formData: any) => {
    setLoading(true);
    try {
      const res = await sendRequestWithCache(
        "POST",
        `/user/change/phone
    `,
        formData,
        {},
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );
      setLoading(false);
      offModal();
      setTwoFaModal({
        ...twoFaModal,
        isOpen: true,
        token: res.token,
        info: `+${res.phone_country}${res.phone_number} numaralı telefonuna`,
      });
      toastSuccessNotify(res.message);
    } catch (error: any) {
      toastErrorNotify(error.response.data.error);
      setLoading(false);
    }
  };
  // Doğrualama modalı
  const onHide = () => {
    setTwoFaModal({ isOpen: false, info: "", token: "" });
  };

  const verifyPost = async (
    formData: any,
    state: { value: string; loading: boolean; scope: number },
    setState: any
  ) => {
    setState({ ...state, loading: true });
    try {
      const res = await sendRequestWithCache(
        "POST",
        `/user/verify/email/stage/${state.scope}
    `,
        formData,
        {},
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );
      toastSuccessNotify(res.message);

      if (state.scope === 1) {
        setState({ ...state, loading: false, scope: 2, value: "" });
        setTwoFaModal({
          ...twoFaModal,
          token: res.token,
          info: `${res.email} adresine`,
        });
        ref.current.email = res.email;
      } else {
        const newData = { ...currentData.data, email: ref.current.email };
        const existingData = getItem("profile");
        existingData.data.data = newData;
        setItem("profile", existingData);
        setCurrentData({
          ...currentData,
          data: newData,
        });

        setState({ ...state, loading: false, scope: 1, value: "" });
        onHide();
      }
    } catch (err: any) {
      toastErrorNotify(err.response.data.error);
      setState({ ...state, loading: false, scope: 1 });
    }
  };
  const getPofile = async () => {
    setCurrentData({ ...currentData, loading: true, error: false });
    try {
      const { data } = await sendRequestWithCache(
        "GET",
        `/user/profile
    `,
        null,
        {
          cache: true,
          cacheName: "profile",
          cacheTime: 300,
        },
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );
      setCurrentData({
        ...currentData,
        data: data[0],
        loading: false,
        error: false,
      });
    } catch (error) {
      setCurrentData({ ...currentData, loading: false, error: true });
    }
  };

  const verifyPhone = async (
    formData: any,
    state: { value: string; loading: boolean; scope: number },
    setState: any
  ) => {
    setState({ ...state, loading: true });
    try {
      const res = await sendRequestWithCache(
        "POST",
        `/user/verify/phone/stage/${state.scope}
    `,
        formData,
        {},
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );
      if (state.scope === 1) {
        setTwoFaModal({
          ...twoFaModal,
          token: res.token,
          info: `+${res.phone_country}${res.phone_number}  numaralı telefonuna`,
        });
        ref.current.exPhone = res.phone_number;
        return setState({ ...state, loading: false, scope: 2, value: "" });
      } else {
        const newData = {
          ...currentData.data,
          phone_number: ref.current.exPhone,
        };
        const existingData = getItem("profile");
        existingData.data.data = newData;
        setItem("profile", existingData);
        setCurrentData({
          ...currentData,
          data: newData,
        });

        setState({ ...state, loading: false, scope: 1, value: "" });
        onHide();
      }
      toastSuccessNotify(res.message);
      setState({ ...state, loading: false, scope: 1, value: "" });
    } catch (err: any) {
      toastErrorNotify(err.response.data.error);
      setState({ ...state, loading: false, scope: 1 });
    }
  };

  useEffect(() => {
    getPofile();
  }, []);
  //  Pasword modalını açma
  const passwordOpen = () => {
    setPassModal({ ...passModal, isOpen: true });
  };
  // Pasword modalını kapatma
  const passwordOnHide = () => {
    setPassModal({ ...passModal, isOpen: false });
  };

  return (
    <>
      {!currentData.loading && !currentData.error && (
        <>
          <div className="flex items-center gap-4 relative">
            <div className="w-12 h-12 rounded-full relative">
              <img
                src={data?.avatar}
                alt="avatar"
                className="w-full h-full rounded-full"
              />
              <div className="absolute right-[-15px] bottom-[-20px] w-7 h-7">
                <Camera className="" />
              </div>
            </div>
            <h6 className="uppercase">{data?.full_name}</h6>
          </div>
          <div className="flex flex-col gap-5 mt-4">
            <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 xl:w-2/3">
              <div className="mb-4 relative">
                <label
                  htmlFor="productCode"
                  className="inline-block mb-2 text-base font-medium"
                >
                  E-posta Adresi
                </label>
                <input
                  type="text"
                  id="productCode"
                  name="productCode"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Eposta adresi giriniz"
                  //   onChange={handleChange}
                  defaultValue={data?.email}

                  // defaultValue="Sophia"
                  // required
                />
                <button
                  type="button"
                  className="absolute top-10 right-2"
                  onClick={() => openModal("E-Posta Adresi", "E-Posta Adresim")}
                >
                  <Pencil size="20" />
                </button>
              </div>
              <div className="mb-4 relative">
                <label
                  htmlFor="title"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Telefon Numarası
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Ürün adını giriniz"
                  defaultValue={data?.phone_number}
                />
                <button
                  type="button"
                  className="absolute top-10 right-2"
                  onClick={() =>
                    openModal("Telefon Numaramı", "Telefon Numaram")
                  }
                >
                  <Pencil size="20" />
                </button>
              </div>
              <div className="mb-4 relative">
                <label
                  htmlFor="title"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Şifre
                </label>
                <input
                  type="password"
                  id="title"
                  name="title"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Ürün adını giriniz"
                  // defaultValue="Bethany"
                  //   onChange={handleChange}
                  // required

                  defaultValue="534***1242"
                />
                <button
                  type="button"
                  className="absolute top-10 right-2"
                  onClick={passwordOpen}
                >
                  <Pencil size="20" />
                </button>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="#"
                  className="inline-block mb-2 text-base font-medium"
                >
                  İki Adımla Doğrulama
                </label>
                <div className="flex gap-4">
                  <div className="flex flex-col gap-3">
                    <div className="flex items-center">
                      <div className="relative inline-block w-10 align-middle transition duration-200 ease-in ltr:mr-2 rtl:ml-2">
                        <input
                          type="checkbox"
                          name="ePosta"
                          id="ePosta"
                          className="absolute block size-5 transition duration-300 ease-linear border-2 rounded-full appearance-none cursor-pointer border-slate-200 dark:border-zink-500 bg-white/80 dark:bg-zink-400 peer/published checked:bg-white dark:checked:bg-white ltr:checked:right-0 rtl:checked:left-0 checked:bg-none checked:border-custom-500 dark:checked:border-custom-500 arrow-none"
                          defaultChecked
                        />
                        <label
                          htmlFor="ePosta"
                          className="block h-5 overflow-hidden duration-300 ease-linear border rounded-full cursor-pointer cursor-pointertransition border-slate-200 dark:border-zink-500 bg-slate-200 dark:bg-zink-600 peer-checked/published:bg-custom-500 peer-checked/published:border-custom-500"
                        ></label>
                      </div>
                      <label
                        htmlFor="ePosta"
                        className="inline-block text-base font-medium cursor-pointer"
                      >
                        E-posta
                      </label>
                    </div>
                    <div className="flex items-center">
                      <div className="relative inline-block w-10 align-middle transition duration-200 ease-in ltr:mr-2 rtl:ml-2">
                        <input
                          type="checkbox"
                          name="phoneNumber"
                          id="phoneNumber"
                          className="absolute block size-5 transition duration-300 ease-linear border-2 rounded-full appearance-none cursor-pointer border-slate-200 dark:border-zink-500 bg-white/80 dark:bg-zink-400 peer/published checked:bg-white dark:checked:bg-white ltr:checked:right-0 rtl:checked:left-0 checked:bg-none checked:border-custom-500 dark:checked:border-custom-500 arrow-none"
                          defaultChecked
                        />
                        <label
                          htmlFor="phoneNumber"
                          className="block h-5 overflow-hidden duration-300 ease-linear border rounded-full cursor-pointer cursor-pointertransition border-slate-200 dark:border-zink-500 bg-slate-200 dark:bg-zink-600 peer-checked/published:bg-custom-500 peer-checked/published:border-custom-500"
                        ></label>
                      </div>
                      <label
                        htmlFor="phoneNumber"
                        className="inline-block text-base font-medium cursor-pointer"
                      >
                        Telefon Numarası
                      </label>
                    </div>
                  </div>
                  <div className="flex-1">
                    <p className="text-xs text-gray-400 break-all">
                      İki adımlı doğrulama yöntemini etkinleştirdiğinizde,
                      yalnızca kişisel şifrenizle değil, aynı zamanda kayıtlı
                      cep telefonunuza veya e-postanıza gönderilen doğrulama
                      koduyla da oturum açmanız gerekecektir.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {currentData.loading && (
        <div className="flex flex-col items-center justify-center">
          <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-yellow-400"></div>
          <p>Yükleniyor...</p>
        </div>
      )}
      {!currentData.loading && currentData.error && (
        <div className="noresult">
          <div className="py-6 text-center">
            <NotFound title="Üzgünüm! Sonuç bulunamadı." />
          </div>
        </div>
      )}
      {modal.isOpen && (
        <ProfileModal
          modal={modal}
          offModal={offModal}
          emailChange={emailChange}
          loading={loading}
          phoneChange={phoneChange}
        />
      )}

      {twoFaModal.isOpen && (
        <VerificationModal
          twoFaModal={twoFaModal}
          onHide={onHide}
          verifyPost={verifyPost}
          verifyPhone={verifyPhone}
        />
      )}
      {passModal.isOpen && <PasswordModal passwordOnHide={passwordOnHide} />}
    </>
  );
};

export default Profile;
