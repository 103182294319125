import React, { useMemo, useState } from "react";
import { ToastContainer } from "react-toastify";
import { PackageSearch, MoreHorizontal, Search } from "lucide-react";

import DetailModal from "./DetailModal";
import TableContainer from "Common/TableContainer";
import { Dropdown } from "Common/Components/Dropdown";
import { Link } from "react-router-dom";

interface SaledDataProps {
  ProductListViewData: {
    orderInfo: string;
    orderDate: string;
    packageNo: string;
    receiver: string;
    img: string;
    productInfo: string;
    designation: string;
    unitPrice: string;
    cargo?: string;
    cargoCode?: string;
    quantity: string;
    invoice: string;
  }[];
  dropdownOptions: { value: string; icon: Object; transaction: string | null }[];
}

const SaledData: React.FC<SaledDataProps> = ({
  ProductListViewData,
  dropdownOptions,
}) => {

  const [detailModalShow, setDetailModalShow] = useState(false);

  const columns = useMemo(
    () => [
      {
        header: (
          <div className="flex items-center h-full">
            <input
              id="CheckboxAll"
              className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
              type="checkbox"
            />
          </div>
        ),
        enableSorting: false,
        id: "checkAll",
        cell: (cell: any) => {
          return (
            <div className="flex items-center h-full">
              <input
                id="Checkbox1"
                className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
                type="checkbox"
              />
            </div>
          );
        },
      },
      {
        header: 'Sipariş Bilgileri',
        accessorKey: 'orderInfo',
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2 w-full overflow-hidden">
            <div className="grow">
              <h6 className="mb-1 text-blue-400 font-normal">
                {cell.getValue()}
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                Sipariş Tarihi:
              </p>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.orderDate}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: 'Paket No',
        accessorKey: 'packageNo',
        enableColumnFilter: false,
      },
      {
        header: 'Alıcı',
        accessorKey: 'receiver',
        enableColumnFilter: false,
      },
      {
        header: 'Ürün Bilgileri',
        accessorKey: 'productInfo',
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2 w-full overflow-hidden">
            <div className="h-32 w-32 flex items-center justify-center">
              <img
                src={cell.row.original.img}
                alt=""
                className="h-full rounded"
              />
            </div>
            <div className="grow">
              <h6 className="mb-1 ">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                Ürün Kodu: {cell.row.original.designation}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: 'Birim Fiyat',
        accessorKey: 'unitPrice',
        enableColumnFilter: false,
      },
      {
        header: 'Kargo',
        accessorKey: 'cargo',
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2 w-full overflow-hidden">
            {
              cell.row.original.cargoCode && cell.row.original.cargo && (
                <div className="grow">
                  <div className="h-12 flex items-center justify-center ">
                    <img
                      src={cell.getValue()}
                      alt=""
                      className="h-full rounded"
                    />
                  </div>
                  <p className="text-slate-500 dark:text-zink-200 text-center">
                    Kargo Kodu:
                  </p>
                  <p className="text-slate-500 dark:text-zink-200 text-center">
                    {cell.row.original.cargoCode}
                  </p>
                </div>
              )
            }
          </div>
        ),
      },
      {
        header: 'Adet',
        accessorKey: 'quantity',
        enableColumnFilter: false,
      },
      {
        header: 'Fatura',
        accessorKey: 'invoice',
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div>
            <p className="text-slate-500 dark:text-zink-200 text-center">
              Toplam Tutar:
            </p>
            <h6 className="mb-1 font-normal text-center">
              {cell.getValue()}
            </h6>
          </div>
        ),
      },
      {
        header: 'Durum',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <Dropdown className="relative">
            <Dropdown.Trigger
              className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
              id="action1"
            >
              <MoreHorizontal className="size-3" />
            </Dropdown.Trigger>
            <Dropdown.Content
              placement="right-end"
              className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600"
              aria-labelledby="action1"
            >
              {
                dropdownOptions.map((option, index) => (
                  <li key={index}>
                    <Link
                      className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                      to={option.transaction === 'detail' ? `/sales-detail/${cell.row.original.designation}` : '#'}
                      onClick={() => {
                        option.transaction === 'cargoModal' && setDetailModalShow(true);
                      }}
                    >
                      <PackageSearch className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                      <span className="align-middle">{option.value}</span>
                    </Link>
                  </li>
                ))
              }
            </Dropdown.Content>
          </Dropdown>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <DetailModal
        show={detailModalShow}
        onHide={() => setDetailModalShow(!detailModalShow)}
      />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card">
            <div className="card-body">
              {ProductListViewData.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns || []}
                  data={ProductListViewData}
                  customPageSize={10}
                  divclassName="-mx-5 -mb-5 overflow-x-auto"
                  tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                  theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                  thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                  tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                  PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                  isSelect
                  title="Sipariş Tarihi"
                  downloadExcel
                />
              ) : (
                <div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                    <h5 className="mt-2 mb-1">Üzgünüm! Sonuç bulunamadı...</h5>
                    <p className="mb-0 text-slate-500 dark:text-zink-200">
                      Burası boş
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SaledData;
