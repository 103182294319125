import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from "../../../redux/hooks";
import { TrendingUp } from 'lucide-react';
import CountUp from 'react-countup';
import { SalesMonthChart } from './Charts';
import { sendRequestWithCache } from 'helpers/request';

const SalesMonth = () => {
    const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
    const [loading, setLoading] = useState(true);
    const [series, setSeries] = useState<[{ type: string; name: string; data: Array<{ x: string; y: number }>; }]>([{ type: 'line', name: '', data: [] }]);

    const fetchMonthlyOrders = useCallback(async () => {
        try {
            const { data } = await sendRequestWithCache("GET", "/user/dashboard/monthlyOrders", null, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });
            const months = ['Ock', 'Şbt', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'];
            const filteredOrders = Object.values(data).map((order: any) => ({
                x: months[order.month - 1],
                y: order.total_sales
            }));

            setSeries([
                {
                    type: 'line',
                    name: '',
                    data: filteredOrders
                }
            ]);
        } catch (error) {
            return error
        } finally {
            setLoading(false);
        }
    }, [bearer_token]);

    useEffect(() => {
        fetchMonthlyOrders();
    }, [fetchMonthlyOrders]);

    return (
        <React.Fragment>
            <div className="col-span-12 card lg:col-span-6 2xl:col-span-3 relative">
                {loading && (
                    <div className="flex items-center justify-center h-full absolute inset-0">
                        <div className="flex flex-col items-center">
                            <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-green-400"></div>
                        </div>
                    </div>
                )}
                <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                    <div className="flex items-center mb-3">
                        <h6 className="grow text-15">Aylık Satışlar</h6>
                    </div>
                    <div className="flex items-center gap-3 my-3">
                        <div className="flex items-center justify-center size-12 text-green-500 rounded-md bg-green-50 shrink-0 dark:bg-green-500/10">
                            <TrendingUp />
                        </div>
                        <div className="grow">
                            <p className="mb-1 text-slate-500 dark:text-zink-200">Bu Aykı Toplam Hakediş</p>
                            <h5 className="text-15"><CountUp end={series[0].data.reduce((sum, item) => sum + Number(item.y), 0)} decimals={2} className="counter-value" /> ₺</h5>
                        </div>
                    </div>
                    <SalesMonthChart chartId="salesThisMonthChart" series={series} />
                </div>

            </div>
        </React.Fragment>
    );
};

export default SalesMonth;
