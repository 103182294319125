import React, { useEffect } from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import SalesChart from './SalesChart';
import SalesTransactions from './SalesTransactions';

const Sales = () => {
    const path = window.location.pathname;
    const [deliveryDate, setDeliveryDate] = React.useState(false);

    useEffect(() => {
        if (path.includes("/all-sales") || path.includes("/delivered-sales")) {
            setDeliveryDate(true);            
        } else {
            setDeliveryDate(false);
        }
    }, [path]);


    return (
        <React.Fragment>
            <BreadCrumb title='Siparişler' pageTitle='Siparişler & Kargo' />
            <SalesChart />
            <SalesTransactions deliveryDate={deliveryDate} />
        </React.Fragment>
    );
};

export default Sales;
