import iphone from "assets/images/product/iphone.png";
import cargo from "assets/images/product/cargo.png";
import SaledData from "./SaledData";
import { X } from "lucide-react";

const TransportedSales = () => {
    const ProductListViewData = [
        {
            orderInfo: "#TWT5015100365",
            orderDate: "26 Nisan 2022 20:12",
            packageNo: "2157",
            receiver: "Emin Yormaz",
            img: iphone,
            productInfo: "İphone 15 Pro Max, Mavi Titanyum",
            designation: "6",
            unitPrice: "15.00₺",
            cargo: cargo,
            cargoCode: "7320004301539634",
            quantity: "1",
            invoice: "200",
        },
        {
            orderInfo: "#TWT5015100365",
            orderDate: "26 Nisan 2022 19:21",
            packageNo: "2143",
            receiver: "Onur Yücel",
            img: iphone,
            productInfo: "İphone 15 Pro Max, Mavi Titanyum",
            designation: "6",
            unitPrice: "15.00₺",
            cargo: cargo,
            cargoCode: "7320004301539634",
            quantity: "1",
            invoice: "200",
        },
        {
            orderInfo: "#TWT5015100365",
            orderDate: "26 Nisan 2022 19:15",
            packageNo: "2127",
            receiver: "Burak Üstün",
            img: iphone,
            productInfo: "İphone 15 Pro Max, Mavi Titanyum",
            designation: "6",
            unitPrice: "15.00₺",
            cargo: cargo,
            cargoCode: "7320004301539634",
            quantity: "1",
            invoice: "200",
        },
        {
            orderInfo: "#TWT5015100365",
            orderDate: "26 Nisan 2022 18:28",
            packageNo: "2113",
            receiver: "Hüseyin Şahin",
            img: iphone,
            productInfo: "İphone 15 Pro Max, Mavi Titanyum",
            designation: "6",
            unitPrice: "15.00₺",
            cargo: cargo,
            cargoCode: "7320004301539634",
            quantity: "1",
            invoice: "200",
        },
        {
            orderInfo: "#TWT5015100365",
            orderDate: "26 Nisan 2022 16:33",
            packageNo: "2107",
            receiver: "Uygar Ceylan",
            img: iphone,
            productInfo: "İphone 15 Pro Max, Mavi Titanyum",
            designation: "6",
            unitPrice: "15.00₺",
            cargo: cargo,
            cargoCode: "7320004301539634",
            quantity: "1",
            invoice: "200",
        },
    ];
    
    const dropdownOptions = [
        {
            value: "Siparişi Görüntüle",
            icon: X,
            transaction: 'detail'
        },
        {
            value: "Kargo Takibi",
            icon: X,
            transaction: null,
        },
    ];

    return (
        <SaledData ProductListViewData={ProductListViewData} dropdownOptions={dropdownOptions} />
    )
}

export default TransportedSales;