import Alert from "Common/Components/Alert";
import Modal from "Common/Components/Modal";
import iphone from "assets/images/product/iphone.png";
import { Globe, SendHorizontal } from "lucide-react";

interface RepliesModalProp {
  extraLargeModal: boolean;
  extraLargeToggle: any;
}

const RepliesModal = ({
  extraLargeModal,
  extraLargeToggle,
}: RepliesModalProp) => {
  return (
    <Modal
      show={extraLargeModal}
      onHide={extraLargeToggle}
      id="extraLargeModal"
      modal-center="true"
      className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
      dialogClassName="w-screen lg:w-[55rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full"
    >
      <Modal.Header
        className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500"
        closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500 dark:text-zink-200 dark:hover:text-red-500"
      >
        <Modal.Title className="text-16">Soruyu Cevapla</Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
        <Alert className="px-4 py-3 text-sm border border-transparent rounded-md text-custom-500 bg-custom-50 dark:bg-custom-400/20 border-t-2 border-t-custom-500">
          <p>
            <Alert.Bold>'Lenovo LEGION'</Alert.Bold> adlı ürününüze gelen soruyu
            cevaplamak üzeresiniz.
          </p>
          <p>
            Soruları düzenli ve özverili cevaplamanız müşteri deneyiminde etkili
            olacaktır.
          </p>
        </Alert>
        <div className="flex gap-4 p-4">
          <div className="w-[200px]">
            <img src={iphone} alt="pic" />
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-lg">Ne zaman stok güncellenir ?</p>
            <p className="text-gray-400">
              Onur Yücel Tarafından 22 Nisan 2020 22:22 tarihinde yazıldı.
            </p>
          </div>
        </div>
        <div className="flex justify-between items-center text-custom-500 mb-4">
          <p className="">Müşteriye Cevabınız</p>
          <p className="flex gap-1 items-center">
            <Globe className=" dark:text-zink-200" />
            Herkes görebilir
          </p>
        </div>

        <div className="relative">
          <SendHorizontal className="absolute size-4 ltr:right-3 rtl:left-3 bottom-3 text-slate-500 dark:text-zink-200" />
          <textarea
            // type="email"
            id="inputIconRight"
            className="ltr:pr-10 rtl:pl-10 form-input h-20 border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200 placeholder:text-start resize-none"
            placeholder="Yanıtla"
          />
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="flex items-center justify-between p-4 mt-auto border-t border-slate-200 dark:border-zink-500">
          <h5 className="text-16">Modal Footer</h5>
        </Modal.Footer> */}
    </Modal>
  );
};

export default RepliesModal;
