import React, { useCallback, useEffect, useState } from 'react';
import { sendRequestWithCache } from 'helpers/request';
import { useAppSelector } from "../../../redux/hooks";
import { Package, PackageX, Truck, Wallet2 } from 'lucide-react';
import CountUp from 'react-countup';

interface Statistics {
    cancelOrders: number;
    orders: number;
    profit: string;
    waitingCargo: number;
}

const MediumWidgets = () => {
    const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
    const [statisticsData, setStatisticsData] = useState<Statistics>();
    const [loading, setLoading] = useState(true);

    const fetchStatistics = useCallback(async () => {
        try {
            const { data } = await sendRequestWithCache("GET", "/user/dashboard/stats", null, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });
            setStatisticsData(data);
        } catch (error) {
            return error
        } finally {
            setLoading(false);
        }
    }, [bearer_token]);

    const loadingDiv = (color: string) => (
        <div className="flex items-center justify-center h-full absolute inset-0">
            <div className="flex flex-col items-center">
                <div className={`inline-flex size-10 rounded-full opacity-75 animate-bounce ${color}`}></div>
            </div>
        </div>
    );

    useEffect(() => {
        fetchStatistics();
    }, [fetchStatistics]);
    return (
        <React.Fragment>
            <div className="col-span-12 card md:col-span-6 lg:col-span-3 2xl:col-span-2 relative">
                {loading && (
                    loadingDiv("bg-custom-400")
                )}
                <div className={`text-center card-body ${loading ? 'opacity-0' : ''}`}>
                    <div className="flex items-center justify-center mx-auto rounded-full size-14 bg-custom-100 text-custom-500 dark:bg-custom-500/20">
                        <Wallet2 />
                    </div>
                    <h5 className="mt-4 mb-2">
                        <CountUp end={Number(statisticsData?.profit)} decimals={2} className="counter-value" />  ₺
                    </h5>
                    <p className="text-slate-500 dark:text-zink-200">Toplam Hakediş</p>
                </div>

            </div >
            <div className="col-span-12 card md:col-span-6 lg:col-span-3 2xl:col-span-2 relative">
                {loading && (
                    loadingDiv("bg-purple-400")
                )}
                <div className={`text-center card-body ${loading ? 'opacity-0' : ''}`}>
                    <div className="flex items-center justify-center mx-auto text-purple-500 bg-purple-100 rounded-full size-14 dark:bg-purple-500/20">
                        <Package />
                    </div>
                    <h5 className="mt-4 mb-2"><CountUp end={Number(statisticsData?.orders)} className="counter-value" /></h5>
                    <p className="text-slate-500 dark:text-zink-200">Toplam Sipariş</p>
                </div>

            </div>
            <div className="col-span-12 card md:col-span-6 lg:col-span-3 2xl:col-span-2 relative">
                {loading && (
                    loadingDiv("bg-green-400")
                )}
                 <div className={`text-center card-body ${loading ? 'opacity-0' : ''}`}>
                    <div className="flex items-center justify-center mx-auto text-green-500 bg-green-100 rounded-full size-14 dark:bg-green-500/20">
                        <Truck />
                    </div>
                    <h5 className="mt-4 mb-2"><CountUp end={Number(statisticsData?.waitingCargo)} className="counter-value" /></h5>
                    <p className="text-slate-500 dark:text-zink-200">Kargo Bekleyen</p>
                </div>

            </div>
            <div className="col-span-12 card md:col-span-6 lg:col-span-3 2xl:col-span-2 relative">
                {loading && (
                    loadingDiv("bg-red-400")
                )}
               <div className={`text-center card-body ${loading ? 'opacity-0' : ''}`}>
                    <div className="flex items-center justify-center mx-auto text-red-500 bg-red-100 rounded-full size-14 dark:bg-red-500/20">
                        <PackageX />
                    </div>
                    <h5 className="mt-4 mb-2"><CountUp end={Number(statisticsData?.cancelOrders)} className="counter-value" /></h5>
                    <p className="text-slate-500 dark:text-zink-200">İptal Edilen</p>
                </div>
            </div>
        </React.Fragment >
    );
};

export default MediumWidgets;
