import React, { useCallback, useEffect, useMemo, useState } from "react";
import { sendRequestWithCache } from 'helpers/request';
import { useAppSelector } from "../../redux/hooks";
import { ToastContainer } from "react-toastify";
import TableContainer from "Common/TableContainer";
import NotFound from "../../Common/Components/NotFound/NotFound";
import moment from "moment";

interface CategoryBasedSalesReportsTableProps {
    filter: {
        startDate: string;
        endDate: string;
    }
}

const CategoryBasedSalesReportsTable = ({ filter }: CategoryBasedSalesReportsTableProps) => {
    const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
    const [loading, setLoading] = useState(true);
    const [reportSalesSummary, setReportSalesSummary] = useState([])

    const formatPrice = (price: number) => {
        if (typeof price !== "number") return '0,00₺'
        return new Intl.NumberFormat("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price) + "₺";
    };

    const list = reportSalesSummary.map((item: {
        category_name: string;
        total_sales: number;
        total_cancellations: number;
        total_returns: number;
        net_sales: number;
        gross_revenue: number;
        net_revenue: number;
        total_commission_amount: number;
        average_commission_amount: number;
        average_sale_price: number;
        current_stock: number;
    }) => {
        return {
            category: item.category_name,
            grossSalesQuantity: item.total_sales,
            numberOfCancellations: item.total_cancellations,
            numberOfReturns: item.total_returns,
            netSalesQuantity: item.net_sales,
            grossTurnover: formatPrice(item.gross_revenue),
            netTurnover: formatPrice(item.net_revenue),
            totalCommissionAmount: formatPrice(item.total_commission_amount),
            averageCommissionAmount: formatPrice(item.average_commission_amount),
            averageSalesPrice: formatPrice(item.average_sale_price),
            currentStock: item.current_stock
        }
    })

    const fetchReportSalesSummary = useCallback(async () => {
        setLoading(true);
        const params = new URLSearchParams({
            start_date: filter.startDate,
            end_date: filter.endDate,
            scope: 'category',
        });

        try {
            const response = await sendRequestWithCache("POST", `/reports/sales/summary?${params}`, null, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });
            setReportSalesSummary(response);
        } catch (error) {
            return error
        } finally {
            setLoading(false);
        }
    }, [filter, bearer_token]);

    useEffect(() => {
        fetchReportSalesSummary();
    }, [filter, fetchReportSalesSummary]);

    const columns = useMemo(
        () => [

            {
                header: "Kategori",
                accessorKey: "category",
                enableColumnFilter: false,
            },
            {
                header: "Brüt Satış Adedi",
                accessorKey: "grossSalesQuantity",
                enableColumnFilter: false,
            },
            {
                header: "İptal Adedi",
                accessorKey: "numberOfCancellations",
                enableColumnFilter: false,
            },
            {
                header: "İade Adedi",
                accessorKey: "numberOfReturns",
                enableColumnFilter: false,
            },
            {
                header: "Net Satış Adedi",
                accessorKey: "netSalesQuantity",
                enableColumnFilter: false,
            },
            {
                header: "Brüt Ciro",
                accessorKey: "grossTurnover",
                enableColumnFilter: false,
                cell: (cell: any) => {
                    return (
                        <div className="mx-6">
                            {cell.getValue()}
                        </div>
                    )
                }
            },
            {
                header: "Net Ciro",
                accessorKey: "netTurnover",
                enableColumnFilter: false,
                cell: (cell: any) => {
                    return (
                        <div className="mx-6">
                            {cell.getValue()}
                        </div>
                    )
                }
            },
            {
                header: "Toplam Komisyon Tutarı",
                accessorKey: "totalCommissionAmount",
                enableColumnFilter: false,
            },
            {
                header: "Ortalama Komisyon Tutarı",
                accessorKey: "averageCommissionAmount",
                enableColumnFilter: false,
            },
            {
                header: "Ortalama Satış Fiyatı",
                accessorKey: "averageSalesPrice",
                enableColumnFilter: false,
            },
            {
                header: "Güncel Stok",
                accessorKey: "currentStock",
                enableColumnFilter: false,
            }
        ],
        []
    );

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} limit={1} />
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
                <div className="xl:col-span-12">
                    <div className="card relative">
                        {loading && (
                            <div className="flex items-center justify-center h-full absolute inset-0">
                                <div className="flex flex-col items-center">
                                    <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                                </div>
                            </div>
                        )}
                        <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                            <React.Fragment>
                                <div className="md:flex justify-between items-center">
                                    <div className="mb-2">
                                        <h6 className="text-xl font-normal">Kategori Bazlı Satış Raporları</h6>
                                    </div>
                                    <div className="mb-2 flex sm:items-center items-start flex-col sm:flex-row">
                                        <div className="sm:text-end text-start mb-1 sm:mr-4 mr-0 sm:ml-auto">
                                            <p className="text-sm">Filtreleme Sonuçları: Toplam {list.length} yorum bilgisi</p>
                                            <span className="text-sm text-gray-400">Son Güncellenme: {moment().format('DD MMMM YYYY HH:mm')}</span>
                                        </div>
                                        <div>
                                            <div className="relative flex-1">
                                            </div>
                                            <div className='self-center place-self-end'>
                                                <button
                                                    type="button"
                                                    className="btn border-slate-200 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 mb-2"
                                                >
                                                    Excel ile indir{" "}
                                                    <i className="align-baseline ltr:pr-1 rtl:pl-1 ri-download-2-line"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                            {list.length > 0 ? (
                                <TableContainer
                                    isPagination={true}
                                    columns={columns}
                                    data={list}
                                    customPageSize={10}
                                    divclassName="-mx-5 -mb-5 overflow-x-auto"
                                    tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                                    theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                                    trclassName="transition-all hover:bg-slate-100 dark:hover:bg-zink-600"
                                    thclassName="px-3 py-2.5 first:pl-5 last:pr-5 font-semibold text-center"
                                    tdclassName="px-3 py-2.5 first:pl-5 last:pr-5 text-center"
                                    PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                                />
                            ) : (
                                <NotFound />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default CategoryBasedSalesReportsTable;