import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import TableContainer from 'Common/TableContainer';
// import { ProductOrdersData } from "Common/data";
import { sendRequestWithCache } from "../../../helpers/request";
import { CalendarRange } from 'lucide-react';
import Flatpickr from 'react-flatpickr';
import { Turkish } from "flatpickr/dist/l10n/tr";

interface IProductOrders {
    id: number;
    OrderId: string;
    customerName: string;
    location: string;
    orderDate: string;
    payments: string;
    quantity: number;
    price: number;
    subTotal: number;
    status: string;
}

const ProductsOrders = () => {
    const { bearer_token } = useSelector((state: any) => state.auth.currentUser);
    const [ProductOrdersData, setProductOrdersData] = useState(Array(10).fill({}));
    const [loading, setLoading] = useState(true);

    const formatPrice = (price: number) => {
         if (typeof price !== "number") return '0,00₺'
        return new Intl.NumberFormat("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price) + "₺";
    };

    const fetchProductOrders = useCallback(async () => {
        try {
            const { data } = await sendRequestWithCache("GET", "/user/dashboard/orders", null, {}, {
                headers: {
                    Authorization: `Bearer ${bearer_token}`,
                },
            });
            const convertedData = data.map((item: IProductOrders) => ({
                id: item.id,
                orderId: item.OrderId,
                customerName: item.customerName,
                location: item.location,
                orderDate: item.orderDate,
                payments: item.payments,
                quantity: item.quantity,
                price: formatPrice(item.price),
                totalAmount: formatPrice(item.subTotal),
                status: item.status
            }));
            setProductOrdersData(convertedData);
        } catch (error) {
            return error;
        } finally {
            setLoading(false);
        }
    }, [bearer_token]);

    useMemo(() => {
        fetchProductOrders();
    }, [fetchProductOrders]);

    const dateConverter = (date: string) => {
        if (!date) return;
        const convertedDate = new Date(date);
        return convertedDate.toLocaleDateString();
    }

    const columns = useMemo(() => [
        {
            header: "Sipariş Numarası",
            accessorKey: "orderId",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => (
                <>
                    #{cell.row.original.orderId}
                </>
            ),
        },
        {
            header: "Müşteri Adı",
            accessorKey: "customerName",
            enableColumnFilter: false,
            enableSorting: true,
        },
        {
            header: "Adres",
            accessorKey: "location",
            enableColumnFilter: false,
            enableSorting: true,
        },
        {
            header: "Sipariş Tarihi",
            accessorKey: "orderDate",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => (
                <>
                    {dateConverter(cell.row.original.orderDate)}
                </>
            ),
        },
        {
            header: "Ödeme Tipi",
            accessorKey: "payments",
            enableColumnFilter: false,
            enableSorting: true,
        },
        {
            header: "Miktar",
            accessorKey: "quantity",
            enableColumnFilter: false,
            enableSorting: true,
        },
        {
            header: "Tutar",
            accessorKey: "price",
            enableColumnFilter: false,
            enableSorting: true,
        },
        {
            header: "Toplam Tutar",
            accessorKey: "totalAmount",
            enableColumnFilter: false,
            enableSorting: true,
        },
        {
            header: "Durum",
            accessorKey: "status",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => (
                <>
                    {cell.row.original.status === "Teslim Edildi" ? (
                        <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-green-100 border-green-200 text-green-500 dark:bg-green-500/20 dark:border-green-500/20">
                            {cell.row.original.status}
                        </span>
                    ) : cell.row.original.status === "Kargoda" ? (
                        <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-purple-100 border-purple-200 text-purple-500 dark:bg-purple-500/20 dark:border-purple-500/20">
                            {cell.row.original.status}
                        </span>
                    ) : cell.row.original.status === "Yeni" ? (
                        <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-sky-100 border-sky-200 text-sky-500 dark:bg-sky-500/20 dark:border-sky-500/20">
                            {cell.row.original.status}
                        </span>
                    ) : (
                        <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-yellow-100 border-yellow-200 text-yellow-500 dark:bg-yellow-500/20 dark:border-yellow-500/20">
                            {cell.row.original.status}
                        </span>
                    )
                    }

                </>
            ),
        },
    ], []
    );

    return (
        <React.Fragment>
            <div className="col-span-12 card 2xl:col-span-12 relative">
                {loading && (
                    <div className="flex items-center justify-center h-full absolute inset-0">
                        <div className="flex flex-col items-center">
                            <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-custom-500"></div>
                        </div>
                    </div>
                )}
                <div className={`card-body ${loading ? 'opacity-0' : ''}`}>
                    <div className="md:flex justify-between items-center">
                        <div className="mb-2">
                            <h6 className="text-15">Sipariş Önizlemesi</h6>
                        </div>
                        <div className="mb-2">
                            <div className="flex justify-end gap-3">
                                <div className="relative flex-1">
                                    <CalendarRange className="absolute size-4 ltr:left-3 rtl:right-3 top-3 text-slate-500 dark:text-zink-200"></CalendarRange>
                                    <Flatpickr
                                        className="ltr:pl-10 rtl:pr-10 form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        options={{
                                            dateFormat: "d M, Y",
                                            mode: "range",
                                            locale: Turkish,
                                        }}
                                        placeholder='Tarih Aralığı Seçin'
                                    />
                                </div>
                                <div className='self-center place-self-end'>
                                    <button
                                        type="button"
                                        className="btn border-slate-200 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 mb-2"
                                    >
                                        Excel ile indir{" "}
                                        <i className="align-baseline ltr:pr-1 rtl:pl-1 ri-download-2-line"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TableContainer
                        isPagination={true}
                        columns={(columns)}
                        data={(ProductOrdersData)}
                        customPageSize={7}
                        divclassName="overflow-x-auto -mx-5"
                        tableclassName="w-full whitespace-nowrap"
                        theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
                        thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-y border-slate-200 dark:border-zink-500"
                        tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
                        PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default ProductsOrders;
