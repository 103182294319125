import image from "assets/images/support.png";

interface NotFoundType {
  title?: string;
  className?: string;
}

const NotFound: React.FC<NotFoundType> = ({ className, title }) => {
  return (
    <div className={`noresult ${className}`}>
      <div className="py-6 text-center">
        <img
          src={image}
          alt=""
          className="w-32 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20"
        />
        <h5 className="mt-2 mb-1">
          {title ? title : "Üzgünüm! Burada hiç bir şey yok"}
        </h5>
        <p className="mb-0 text-slate-500 dark:text-zink-200">
          Aradığınız kriterlere uygun bir sonuç bulunamadı.
        </p>
      </div>
    </div>
  );
};

export default NotFound;
