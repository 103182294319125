import { Fragment, useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { UploadCloud, X } from "lucide-react";

import Select from "react-select";
import Dropzone from "react-dropzone";
import BreadCrumb from "Common/BreadCrumb";
import MultiSelect from "Common/Components/productMultiSelect/MultiSelect";
import { EventFunc, SubmitFunc } from "models/models";
import { sendRequestWithCache } from "../../../helpers/request";
import { useAppSelector } from "../../../redux/hooks";
import {
  toastErrorNotify,
  toastSuccessNotify,
  toastWarnNotify,
} from "helpers/ToasNotify";
import { removeItem } from "helpers/localStorageControl";

interface FormType {
  productName: string;
  category: { label: string; value: string };
  originalPrice: string;
  price: string;
  stock: string;
  tax: any;
}
export interface SelectStateType {
  isOpen: boolean;
  data: any;
  list: number[];
  loading: any;
}
interface SelectImgType {
  data: any;
  loading: boolean;
}
interface BrandsDataType {
  data: [];
  selectBrand: any;
}

export type categoriyGetType = (
  e: number,
  i: number,
  name?: string | null
) => void;

const next = {
  fontSize: "10px",
};

const dataImages = [
  "https://swiperjs.com/demos/images/nature-1.jpg",
  "https://swiperjs.com/demos/images/nature-2.jpg",
  "https://swiperjs.com/demos/images/nature-3.jpg",
  "https://swiperjs.com/demos/images/nature-4.jpg",
  "https://swiperjs.com/demos/images/nature-5.jpg",
  "https://swiperjs.com/demos/images/nature-6.jpg",
  "https://swiperjs.com/demos/images/nature-7.jpg",
  "https://swiperjs.com/demos/images/nature-8.jpg",
];

const AddProduct = () => {
  const [productCode, setProductCode] = useState("");
  const [formValues, setFormValues] = useState<FormType>({
    productName: "",
    category: { value: "", label: "" },
    originalPrice: "",
    price: "",
    stock: "",
    tax: "",
  });
  const { productName, originalPrice, price } = formValues;
  const [selectImg, setSelectImg] = useState<SelectImgType>({
    data: [],
    loading: false,
  });
  const [multiSelectState, setMultiSelectState] = useState<SelectStateType>({
    isOpen: false,
    data: [],
    list: [],
    loading: false,
  });
  const [selectCategory, setSelectCategory] = useState<string>("");
  const [brandsData, setBrandsData] = useState<BrandsDataType>({
    selectBrand: "",
    data: [],
  });
  const [editorValue, setEditorValue] = useState<string>("");
  const [state, setState] = useState({ loading: false });
  const [taxData, setTaxData] = useState([]);

  const sliderRef = useRef<HTMLButtonElement>();
  const { currentUser } = useAppSelector((state) => state.auth);

  const handleAcceptedBorderFiles = async (files: any) => {
    const formData = new FormData();
    formData.append("file", files[0]);
    setSelectImg((state: any) => ({ ...state, loading: true }));

    try {
      const res = await sendRequestWithCache(
        "POST",
        "/upload/file",
        formData,
        {},
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );
      setSelectImg({
        data: [...selectImg.data, res],
        loading: false,
      });
    } catch (error) {
      setSelectImg((state: any) => ({ ...state, loading: false }));

      toastErrorNotify(
        "Resmi yüklerken bir sorun oluştu. Lütfen tekrar deneyiniz."
      );
    }
  };
  const categoryGet: categoriyGetType = async (key, index, name) => {
    if (multiSelectState.list[index]) return;

    if (
      name &&
      !selectCategory?.includes(name) &&
      !multiSelectState.list[index]
    ) {
      setSelectCategory(`${selectCategory} ${name} >`);
    } else if (name && selectCategory?.includes(name)) return;

    setMultiSelectState({ ...multiSelectState, loading: { [index]: true } });
    try {
      const { data } = await sendRequestWithCache(
        "POST",
        `/category/${key}`,
        null,
        {},
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );
      if (data.length === 0) {
        return (
          setFormValues({
            ...formValues,
            category: {
              value: key.toString(),
              label: `${selectCategory} ${name}`,
            },
          }),
          setMultiSelectState((state: any) => ({
            ...state,
            isOpen: false,
          })),
          brandsGet(key)
        );
      }

      if (multiSelectState.list[index]) {
        setMultiSelectState((state) => ({
          ...state,
          data: [...state.data[index], data],
          list: [...state.list, index],
          loading: false,
        }));
      } else {
        setMultiSelectState((state) => ({
          ...state,
          data: [...state.data, data],
          list: [...state.list, index],
          loading: false,
        }));
      }
    } catch (error) {
      setMultiSelectState({
        ...multiSelectState,
        data: [],
        list: [],
        loading: false,
      });
    }
  };

  const categoryClear = () => {
    setFormValues({ ...formValues, category: { label: "", value: "" } });
    setBrandsData({
      data: [],
      selectBrand: "",
    });
    setMultiSelectState({
      isOpen: false,
      data: [],
      list: [],
      loading: false,
    });
    setSelectCategory("");
    // categoryGet(0, 0);
  };

  const clearState = () => {
    setFormValues({
      productName: "",
      category: { label: "", value: "" },
      originalPrice: "",
      price: "",
      stock: "",
      tax: "",
    });
    setSelectImg({
      data: [],
      loading: false,
    });
    setBrandsData({
      data: [],
      selectBrand: "",
    });
    setMultiSelectState({
      isOpen: false,
      data: [],
      list: [],
      loading: false,
    });
    setSelectCategory("");
    setEditorValue("");
    categoryGet(0, 0);
  };

  // Onayla Buttonuna basıldığında
  const handleOnSubmit: SubmitFunc = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("title", formValues.productName);
    formData.append("description", editorValue);
    formData.append("price", formValues.price);
    formData.append("original_price", formValues.originalPrice);
    formData.append("product_code", productCode);
    formData.append("category_id", formValues.category.value);
    formData.append("brand_id", brandsData.selectBrand?.value);
    formData.append("tax_id", formValues.tax?.value);
    const images = selectImg.data.map((item: any) => item.url);
    formData.append("images", images.join(","));

    if (formValues.stock) {
      if (
        !(Number.isInteger(+formValues.stock) && Number(+formValues.stock) > 0)
      ) {
        return toastWarnNotify("Stok bilgilerinizi kontrol ediniz.");
      } else {
        formData.append("stock", formValues.stock.toString());
      }
    }
    if (Number(formValues.originalPrice) < Number(formValues.price)) {
      return toastWarnNotify(
        "a-Commerce satış fiyatı Piyasa satış değerinden fazla olamaz."
      );
    }
    if (editorValue.length < 3) {
      return toastWarnNotify("Açıklama kısmını doldurunuz.");
    }
    if (selectImg.data.length === 0) {
      return toastWarnNotify("Lütfen resim ekleyiniz.");
    }
    if (brandsData.selectBrand?.label?.length === 0) {
      return toastWarnNotify("Marka kısmını doldurunuz.");
    }
    setState({ ...state, loading: true });

    try {
      const data = await sendRequestWithCache(
        "POST",
        "/product/create",
        formData,
        {},
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );
      setMultiSelectState({
        isOpen: false,
        data: [],
        list: [],
        loading: false,
      });

      toastSuccessNotify(data.message);
      removeItem("userBrands");
      removeItem("userCategories");
      removeItem("allProducts");
      removeItem("activeProducts");
      removeItem("waitingProducts");
      removeItem("inactiveProducts");
      setState({ ...state, loading: false });

      generateId();
    } catch (error) {
      setState({ ...state, loading: false });
      toastErrorNotify("Ürün eklerken sorun oluştu.Tekrar deneyiniz. ");
    }
    clearState();
  };
  // inputlardan veri alma
  const handleOnChange: EventFunc = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };
  const handlePriceOrStock = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.value === "" ||
      (/^[0-9\b]+$/.test(event.target.value) && Number(event.target.value) > 0)
    ) {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    }
  };
  // satış fiyartlarının karşılaştırılması
  const handlePriceChange: EventFunc = (event) => {
    if (
      event.target.value === "" ||
      (/^[0-9\b]+$/.test(event.target.value) && Number(event.target.value) > 0)
    ) {
      if (Number(formValues.originalPrice) >= Number(event.target.value)) {
        setFormValues({
          ...formValues,
          [event.target.name]: event.target.value,
        });
      }
    }
  };
  // KDV oranını seçme
  const handleSelect = (event: { label: string; value: string }) => {
    setFormValues({ ...formValues, tax: event });
  };

  const selectBrandOrCategory = (item: any) => {
    setBrandsData((state) => ({
      ...state,
      selectBrand: { value: item?.value.toString(), label: item?.label },
    }));
  };

  const categoryInputChange = () => {
    setMultiSelectState({ ...multiSelectState, isOpen: true });
  };

  // Ürün kodu oluşturma
  function generateId() {
    const staticText = "ALF";
    const currentYear = new Date().getFullYear();
    const randomChars = () => {
      const possibleChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let text = "";
      for (let i = 0; i < 12; i++) {
        text += possibleChars.charAt(
          Math.floor(Math.random() * possibleChars.length)
        );
      }
      return text;
    };
    setProductCode(staticText + currentYear + randomChars());
  }

  // kategorileri getir

  const brandsGet = async (id: number) => {
    try {
      const res = await sendRequestWithCache(
        "POST",
        `/category/${id}/brands`,
        null,
        {},
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );
      const data = res.map((item: any) => ({
        ...item,
        label: item.name,
        value: item.id.toString(),
      }));
      setBrandsData((state) => ({ ...state, data }));
    } catch (error) {
      setBrandsData({ ...brandsData });
    }
  };

  const handleClickInput = () => {
    if (selectCategory.length > 0) {
      categoryClear();
    }
    return setMultiSelectState((state: any) => ({
      ...state,
      isOpen: true,
    }));
  };

  const taxes = async () => {
    try {
      const { data } = await sendRequestWithCache(
        "GET",
        "/product/taxes",
        null,
        {},
        {
          headers: {
            Authorization: `Bearer ${currentUser.bearer_token}`,
          },
        }
      );

      setTaxData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveImage = (name: string) => {
    const newData: any = selectImg.data.filter(
      (item: any) => item.name !== name
    );
    setSelectImg({ ...selectImg, data: newData });
  };

  useEffect(() => {
    generateId();
    categoryGet(0, 0);
    taxes();
  }, []);
  // const pagination = {
  //   clickable: true,
  //   renderBullet: function (index: number, className: string) {
  //     console.log(className);
  //     return '<span class="' + className + '">' + (index + 1) + "</span>";
  //   },
  // };

  return (
    <Fragment>
      <div className="container-fluid group-data-[content=boxed]:max-w-boxed mx-auto">
        <BreadCrumb
          title="
            Ürün ekle"
          pageTitle="Ürün"
        />
        <form className="grid xl:grid-cols-4 gap-x-5" onSubmit={handleOnSubmit}>
          <div className="grid col-span-4 xl:col-span-3">
            <div className="card">
              <div className="card-body">
                <h6 className="mb-4 text-15">Ürün Bilgileri</h6>
                <div className="grid grid-cols-1 gap-x-5 ">
                  <div className="mb-4">
                    <label
                      htmlFor="productName"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      Ürün Adı
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="productName"
                      name="productName"
                      className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="Ürün Başlığı"
                      required
                      onChange={handleOnChange}
                      value={formValues.productName}
                    />
                  </div>
                  <div className="mb-4 relative group group/item">
                    <X
                      onClick={categoryClear}
                      className="absolute right-1 top-9 text-slate-200 group/edit invisible group-hover/item:visible group-hover/item:opacity-100  dark:text-zink-100 dark:hover:text-zink-200 hover:text-slate-500"
                    />
                    <label
                      htmlFor="category"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      Kategori
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="category"
                      name="category"
                      className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="Kategoriyi seçiniz"
                      // defaultValue="Bethany"
                      required
                      onChange={categoryInputChange}
                      onClick={handleClickInput}
                      value={formValues.category?.label}
                    />

                    {multiSelectState.isOpen && (
                      <MultiSelect
                        multiSelectState={multiSelectState}
                        categoryGet={categoryGet}
                        selectCategory={selectCategory}
                      />
                    )}
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="brand"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      Marka
                      <span className="text-red-500">*</span>
                    </label>
                    <Select
                      className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200 z-10"
                      id="brand"
                      name="brand"
                      // data-choices
                      placeholder="Marka seçiniz"
                      // data-choices-multiple-groups="true"
                      // isMulti
                      onChange={(event: any) => selectBrandOrCategory(event)}
                      options={brandsData.data}
                      value={brandsData.selectBrand}
                      // isSearchable
                      // isClearable
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="description"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      Ürün Açıklaması
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="ckeditor-classic  text-slate-800 bg-transparent">
                      <CKEditor
                        config={{
                          placeholder: "Ürünü açıklayınız",
                        }}
                        id="editor"
                        onChange={(event: any, editor: any) => {
                          setEditorValue(editor.getData());
                        }}
                        editor={ClassicEditor}
                        data={editorValue}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`xl:col-span-1 col-span-4 ${
              selectImg.data.length > 0 &&
              (productName && originalPrice && price).length > 0
                ? "grid"
                : "hidden"
            }`}
          >
            {/* <div className={`xl:col-span-1 col-span-4 grid`}> */}
            <div>
              <div className="card">
                <div className="card-body w-full">
                  <h6 className="mb-4 text-15 w-full">Ürün Kartı Önizlemesi</h6>
                  {/* 
                  <div className="px-5 py-8 rounded-md bg-sky-50 dark:bg-zink-600">
                    <img
                      src={selectImg.url}
                      alt={selectImg.name}
                      className="block mx-auto h-44"
                    />
                  </div> */}
                  {/* <div className="xl:col-span-1 col-span-4 h-[500px]"> */}
                  <Swiper
                    // cssMode={true}
                    // centeredSlides={true}
                    navigation
                    slidesPerGroup={1}
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    // mousewheel={true}
                    keyboard={true}
                    grabCursor={true}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                    className="mySwiper flex justify-center items-center w-[300px] xl:w-[260px]"
                    style={
                      {
                        // "--swiper-navigation-color": "red",
                        "--swiper-navigation-size": "15px",
                        "--swiper-pagination-size": "5px",
                        // "--swiper-pagination-color": "red",
                      } as React.CSSProperties
                    }
                  >
                    {selectImg.data.map((item: any, index: number) => {
                      return (
                        <SwiperSlide
                          key={index}
                          className="flex justify-center items-center"
                        >
                          <img
                            src={item.url}
                            alt={item}
                            className="w-full h-[300px] xl:h-[260px] rounded-md object-cover"
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                  {/* </div> */}

                  <div className="mt-3">
                    <h6 className="mb-1 text-15">{productName}</h6>

                    {price === originalPrice ? (
                      <h5 className="mb-2">₺{price} </h5>
                    ) : (
                      <h5 className="mb-2">
                        ₺{price}{" "}
                        <small className="font-normal line-through">
                          ₺{originalPrice}
                        </small>
                      </h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="xl:col-span-1 col-span-4 h-[500px]">
            <Swiper
              cssMode={true}
              navigation={true}
              pagination={true}
              mousewheel={true}
              keyboard={true}
              modules={[Navigation, Pagination, Mousewheel, Keyboard]}
              className="flex justify-center items-center w-full md:w-[50vw] xl:w-full h-full"
              // style={{
              //   padding: "6rem 0 2rem 0  ",
              // }}
            >
              {dataImages.map((item: any, index: number) => {
                return (
                  <SwiperSlide key={index}>
                    <img
                      src={item}
                      alt={item}
                      className="w-full h-full rounded-md"
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div> */}
          <div className="col-span-4 xl:col-span-3">
            <div className="card">
              <div className="card-body">
                <h6 className="mb-4 text-15">Satış Bilgileri</h6>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 ">
                  <div className="mb-4">
                    <label
                      htmlFor="originalPrice"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      Piyasa Satışı
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="number"
                      id="originalPrice"
                      name="originalPrice"
                      className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="0,00₺"
                      // defaultValue="Sophia"
                      required
                      value={formValues.originalPrice}
                      onChange={handlePriceOrStock}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="price"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      a-Commerce Satış Fiyatı
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="number"
                      id="price"
                      name="price"
                      className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="0,00₺"
                      // defaultValue="Bethany"
                      required
                      value={formValues.price}
                      disabled={formValues.originalPrice.length === 0}
                      onChange={handlePriceChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="stock"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      Stok
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="number"
                      id="stock"
                      name="stock"
                      className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="Stok adetini giriniz"
                      required
                      value={formValues.stock}
                      onChange={handlePriceOrStock}
                      pattern=" 0+\.[0-9]*[1-9][0-9]*$"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="tax"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      KDV
                      <span className="text-red-500">*</span>
                    </label>
                    <Select
                      className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      id="tax"
                      name="tax"
                      data-choices
                      placeholder="KDV oranını seçiniz"
                      onChange={(e: any) => handleSelect(e)}
                      isClearable
                      required
                      options={taxData}
                      value={formValues.tax}
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="productCode"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      Ürün Kodu
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="productCode"
                      name="productCode"
                      className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="Stok Kodunu Giriniz"
                      required
                      value={productCode}
                      disabled
                    />
                  </div>
                </div>
                <div className="mb-0">
                  <div className="flex items-center justify-center bg-white border border-dashed rounded-md cursor-pointer dropzone border-slate-300 dropzone2 dark:bg-zink-700 dark:border-zink-500">
                    {selectImg.loading ? (
                      <div className="flex flex-col items-center justify-center">
                        <div className="inline-flex size-10 rounded-full opacity-75 animate-bounce bg-yellow-400"></div>
                        <p>Yükleniyor...</p>
                      </div>
                    ) : (
                      <Dropzone
                        onDrop={(acceptedFiles: any) => {
                          handleAcceptedBorderFiles(acceptedFiles);
                          // handleAcceptedFiles(acceptedFiles);
                        }}
                      >
                        {({ getRootProps, getInputProps }: any) => (
                          <div
                            className="w-full py-5 text-lg text-center dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <UploadCloud className="block size-12 mx-auto text-slate-500 fill-slate-200 dark:text-zink-200 dark:fill-zink-500"></UploadCloud>
                            </div>

                            <h5 className="mb-0 font-normal text-slate-500 text-15">
                              {/* Drag and drop your files or <a href="#!">browse</a>{" "}
                            your files */}
                              Ürün görsellerinizi sürükleyip bırakın veya ürün
                              görsellerinize göz atın.
                            </h5>
                          </div>
                        )}
                      </Dropzone>
                    )}
                  </div>
                  <ul
                    className="flex flex-wrap mb-0 gap-x-5"
                    id="dropzone-preview2"
                  >
                    {selectImg.data.length > 0 &&
                      selectImg.data.map((f: any, i: any) => {
                        return (
                          <li
                            className="mt-2"
                            id="dropzone-preview-list"
                            key={i + "-file"}
                          >
                            <div className="border rounded border-slate-200 dark:border-zink-500">
                              <div className="p-2 text-center">
                                <div>
                                  <div className="p-2 mx-auto rounded-md size-24 bg-slate-100 dark:bg-zink-600">
                                    <img
                                      data-dz-thumbnail
                                      className="block w-full h-full rounded-md aspect-1/1 object-cover"
                                      src={f.url}
                                      alt={f.name}
                                    />
                                  </div>
                                </div>
                                <div className="pt-3">
                                  <h5 className="mb-1 text-15" data-dz-name>
                                    {f.lastModified}
                                  </h5>
                                  <p
                                    className="mb-0 text-slate-500 dark:text-zink-200"
                                    data-dz-size
                                  >
                                    {f.formattedSize}
                                  </p>
                                </div>
                                <div className="mt-2">
                                  <button
                                    type="button"
                                    className="px-2 py-1.5 text-xs text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-custom-400/20"
                                    onClick={() => {
                                      // const newImages = [...selectedBorderFiles];
                                      // newImages.splice(i, 1);
                                      // setSelectedBorderFiles(newImages);
                                      handleRemoveImage(f.name);
                                    }}
                                  >
                                    Kaldır
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>

                <div className="text-end mt-4">
                  <button
                    type="submit"
                    className="px-4 py-2.5 text-15 text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 disabled:bg-slate-300 disabled:border-none"
                    disabled={state.loading}
                  >
                    Onaya Gönder
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default AddProduct;
