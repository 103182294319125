import { sendRequestWithCache } from "helpers/request";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { userSuccess } from "../redux/auth/authSlice";
import { getItem, setItem } from "helpers/localStorageControl";

const useUser = () => {
  const dispatch = useAppDispatch();

  const userGet = async (info: any) => {
    try {
      const { data } = await sendRequestWithCache(
        "GET",
        "/user",
        null,
        {},
        {
          headers: {
            Authorization: `Bearer ${info.bearer_token || info.bearerToken}`,
          },
        }
      );
      dispatch(userSuccess(data));

      const existingData = getItem("auth");
      existingData.data = { ...info, ...data };
      setItem("auth", existingData);
    } catch (error) {
      console.log(error);
    }
  };
  return { userGet };
};

export default useUser;
