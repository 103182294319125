import { useCallback, useEffect, useState } from 'react';
import { sendRequestWithCache } from 'helpers/request';
import { useAppSelector } from "../../redux/hooks";
import CancellationData from "./CancellationData";

interface Filter {
  orderNumber: string,
  cargoCode: string,
  cancelStartDate: string,
  cancelEndDate: string,
}

const CustomerCanceled: React.FC<{ filter: Filter }> = ({ filter }) => {
  const { bearer_token } = useAppSelector((state) => state.auth.currentUser);
  const [loading, setLoading] = useState(true);
  const [listOfProducts, setListOfProducts] = useState([{}, {}]);

  const fetchCustomerCancellations = useCallback(async () => {
    setLoading(true);
    try {
      const response = await sendRequestWithCache("POST", "/order/canceled-orders/customer", {
        order_number: filter.orderNumber,
        cargo_code: filter.cargoCode,
        start_date: filter.cancelStartDate,
        end_date: filter.cancelEndDate,
      }, {}, {
        headers: {
          Authorization: `Bearer ${bearer_token}`,
        },
      });
      const editedData = response.map((item: {
        id: string,
        orderDate: string,
        customer: string,
        img: string,
        productName: string,
        // designation: string,
        unitPrice: string,
        quantity: string,
        cancellationDate: string,
        state: string,
        cancellationReason: string,
      }) => {
        return {
          orderId: item.id,
          orderDate: item.orderDate,
          receiver: item.customer,
          img: item.img,
          productInfo: item.productName,
          // designation: item.designation,
          unitPrice: item.unitPrice,
          quantity: item.quantity,
          cancellationDate: item.cancellationDate,
          whoCancelled: item.state,
          cancellationReason: item.cancellationReason,
        }
      })
      setListOfProducts(editedData);
    } catch (error) {
      return error
    } finally {
      setLoading(false);
    }
  }, [bearer_token, filter]);

  useEffect(() => {
    fetchCustomerCancellations();
  }, [filter, fetchCustomerCancellations]);

  return (
    <CancellationData listOfProducts={listOfProducts} loading={loading} />
  );
};

export default CustomerCanceled;
