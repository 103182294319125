import axios, { AxiosResponse } from "axios";

const apiURL = "https://merchant-api.gamerburada.com/api";

interface CacheConfig {
  cache?: boolean;
  cacheName?: string;
  cacheTime?: number | any;
}

export const sendRequestWithCache = async (
  method: string,
  url: string,
  data?: any,
  cacheConfig: CacheConfig = {},
  headers?: any
): Promise<any> => {
  const { cache, cacheName, cacheTime } = cacheConfig;
  const cacheKey = cache ? cacheName || `${method}_${url}` : "";
  const cachedData = cache ? localStorage.getItem(cacheKey) : null;

  if (cachedData) {
    const { data: cachedResponse, timestamp } = JSON.parse(cachedData);

    if (Date.now() - timestamp < cacheTime * 1000) {
      return cachedResponse;
    }
    localStorage.removeItem(cacheKey);
  }

  url = `${apiURL}${url}`;

  let response: AxiosResponse<any> | undefined;

  if (method === "GET") {
    response = await axios.get(url, headers);
  } else if (method === "POST") {
    response = await axios.post(url, data, headers);
  }

  if (
    !response
    // || !response.data || !response.data.success
  ) {
    throw new Error("Error");
  }

  // // catch error
  // if (response.success === false) {
  //   throw new Error("Error");
  // }

  const responseToCache = {
    data: response.data,
    timestamp: Date.now(),
  };
  if (cache) {
    localStorage.setItem(cacheKey, JSON.stringify(responseToCache));
  }

  return response.data;
};
