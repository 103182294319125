import image from "assets/images/support.png";

interface NotFoundType {
  title?: string;
}

const NotFound: React.FC<NotFoundType> = ({ title }) => {
  return (
    <div className="flex flex-col items-center py-6">
      <img src={image} alt="" className="w-32" />
      <h3 className="text-lg font-medium">
        {title ? title : "Burada hiçbir şey yok"}
      </h3>
    </div>
  );
};

export default NotFound;
